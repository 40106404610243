import { SortOption, TimeOption } from '../../../../../utils/utils-filter';
import { Headline } from './Headline';

export function getTemplatesHeadline(
    sort: SortOption,
    timeOption: TimeOption,
    filterKey: string,
    category: string | null
): Headline {
    console.log("getSpecificHeadline", sort, timeOption, filterKey, category);
    const timeSentence = `Sorted by ${sort.name
        } and shown in ${timeOption.name.toLowerCase()} order. `;

    const titles: { [key: string]: string } = {
        all: "Notion Templates",
        free: "Free Notion Templates",
        paid: "Premium Notion Templates",
        featured: "Featured Notion Templates",
    };
    const descriptions: { [key: string]: string } = {
        all: category
            ? `All Notion templates in the ${category} category.`
            : "All Notion templates available on our platform.",
        free: category
            ? `Free Notion templates in the ${category} category.`
            : "Free Notion templates available on our platform.",
        paid: category
            ? `Premium Notion templates in the ${category} category.`
            : "Premium Notion templates available on our platform.",
        featured: category
            ? `Featured Notion templates in the ${category} category.`
            : "Featured Notion templates available on our platform.",
    };
    const title = titles[filterKey] || "Notion Templates";
    const description = descriptions[filterKey] || "Notion Templates";
    return {
        title,
        description: description + +" " + timeSentence,
    };
}