// In your LinksFooter component file
import React from "react";

import FooterLinkComponent, { FooterSections } from "./FooterLinkComponent";
import { ExternalLinks, AffiliateLinks } from "../../../utils/constants";
import { Destination } from "../../../utils/constants-navigation";

const footerSections: FooterSections = {
  sections: [
    {
      title: "Notion Content",
      links: [
        { href: Destination.DISCOVER, text: "Discover" },
        {
          href: ExternalLinks.ELCOVIA_GTP,
          text: "Discover via ElcoviaGPT",
          newTab: true,
        },
        { href: Destination.TEMPLATES, text: "Notion Templates" },
        { href: Destination.TEMPLATES_CATEGORIES, text: "Template Categories" },
        { href: Destination.USERS, text: "Notion Creators" },
      ],
    },
    {
      title: "Notion Links",
      links: [
        { href: AffiliateLinks.NOTION, newTab: true, text: "Notion" },
        {
          href: AffiliateLinks.NOTION_AI,
          newTab: true,
          text: "Notion AI",
        },
        {
          href: AffiliateLinks.NOTION_PROJECTS,
          newTab: true,
          text: "Notion Projects",
        },
      ],
    },
    {
      title: "Notion Creators",
      links: [
        {
          href: Destination.LOGIN,
          text: "Login / Signup",
        },
        {
          href: Destination.ACCOUNT_DASHBOARD,
          text: "Creator Dashboard",
        },
      ],
    },
    {
      title: "Elcovia",
      links: [
        { href: Destination.APPS, text: "Elcovia Apps" },
        { href: Destination.ABOUT, text: "About" },
        { href: Destination.CONTACT, text: "Contact" },
        { href: Destination.FAQS_NOTION, text: "FAQs - Notion" },
        { href: Destination.FAQS_ELCOVIA, text: "FAQs - Elcovia" },
      ],
    },
  ],
};

interface FooterLinksComponentProps {
  sections?: FooterSections;
}

const FooterLinksComponent: React.FC<FooterLinksComponentProps> = ({
  sections = footerSections,
}) => {
  return (
    <div className="w-full">
      <div className="mx-auto grid grid-cols-2 gap-8 lg:grid-cols-4 lg:gap-y-16">
        {sections.sections.map((section) => (
          <FooterLinkComponent key={section.title} section={section} />
        ))}
      </div>
    </div>
  );
};

export default FooterLinksComponent;
