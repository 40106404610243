import React from "react";
import {
  getDomainFromUrl,
  getSocialNetworkName,
  getSocialNetworkSvgIcon,
} from "../../../utils/utils-formatting";
import { classNames } from "../../../utils/utils-react";

interface Props {
  onClick?: () => void;
  href: string;
  target?: string;
  rel?: string;
  parentClassNames?: string;
  prefix?: string;
}

const SocialButtonComponent: React.FC<Props> = ({
  onClick,
  href,
  target,
  rel,
  parentClassNames,
  prefix,
}) => {
  const domain = getDomainFromUrl(href);
  const name = getSocialNetworkName(domain);
  const icon = getSocialNetworkSvgIcon(domain);

  const baseClassNames = classNames(
    "text-gray-800 hover:bg-gray-100 bg-white border border-gray-300 items-center",
    "flex transition-all ease-in-out duration-300 rounded shadow-sm py-2.5 px-4 text-sm font-medium outline-none"
  );

  if (onClick)
    return (
      <div className={parentClassNames}>
        <a onClick={onClick} target={target || ""} rel={rel || ""}>
          <button type="button" className={baseClassNames}>
            <img className="h-5 w-5 mr-2" src={icon} alt={name} />

            {(prefix ? prefix : "") + " " + name}
          </button>
        </a>
      </div>
    );

  return (
    <div className={parentClassNames}>
      <a href={href || "#"} target={target || ""} rel={rel || ""}>
        <button type="button" className={baseClassNames}>
          <img className="h-5 w-5" src={icon} alt={name} />
          {name && (
            <span className="ml-2"> {(prefix ? prefix : "") + " " + name}</span>
          )}
        </button>
      </a>
    </div>
  );
};

export default SocialButtonComponent;
