const stats = [
  { id: 3, name: "Templates Available", value: "200+" },
  { id: 1, name: "Monthly Users", value: "500+" },
  { id: 2, name: "Creators Supported", value: "25+" },
];

export default function StatsComponent() {
  return (
    <div className="py-16 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Elcovias impact on Notion ♥, Creators, and Users
            </h2>
            <p className="mt-4 text-md leading-8 text-gray-600 max-w-2xl mx-auto">
              Discover Elcovia, the platform that empowers Notion enthusiasts,
              creators, and users. Showcase your creativity, connect with fellow
              Notion lovers, and elevate your Notion experience. Join our
              community and unlock new possibilities with Elcovia
            </p>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-4 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((stat) => (
              <div
                key={stat.id}
                className="flex flex-col bg-gray-50 rounded-lg p-4 sm:p-5"
              >
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  {stat.name}
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
