import React from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import SimpleSocialButtonComponent from "../../../components/common/buttons/SimpleSocialButtonComponent";
import HtmlShortTextComponent from "../../../components/common/html/preview_short/HtmlShortTextComponent";
import TextFieldComponent from "../../../components/common/input/TextFieldComponent";
import LoadingBarComponent from "../../../components/common/loading/LoadingBarComponent";
import LoadingScreenComponent from "../../../components/common/loading/LoadingScreenComponent";
import { Profile } from "../../../models/Profile";
import { Template } from "../../../models/Template";
import {
  checkCustomerOwnsTemplate,
  getTemplateById,
  getUserByUserId,
} from "../../../services/firestore-service";
import {
  unlockMarketplaceFreeTemplate,
  unlockMarketplaceFreeTemplateAnonymously,
} from "../../../services/functions-service";
import { RootState } from "../../../store";
import { Destination, setPathId } from "../../../utils/constants-navigation";
import { isInvalidEmail } from "../../../utils/mails/utils-mails";
import { localizedValue } from "../../../utils/supported-locales";
import { getProfileId } from "../../../utils/utils-profile";

/**
 * The parameters for the MarketplaceTemplateScreen.
 */
interface MarketplaceTemplatePageParams {
  id: string;
  [key: string]: string;
}

/**
 * The MarketplaceTemplateScreen.
 */
const MarketplaceTemplatePage = () => {
  const { id } = useParams<MarketplaceTemplatePageParams>();
  const navigate = useNavigate();

  // General states
  const [hasOwnership, setHasOwnership] = React.useState<boolean | null>(null);
  const [unlockLoading, setUnlockLoading] = React.useState<boolean>(false);
  const [template, setTemplate] = React.useState<Template | null>(null);
  const [templateCreator, setTemplateCreator] = React.useState<Profile | null>(
    null
  );

  // Logged-in relevant states
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const userProfile = useSelector((state: RootState) => state.user.profile);

  // Anonymous relevant states
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<boolean>(false);

  /**
   * Load the template.
   * @param templateId The template ID.
   */
  const loadTemplate = async (templateId: string) => {
    const template = await getTemplateById(templateId);
    loadTemplateCreator(template.userId);
    setTemplate(template);
  };

  /**
   * Load the template creator.
   * @param userId The user ID.
   */
  const loadTemplateCreator = async (userId: string) => {
    const templateCreator = await getUserByUserId(userId);
    setTemplateCreator(templateCreator);
  };

  /**
   * Check if the user owns the template.
   * @param customerId The customer ID.
   * @param templateId The template ID.
   * @returns True if the user owns the template, false otherwise.
   */
  const checkCustomerTemplateOwnership = async (
    creatorId: string,
    customerId: string,
    templateId: string
  ) => {
    if (!customerId) return;
    const owned = await checkCustomerOwnsTemplate(
      creatorId,
      customerId,
      templateId
    );
    setHasOwnership(owned);
  };

  /**
   * Open the template in a new tab.
   * @param url The URL of the free template.
   */
  function openTemplate(url: string) {
    window.open(url, "_blank");
  }

  /**
   * Open the free template in a new tab.
   * @param url The URL of the free template.
   */
  function backToTemplate() {
    navigate(setPathId(Destination.TEMPLATE_ID_SHORT, template?.id!!));
  }

  /**
   * Unlock the free template for the logged-in user.
   */
  async function unlockFreeTemplate() {
    setEmailError(false);
    setUnlockLoading(true);
    await unlockMarketplaceFreeTemplate(template?.id!!);
    setTimeout(() => {
      checkCustomerTemplateOwnership(
        template?.userId!!,
        userProfile?.id!!,
        template?.id!!
      );
      setUnlockLoading(false);
    }, 1000);
  }

  /**
   * Unlock the free template for the anonymous user.
   */
  async function unlockFreeTemplateAnonymously(
    e: React.FormEvent<HTMLFormElement>
  ) {
    try {
      e.preventDefault();
    } catch (error) {}

    if (isInvalidEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    setUnlockLoading(true);
    const alreadyOwned = await checkCustomerOwnsTemplate(
      template?.userId!!,
      email,
      template?.id!!
    );
    if (alreadyOwned) {
      setUnlockLoading(false);
      setHasOwnership(true);
      return;
    } else {
      await unlockMarketplaceFreeTemplateAnonymously(
        template?.id!!,
        name,
        email
      );
      setTimeout(() => {
        checkCustomerTemplateOwnership(
          template?.userId!!,
          email!,
          template?.id!!
        );
        setUnlockLoading(false);
      }, 1000);
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const referenceId = id;
    const templateId = referenceId?.split("-")?.[1] || referenceId;
    const userId = userProfile?.id || "";
    // Check if the user owns the template, in case they are logged in
    if (
      hasOwnership == null &&
      userId &&
      templateId &&
      templateCreator != null
    ) {
      checkCustomerTemplateOwnership(template?.userId!!, userId, templateId);
    }
    // Load the template if templateId is available
    if (template == null && templateId) {
      loadTemplate(templateId);
    }
  }, [template, templateCreator]);

  if (
    template == null ||
    templateCreator == null ||
    (isSignedIn && hasOwnership == null)
  )
    return <LoadingScreenComponent />;

  return (
    <div>
      <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-xl">
          <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">
            {localizedValue(template.title)!!}
          </p>
          <p className="mt-2 text-base">
            {template.isPaid ? (
              <>
                {hasOwnership ? (
                  <span className="text-green-500">Purchased</span>
                ) : !hasOwnership ? (
                  <span className="text-red-500">Not purchased</span>
                ) : (
                  <span className="text-gray-500">Verifying purchase...</span>
                )}
              </>
            ) : (
              <>
                {hasOwnership ? (
                  <span className="text-green-500">Access</span>
                ) : (
                  <span className="text-green-500">Free</span>
                )}
              </>
            )}
          </p>
        </div>

        <div className="mt-10 border-t border-gray-200">
          <h2 className="sr-only">Your order</h2>
          <h3 className="sr-only">Items</h3>
          <div
            key={template.id}
            className="flex gap-x-8 py-8 border-b border-gray-200 items-center"
          >
            <img
              src={localizedValue(template.thumbnailImage) || ""}
              alt={localizedValue(template.title)!!}
              className="h-32 w-32 flex-none rounded-lg shadow bg-gray-100 object-cover object-center"
            />

            <div className="flex flex-auto flex-col">
              <div>
                <p className="text-sm text-gray-600 leading-7 line-clamp-3">
                  <HtmlShortTextComponent
                    html={localizedValue(template.desc) || ""}
                  />
                </p>
                <div className="mt-2">
                  {templateCreator != null && (
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to={setPathId(
                        Destination.USER_ID_SHORT,
                        getProfileId(templateCreator)
                      )}
                      key={templateCreator.profileId}
                    >
                      <div className="flex items-center inline-block align-middle">
                        <img
                          src={templateCreator.image}
                          alt={templateCreator.name + " on Elcovia" || ""}
                          className="w-8 h-8 rounded-full shadow max"
                        />
                        <h3 className="ml-4 text-sm font-medium text-gray-900">
                          {templateCreator.name}
                        </h3>
                      </div>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
          {template.isPaid && !hasOwnership && (
            <div
              key={template.id}
              className="flex space-x-6 border-b border-gray-200 py-10"
            >
              <ButtonComponent
                style="redirect"
                text="Continue"
                target="_blank"
                onClick={() => backToTemplate()}
                rel="noopener noreferrer"
              />
              <p className="max-w-sm ml-auto font-normal text-sm">
                You have not purchased this template. Please purchase the
                template to access it.
              </p>
            </div>
          )}
          {unlockLoading ? (
            <LoadingBarComponent />
          ) : (
            <>
              {!template.isPaid && !hasOwnership && (
                <>
                  {isSignedIn ? (
                    // Signed in flow
                    <div
                      key={template.id}
                      className="border-b border-gray-200 py-10"
                    >
                      <p className="font-normal text-sm text-gray-600">
                        Hi{" "}
                        <span className="font-semibold">
                          {userProfile?.name}!
                        </span>{" "}
                        get this free Notion template!
                      </p>

                      <div className="mt-6">
                        <ButtonComponent
                          style="structural"
                          disabled={unlockLoading}
                          loading={unlockLoading}
                          text={"Get " + localizedValue(template.title)!!}
                          onClick={() => unlockFreeTemplate()}
                        />
                        <p className="mt-3 text-xs leading-6 text-gray-500">
                          I hearby agree to receive email updates from this
                          creator. You can unsubscribe at any time.
                        </p>
                      </div>
                    </div>
                  ) : (
                    // Not signed in unlock flow
                    <div
                      key={template.id}
                      className="border-b border-gray-200 py-10"
                    >
                      <p className="font-normal text-sm text-gray-600">
                        Enter your name and email below to unlock this free
                        Notion template.
                      </p>

                      {emailError && (
                        <p className="mt-3 text-xs leading-6 text-red-500">
                          Please enter a valid email address.
                        </p>
                      )}
                      <form
                        className="mt-6"
                        onSubmit={unlockFreeTemplateAnonymously}
                      >
                        <div className="flex gap-x-4 flex-auto items-center">
                          <div className="flex-1">
                            <label htmlFor="name" className="sr-only">
                              Name
                            </label>
                            <TextFieldComponent
                              id="name"
                              name="name"
                              type="text"
                              required
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Name"
                            />
                          </div>
                          <div className="flex-1">
                            <label htmlFor="email-address" className="sr-only">
                              Email address
                            </label>
                            <TextFieldComponent
                              id="email-address"
                              name="email"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              autoComplete="email"
                              required
                              placeholder="Email"
                            />
                          </div>
                          <ButtonComponent
                            style="structural"
                            text="Unlock"
                            disabled={unlockLoading}
                            loading={unlockLoading}
                            type="submit"
                          />
                        </div>
                        <p className="mt-3 text-xs leading-6 text-gray-500">
                          I hearby agree to receive email updates from this
                          creator. You can unsubscribe at any time.
                        </p>
                      </form>
                    </div>
                  )}
                </>
              )}
              {hasOwnership && (
                <div
                  key={template.id}
                  className="flex space-x-6 border-b border-gray-200 py-10"
                >
                  {Object.entries(template.templateUrl).map(([key, url]) => (
                    <SimpleSocialButtonComponent
                      key={key + url}
                      href={url}
                      text={`Download (${key})`}
                      imgRef={process.env.PUBLIC_URL + "/logo.png"}
                      target="_blank"
                      onClick={() => openTemplate(url)}
                      rel="noopener noreferrer"
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(MarketplaceTemplatePage);
