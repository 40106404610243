import { Dialog, Menu, Transition } from "@headlessui/react";
import {} from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  Cog6ToothIcon,
  FaceSmileIcon,
  HomeIcon,
  RectangleGroupIcon,
  ChevronDownIcon,
  UserGroupIcon,
  RectangleStackIcon,
  XMarkIcon,
  UserIcon,
  UserCircleIcon,
  UsersIcon,
  ChartBarIcon,
  HeartIcon,
  BookmarkSquareIcon,
  ArrowLeftOnRectangleIcon,
  BackwardIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { Fragment, MouseEventHandler, useState } from "react";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { logout } from "../../../services/action-service";
import { RootState } from "../../../store";
import { Destination, setPathId } from "../../../utils/constants-navigation";
import { getProfileId } from "../../../utils/utils-profile";
import { classNames } from "../../../utils/utils-react";
import HeaderProfileComponent, {
  LinkSectionItem,
} from "../../core/header/profile_state/HeaderProfileComponent";
import DashboardSidebarComponent, {
  SidebarItem,
} from "../../core/dashboard/sidebar/DashboardSidebarComponent";
import DashboardHeader from "../../core/dashboard/header/DashboardHeader";
import DashboardFooterComponent from "../../core/dashboard/footer/DashboardFooterComponent";

export type NavigationItem = {
  name: string;
  href?: string;
  icon: any;
  external?: boolean;
};

export type NavigationGroup = {
  name: string;
  items: NavigationItem[];
};

export type CustomNavLinkProps = {
  item: NavigationItem;
  children?: React.ReactNode;
};

export interface DashboardComponentProps {
  key: string;
  title?: string;
  titleView?: React.ReactNode;
  children?: React.ReactNode;
  navigationGroups: NavigationGroup[];
  bottomNavigation: NavigationItem[];
}

const DashboardComponent: React.FC<DashboardComponentProps> = ({
  key,
  title,
  titleView,
  children,
  navigationGroups,
  bottomNavigation,
}) => {
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full flex-1">
                  {/* Mobile Sidebar */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-6 w-auto"
                        src={process.env.PUBLIC_URL + "/text-logo.png"}
                        alt="Elcovia Text Logo"
                      />
                      <div className="ml-auto">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-black"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        {navigationGroups.map((group) => (
                          <li key={key + group.name}>
                            <div className="font-semibold text-md leading-6 text-gray-500">
                              {group.name}
                            </div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                              {group.items.map((item) => (
                                <li key={key + item.name}>
                                  <SidebarItem
                                    item={item}
                                    children={item.name}
                                  />
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                        {bottomNavigation.map((item) => (
                          <li className="mt-auto" key={key + item.name}>
                            <SidebarItem item={item} children={item.name} />
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div>
          {/* Header */}
          <DashboardHeader
            title={title}
            titleView={titleView}
            setSidebarOpen={setSidebarOpen}
          />

          {/* Sidebar */}
          <DashboardSidebarComponent
            origin={key}
            navigationGroups={navigationGroups}
            bottomNavigation={bottomNavigation}
          />
          {/* Content */}
          <main className="mt-16 pt-10 lg:pl-60">
            <div className="mx-auto px-6 sm:px-16 md:px-16 lg:px-16 xl:px-24 3xl-px-32 pb-32 min-h-screen">
              {children}
            </div>
            <DashboardFooterComponent />
          </main>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(DashboardComponent);
