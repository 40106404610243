import React from "react";
import { Helmet } from "react-helmet-async";

import { Destination, setPathId } from "../../../utils/constants-navigation";
import {
  BETA_TESTERS_DESCRIPTION,
  SCREEN_BETA_TESTERS,
} from "../../../utils/constants-seo";
import { classNames } from "../../../utils/utils-react";
import { Profile } from "../../../models/Profile";
import { getBetaUsersByQuery } from "../../../services/firestore-service";

export default function BetaTestersPage() {
  const [betaTesters, setBetaTesters] = React.useState<Profile[] | null>(null);

  React.useEffect(() => {
    if (!betaTesters) {
      const fetchBetaTesters = async () => {
        const betaTesters = await getBetaUsersByQuery();
        // Sort after creation timestamp in descending order.
        const sortedTesters = betaTesters.sort(
          (a, b) => a.created.seconds - b.created.seconds
        );
        setBetaTesters(sortedTesters);
      };
      fetchBetaTesters();
    }
  }, []);

  const betaBenefits = [
    {
      name: "5x Free Features",
      description:
        "Enjoy 5 free features for your creator profile or individual templates, with special promotion on Elcovia. Gain increased visibility and make a lasting impression on users.",
      imageSrc: process.env.PUBLIC_URL + "/beta/beta-perk-feature.png",
      imageAlt: "",
    },
    {
      name: "Enhanced Visibility",
      description:
        "As one of the early participants, you will receive maximum attention when Elcovia goes public, allowing you to stand out from the crowd and significantly enhance your exposure.",
      imageSrc:
        process.env.PUBLIC_URL + "/beta/beta-perk-enhanced-visibility.png",
      imageAlt: "",
    },
    {
      name: "Your Ideas Matter",
      description:
        "Play an active role in shaping the future of Elcovia by sharing your ideas and suggestions. Help make Elcovia better and tailored to creators's needs.'",
      imageSrc: process.env.PUBLIC_URL + "/beta/beta-perk-ideas-matter.png",
      imageAlt: "",
    },
    {
      name: "Beta Tester Recognition",
      description:
        'As a valued beta tester, you will be prominently featured on Elcovia\'s exclusive "Hall of Beta Testers" page.',
      imageSrc: "image-link-for-feature-3",
      imageAlt: "",
    },
  ];

  return (
    <>
      {/* This `Helmet` block sets the title, description, canonical link, and Open Graph metadata for the screen. */}
      <Helmet>
        <title>{SCREEN_BETA_TESTERS}</title>
        <link
          rel="canonical"
          href={`https://elcovia.com/${Destination.BETA_TESTERS}`}
        />
        <meta name="description" content={BETA_TESTERS_DESCRIPTION} />
        <meta property="og:title" content={SCREEN_BETA_TESTERS} />
        <meta property="og:description" content={BETA_TESTERS_DESCRIPTION} />
        <meta property="og:image" content={"%PUBLIC_URL%/logo.png"} />
      </Helmet>
      {/* This `div` block is the main container for the screen. */}
      <div>
        <div>
          <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
            <div className="mx-auto max-w-3xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Celebrating Our Valued Beta Testers
              </h2>
              <p className="mt-4 text-gray-500">
                Welcome to our Hall of Fame, a special tribute to the
                exceptional beta testers who played a pivotal role in shaping
                Elcovia. This dedicated page is a testament to their invaluable
                contributions and unwavering support during our early stages.
                Join us in recognizing and celebrating these extraordinary
                individuals who helped make Elcovia what it is today.
              </p>
            </div>

            <div className="mt-24 space-y-16">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-8 sm:max-w-xl sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                  {betaTesters &&
                    betaTesters.map((betaTester) => (
                      <a
                        href={setPathId(
                          Destination.USER_ID_SHORT,
                          betaTester.id
                        )}
                        key={betaTester.id}
                      >
                        <div className="transition-all ease-in-out duration-300  flex items-center space-x-8 col-span-1 bg-gray-100 rounded-md px-4 py-3 hover:bg-white hover:shadow-lg hover:md:scale-105">
                          <img
                            className="max-h-16 max-h-16 object-contain shadow-md rounded-md"
                            src={betaTester.image}
                            alt={betaTester.name || "Beta Tester"}
                          />
                          <div className="">
                            <h3 className="text-lg font-medium text-gray-900">
                              {betaTester.name}
                            </h3>
                          </div>
                        </div>
                      </a>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
