import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import React from "react";

import {
  getLocaleDisplayNameLanguage,
  getLocaleDisplayNameLanguageByKey,
  LocaleKey,
  localeKey,
  SupportedLocale,
  SupportedLocales,
} from "../../../utils/supported-locales";
import { classNames } from "../../../utils/utils-react";

/**
 * The props for the LocaleDropDown component.
 * @param className - The className to apply to the dropdown
 * @param currentLocale - The current locale
 * @param locales - The locales to display
 * @param onSelectLocale - The callback to call when a locale is selected
 */
interface LocaleDropdownProps {
  className?: string;
  currentLocale: LocaleKey;
  locales: LocaleKey[];
  onSelectLocale: (locale: LocaleKey) => void;
}

/**
 * The LocaleDropDown component.
 * @param param0 - The props for the LocaleDropDown component.
 * @returns The LocaleDropDown component.
 */
export const LocaleDropDownComponent: React.FC<LocaleDropdownProps> = ({
  className,
  currentLocale,
  locales,
  onSelectLocale,
}) => {
  const [richLocales, setRichLocales] = React.useState<SupportedLocale[]>(
    getInitialLocales()
  );

  function getInitialLocales(): SupportedLocale[] {
    const localesWithoutCurrent = locales.filter(
      (locale) => locale !== currentLocale
    );
    return localesWithoutCurrent.map((locale) =>
      Object.values(SupportedLocales).find((l) => localeKey(l) === locale)
    ) as SupportedLocale[];
  }

  React.useEffect(() => {
    setRichLocales(getInitialLocales());
  }, [currentLocale, locales]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className={className}>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {getLocaleDisplayNameLanguageByKey(currentLocale)}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {richLocales.map((locale) => (
              <Menu.Item key={localeKey(locale)}>
                {({ active }) => (
                  <a
                    onClick={() => onSelectLocale(localeKey(locale))}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {getLocaleDisplayNameLanguage(locale)}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
