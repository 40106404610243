// FooterLegalComponent.tsx
import React from "react";
import { Destination } from "../../../utils/constants-navigation";
import { classNames } from "../../../utils/utils-react";

interface LegalLink {
  href: string;
  text: string;
}

const legalLinks: LegalLink[] = [
  {
    href: Destination.TERMS_AND_CONDITIONS,
    text: "Terms & Conditions",
  },
  {
    href: Destination.PRIVACY_POLICY,
    text: "Privacy Policy",
  },
];

type FooterLegalComponentProps = {
  classNames?: string;
};

const FooterLegalComponent: React.FC<FooterLegalComponentProps> = ({
  classNames,
}) => {
  return (
    <div className={classNames || "pt-16 pb-4"}>
      <div className="sm:flex sm:justify-between">
        <p className="text-xs font-light text-gray-600">
          &copy; 2024 Elcovia. All rights reserved.
        </p>

        <nav className="mt-8 sm:mt-0">
          <ul className="flex flex-wrap justify-start gap-4 lg:justify-end">
            {legalLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className="text-xs font-light text-gray-600 hover:opacity-75"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default FooterLegalComponent;
