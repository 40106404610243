import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Customer } from "../../../models/Customer";
import {
  getUserConsumptions,
  getUserCustomers,
  getUserOrders,
  getUserSecretById,
} from "../../../services/firestore-service";
import { RootState } from "../../../store";
import { Destination } from "../../../utils/constants-navigation";
import {
  ACCOUNT_AUDIENCE_DESCRIPTION,
  ACCOUNT_DASHBOARD_DESCRIPTION,
  SCREEN_ACCOUNT_DASHBOARD,
  SCREEN_TITLE,
} from "../../../utils/constants-seo";
import {
  timestampToLocalDateTime,
  timestampToLocaleDateTimeShort,
} from "../../../utils/utils-formatting";
import { classNames } from "../../../utils/utils-react";
import { async } from "@firebase/util";
import { CustomerConsumption } from "../../../models/CustomerConsumption";
import { localizedValue } from "../../../utils/supported-locales";
import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import { func } from "prop-types";
import LoadingScreenComponent from "../../../components/common/loading/LoadingScreenComponent";
import NoDataStateComponent from "../../../components/common/state/NoDataState";
import SEOComponent, {
  SEOPage,
} from "../../../components/common/seo/SEOComponent";
import TabsComponent from "../../../components/common/tabs/TabsComponent";
import { DashboardH1 } from "../../../components/common/common/DashboardHeaderComponents";

const TabType = {
  CUSTOMERS: "Customers",
  PURCHASES: "Purchases",
};

type Tab = {
  name: (typeof TabType)[keyof typeof TabType];
  current: boolean;
};

const initTabs: Tab[] = [
  { name: TabType.CUSTOMERS, current: true },
  { name: TabType.PURCHASES, current: false },
];

interface CustomerTableRowProps {
  customer: Customer;
}

const CustomerTableRow: React.FC<CustomerTableRowProps> = ({ customer }) => {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        {customer.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {customer.mail}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {customer.productIds.length}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        ${customer.price}
      </td>
      <td className="whitespace-nowrap pl-3 py-4 text-sm text-gray-500">
        {timestampToLocaleDateTimeShort(customer.timestamp)}
      </td>
    </tr>
  );
};

interface ConsumptionTableRowProps {
  consumption: CustomerConsumption;
  customers: Customer[];
}

const ConsumptionTableRow: React.FC<ConsumptionTableRowProps> = ({
  consumption,
  customers,
}) => {
  const customerName =
    customers.find((c) => c.id === consumption.customerId)?.name || "Unknown";

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        {localizedValue(consumption.productName)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {customerName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        ${consumption.productPrice}
      </td>
      <td className="whitespace-nowrap pl-3 py-4 text-sm text-gray-500">
        {timestampToLocaleDateTimeShort(consumption.timestamp)}
      </td>
    </tr>
  );
};

interface TableComponentProps {
  headers: string[];
  children: React.ReactNode;
}

const TableComponent: React.FC<TableComponentProps> = ({
  headers,
  children,
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">{children}</tbody>
    </table>
  );
};

interface AudiencePageProps {}

const AudiencePage: React.FC<AudiencePageProps> = ({}) => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [customers, setCustomers] = React.useState<Customer[] | null>(null);
  const [consumptions, setConsumptions] = React.useState<
    CustomerConsumption[] | null
  >(null);

  const [tabs, setTabs] = React.useState<Tab[]>(initTabs);
  /**
   * Handle tab click event.
   */
  function handleTabClick(tab: Tab) {
    const newTabs = tabs.map((t) => ({
      ...t,
      current: t.name === tab.name,
    }));
    setTabs(newTabs);
  }

  /**
   * Load customers.
   */
  async function loadCustomers() {
    try {
      if (!isSignedIn || !sessionProfile) return;
      const customers = await getUserCustomers(sessionProfile.id);
      const sortedCustomers = customers.sort(
        (a, b) => b.timestamp.seconds - a.timestamp.seconds
      );
      setCustomers(sortedCustomers);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Load consumptions.
   */
  async function loadConsumptions() {
    try {
      if (!isSignedIn || !sessionProfile) return;
      const consumptions = await getUserConsumptions(sessionProfile.id);
      const sortedConsumptions = consumptions.sort(
        (a, b) => b.timestamp.seconds - a.timestamp.seconds
      );
      setConsumptions(sortedConsumptions);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    if (customers === null) loadCustomers();
    if (consumptions === null) loadConsumptions();
  }, []);

  function exportData() {
    const activeTab = tabs.find((tab) => tab.current);
    if (!activeTab) return;
    if (activeTab.name === TabType.CUSTOMERS) {
      exportCustomers();
    } else if (activeTab.name === TabType.PURCHASES) {
      exportConsumptions();
    }
  }

  /**
   * Export data to CSV.
   * @param filename The filename.
   * @param header The header.
   * @param data The data.
   */
  function exportCSV(filename: string, header: string, data: any[]) {
    const csvHeader = header;
    const csv = csvHeader + "\n" + data.join("\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function exportCustomers() {
    const csvHeader = "Name,Email,Products,Total,Since";

    const csvData = (customers || []).map((customer) => {
      return `${customer.name},${customer.mail},${customer.productIds.length},${
        customer.price
      },${timestampToLocaleDateTimeShort(customer.timestamp)}`;
    });

    exportCSV("customers.csv", csvHeader, csvData);
  }

  function exportConsumptions() {
    const csvHeader = "Product,Customer,Price,Purchase date";

    const csvData = (consumptions || []).map((consumption) => {
      return `${localizedValue(consumption.productName)},${
        customers?.find((customer) => customer.id === consumption.customerId)
          ?.name || "Unknown"
      },${consumption.productPrice},${timestampToLocaleDateTimeShort(
        consumption.timestamp
      )}`;
    });

    exportCSV("consumptions.csv", csvHeader, csvData);
  }

  if (!isSignedIn || !sessionProfile || !customers || !consumptions)
    return <LoadingScreenComponent />;

  const renderCustomersTab = () => {
    if (!customers || customers.length === 0) {
      return (
        <NoDataStateComponent
          heading1="Build your audience"
          heading2="No customers yet"
          description="No customers yet. Once you have customers, they will be listed here."
        />
      );
    }

    return (
      <TableComponent headers={["Name", "Email", "Products", "Total", "Since"]}>
        {customers.map((customer) => (
          <CustomerTableRow key={customer.mail} customer={customer} />
        ))}
      </TableComponent>
    );
  };

  const renderPurchasesTab = () => {
    if (!consumptions || consumptions.length === 0) {
      return (
        <NoDataStateComponent
          heading1="Build your audience"
          heading2="No purchases yet"
          description="No purchases yet. Once you have purchases, they will be listed here."
        />
      );
    }

    return (
      <TableComponent
        headers={["Product", "Customer", "Price", "Purchase Date"]}
      >
        {consumptions.map((consumption) => (
          <ConsumptionTableRow
            key={consumption.customerId}
            consumption={consumption}
            customers={customers}
          />
        ))}
      </TableComponent>
    );
  };

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TITLE + SCREEN_ACCOUNT_DASHBOARD,
            ACCOUNT_AUDIENCE_DESCRIPTION
          )
        }
      />
      <DashboardH1
        title="Audience"
        subtitle="Understand your audience and their purchases."
      />
      <div>
        <div className="w-full flex items-top">
          <TabsComponent handleTabClick={handleTabClick} tabs={tabs} />
          <div className="ml-auto">
            {tabs.find((tab) => tab.current)?.name === TabType.CUSTOMERS &&
              customers &&
              customers.length !== 0 && (
                <ButtonComponent
                  style="structural"
                  text={"Export"}
                  onClick={() => exportData()}
                />
              )}

            {tabs.find((tab) => tab.current)?.name === TabType.PURCHASES &&
              consumptions &&
              consumptions.length !== 0 && (
                <ButtonComponent
                  style="structural"
                  text={"Export"}
                  onClick={() => exportData()}
                />
              )}
          </div>
        </div>
        {tabs.map((tab) => (
          <div key={tab.name} className="mt-8">
            {tab.current && (
              <div>
                {tab.name === TabType.CUSTOMERS && renderCustomersTab()}
                {tab.name === TabType.PURCHASES && renderPurchasesTab()}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(AudiencePage);
