import React from "react";
import { useLocation } from "react-router-dom";
import { FooterSocialLinks } from "../../../../utils/constants";
import FooterSocialsComponent from "../../footer/FooterSocialsComponent";
import FooterLegalComponent from "../../footer/FooterLegalComponent";
import { classNames } from "../../../../utils/utils-react";
import { Destination } from "../../../../utils/constants-navigation";

const DashboardFooterComponent = () => {
  return (
    <footer aria-label="Dashboard Footer">
      <div className="rounded-xl m-8 bg-gray-50 px-8">
        <div>
          <p className="pt-16 font-normal text-sm text-gray-500 text-center leading-6">
            Do you have any questions or need help?{" "}
            <a
              className="text-indigo-500"
              href={Destination.CONTACT}
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us.
            </a>{" "}
            <br /> We understand that you are a creator and will assist you as
            soon as possible.
          </p>
          <div className="mt-12" />
          <FooterSocialsComponent />
        </div>
        <FooterLegalComponent />
      </div>
    </footer>
  );
};

export default DashboardFooterComponent;
