import {
  ArrowDownCircleIcon,
  EyeIcon,
  FaceSmileIcon,
  HeartIcon,
  IdentificationIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import { profile } from "console";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SEOComponent, {
  SEOPage,
} from "../../../components/common/seo/SEOComponent";
import { Profile } from "../../../models/Profile";
import { Template } from "../../../models/Template";
import {
  getUserByUserId,
  getUserDraftTemplates,
  getUserPublicTemplates,
} from "../../../services/firestore-service";
import { RootState } from "../../../store";
import { Destination } from "../../../utils/constants-navigation";
import {
  ACCOUNT_DASHBOARD_DESCRIPTION,
  SCREEN_ACCOUNT_DASHBOARD,
  SCREEN_TITLE,
} from "../../../utils/constants-seo";
import PeriodId from "../../../utils/utils-period";
import HomeActionsSection, { Action } from "./HomeActionsSection";
import HomeActivePromotionsItem from "./HomeActivePromotionsSection";
import DraftTemplatesComponent, {
  DraftTemplate,
} from "./HomeDraftTemplatesSection";
import HomeProfilePromotionsSection from "./HomeProfilePromotionsSection";
import HomeStatisticsSection, { Stats } from "./HomeStatsticsSection";
import HomeTemplatePromotionsSection, {
  TemplatePromotion,
} from "./HomeTemplatePromotionsSection";
import { Logger } from "../../../utils/utils-logging";
import {
  DashboardH1,
  DashboardH2,
  DashboardH3,
} from "../../../components/common/common/DashboardHeaderComponents";

interface HomePageProps {}

type ChangeType = "increase" | "decrease" | "neutral";

type TemplatePromotionSuggestionStat = {
  name: string;
  value: string;
  change: string;
  changeType: ChangeType;
};

const HomePage: React.FC<HomePageProps> = ({}) => {
  const logger = new Logger("HomePage");
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const navigate = useNavigate();
  const [templates, setTemplates] = React.useState<Map<
    string,
    Template[]
  > | null>(null);

  const [actions, setActions] = React.useState<Action[]>([]);
  const [stats, setStats] = React.useState<Stats[]>([]);

  const [profilePromotionSuggestion, setProfilePromotionSuggestion] =
    React.useState<Profile | null>(null);

  const [templatePromotions, setTemplatePromotionSuggestions] = React.useState<
    TemplatePromotion[]
  >([]);

  const [activeTemplatePromotions, setActiveTemplatePromotions] =
    React.useState<TemplatePromotion[]>([]);

  const [activeProfilePromotion, setActiveProfilePromotion] =
    React.useState<Profile | null>(null);

  const [draftTemplates, setDraftFinishSuggestions] = React.useState<
    DraftTemplate[]
  >([]);

  React.useEffect(() => {
    const fetchTemplates = async () => {
      const publicTemplates = await getUserPublicTemplates(sessionProfile!!.id);
      const sortedPublicTemplates = publicTemplates.sort(
        (a, b) => b.published!.seconds - a.published!.seconds
      );
      const draftTemplates = await getUserDraftTemplates(sessionProfile!!.id);
      const sortedDraftTemplates = draftTemplates.sort(
        (a, b) => b.created!!.seconds - a.created!!.seconds
      );
      setTemplates(
        new Map([
          ["Public", sortedPublicTemplates],
          ["Drafts", sortedDraftTemplates],
        ])
      );
    };
    fetchTemplates();
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    updateActions();
    updateStats();
    updateDraftFinishSuggestions();
    updatePromotions();
  }, [templates]);

  /**
   * Updates the actions to be displayed on the home screen.
   */
  function updateActions() {
    const actions = getActions(sessionProfile, templates);
    setActions(actions);
  }

  /**
   * Updates the stats to be displayed on the home screen.
   */
  function updateStats() {
    const stats = getStats(sessionProfile, templates);
    setStats(stats);
  }

  /**
   * Updates the draft finish suggestions to be displayed on the home screen.
   */
  function updateDraftFinishSuggestions() {
    const draftFinishSuggestions = getDraftFinishSuggestions(
      sessionProfile,
      templates
    );
    setDraftFinishSuggestions(draftFinishSuggestions);
  }

  /**
   * Updates the template promotions to be displayed on the home screen. Either as active or suggested.
   */
  async function updatePromotions() {
    const user = await getUserByUserId(sessionProfile!!.id);
    const [suggestions, active] = getTemplatePromotions(user, templates);

    logger.log("updatePromotions", {
      user: user,
      suggestions: suggestions,
      active: active,
      userPromotion: user?.promotion,
      profilePromotionSuggestion: user?.promotion,
    });

    setTemplatePromotionSuggestions(suggestions);
    setProfilePromotionSuggestion(user?.promotion == null ? user : null);
    setActiveTemplatePromotions(active);
    setActiveProfilePromotion(user?.promotion != null ? user : null);
  }

  /**
   * Returns a list of actions to be displayed on the home screen.
   * @param profile Profile of the user.
   * @param templates Map of templates to be displayed on the home screen.
   * @returns {Action[]} actions to be displayed on the home screen.
   */
  function getActions(
    profile: Profile | null,
    templates: Map<string, Template[]> | null
  ): Action[] {
    const actions = Array<Action>();
    if (!profile || !templates) return actions;

    const switchAllowAll = false;

    if (
      switchAllowAll ||
      profile.links.length == 0 ||
      profile.bio == null ||
      profile.bio == ""
    ) {
      actions.push({
        title: "Setup your profile",
        description:
          "Edit your name, profile picture, social profile links and more.",
        href: Destination.ACCOUNT_PROFILE,
        Icon: FaceSmileIcon,
        style: "text-teal-500 bg-teal-50",
      });
    }

    if (
      switchAllowAll ||
      profile.profileId == null ||
      profile.profileId == ""
    ) {
      actions.push({
        title: "Setup your user name",
        description:
          "Setup your user name to make it easier for others to find you.",
        href: Destination.ACCOUNT_PROFILE,
        Icon: IdentificationIcon,
        style: "text-green-500 bg-green-50",
      });
    }

    const publicTemplates = templates.get("Public") || [];
    const draftTemplates = templates.get("Drafts") || [];

    if (
      switchAllowAll ||
      (publicTemplates.length == 0 && draftTemplates.length == 0)
    ) {
      actions.push({
        title: "Create your first template",
        description:
          "Begin your journey on Elcovia by crafting your first unique Notion Template.",
        href: Destination.ACCOUNT_TEMPLATES,
        Icon: RectangleGroupIcon,
        style: "text-purple-500 bg-purple-50",
      });
    }

    if (
      switchAllowAll ||
      (draftTemplates.length == 0 && publicTemplates.length > 2)
    ) {
      actions.push({
        title: "Create another template",
        description:
          "Continue your journey on Elcovia by crafting another unique Notion Template.",
        href: Destination.ACCOUNT_TEMPLATES,
        Icon: RectangleGroupIcon,
        style: "text-purple-500 bg-purple-50",
      });
    }

    if (publicTemplates.length > 0) {
      const views = publicTemplates.reduce(
        (total, template) => total + (template.views["total"] || 0),
        0
      );
      const likes = publicTemplates.reduce(
        (total, template) => total + (template.likes["total"] || 0),
        0
      );

      const downloads = publicTemplates.reduce(
        (total, template) => total + (template.downloads["total"] || 0),
        0
      );

      if (switchAllowAll || likes == 0) {
        actions.push({
          title: "Get your first likes",
          description:
            "Share your templates on social networks and optimize your template listings to get more likes.",
          href: Destination.ACCOUNT_TEMPLATES,
          Icon: HeartIcon,
          style: "text-red-500 bg-red-50",
        });
      }
      if (switchAllowAll || views == 0) {
        actions.push({
          title: "Get your first downloads",
          description:
            "Share your templates on social networks and optimize your template listings to get more downloads.",
          href: Destination.ACCOUNT_TEMPLATES,
          Icon: ArrowDownCircleIcon,
          style: "text-yellow-500 bg-yellow-50",
        });
      }
      if (switchAllowAll || downloads == 0) {
        actions.push({
          title: "Get your first views",
          description:
            "Share your templates on social networks and optimize the visuals of your template listings to get more views.",
          href: Destination.ACCOUNT_TEMPLATES,
          Icon: EyeIcon,
          style: "text-sky-500 bg-sky-50",
        });
      }
    }
    return actions;
  }

  /**
   * Returns a list of stats to be displayed on the home screen.
   * @param profile Profile of the user.
   * @param templates Map of templates to be displayed on the home screen.
   * @returns {Stats[]} stats to be displayed on the home screen.
   */
  function getStats(
    profile: Profile | null,
    templates: Map<string, Template[]> | null
  ): Stats[] {
    const stats = Array<Stats>();
    if (!profile || !templates) return stats;

    const formatter = new Intl.NumberFormat();

    const publicTemplates = templates.get("Public") || [];
    const draftTemplates = templates.get("Drafts") || [];

    const views = publicTemplates.reduce(
      (total, template) => total + (template.views["total"] || 0),
      0
    );
    const likes = publicTemplates.reduce(
      (total, template) => total + (template.likes["total"] || 0),
      0
    );

    const downloads = publicTemplates.reduce(
      (total, template) => total + (template.downloads["total"] || 0),
      0
    );

    stats.push({
      name: "Likes",
      value: `${formatter.format(likes)}`,
      rawValue: likes,
      Icon: HeartIcon,
      style: "text-red-500 bg-red-50",
    });

    stats.push({
      name: "Downloads",
      value: `${formatter.format(downloads)}`,
      rawValue: downloads,
      Icon: ArrowDownCircleIcon,
      style: "text-yellow-500 bg-yellow-50",
    });

    stats.push({
      name: "Views",
      value: `${formatter.format(views)}`,
      rawValue: views,
      Icon: EyeIcon,
      style: "text-sky-500 bg-sky-50",
    });

    stats.push({
      name: "Published",
      value: `${formatter.format(publicTemplates.length)}`,
      rawValue: publicTemplates.length,
      Icon: RectangleGroupIcon,
      style: "text-green-500 bg-green-50",
    });

    stats.push({
      name: "Drafts",
      value: `${formatter.format(draftTemplates.length)}`,
      rawValue: draftTemplates.length,
      Icon: RectangleGroupIcon,
      style: "text-gray-500 bg-gray-50",
    });

    return stats;
  }

  const calculatePercentChange = (
    thisMonth: number,
    lastMonth: number
  ): number => {
    if (isNaN(lastMonth) || isNaN(thisMonth)) {
      return 0;
    }
    if (lastMonth < 0) lastMonth = 0;

    if (lastMonth === 0) {
      if (thisMonth === 0) return 0;
      else return 1;
    }
    if (thisMonth === 0) {
      thisMonth = 1;
    }

    return (thisMonth - lastMonth) / Math.abs(lastMonth);
  };

  /**
   * Returns a list of template promotion suggestions to be displayed on the home screen.
   * @param profile Profile of the user.
   * @param templates Map of templates to be displayed on the home screen.
   * @returns {TemplatePromotion[]} Template promotion suggestions to be displayed on the home screen.
   */
  function getTemplatePromotions(
    profile: Profile | null,
    templates: Map<string, Template[]> | null
  ): any[] {
    const suggestions: TemplatePromotion[] = [];
    const active: TemplatePromotion[] = [];

    if (!templates) return [suggestions, active];

    const publicTemplates = templates.get("Public") || [];

    const formatter = new Intl.NumberFormat();
    const percentageFormatter = new Intl.NumberFormat(undefined, {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    for (let template of publicTemplates) {
      const stats = Array<TemplatePromotionSuggestionStat>();

      const thisMonthkey = PeriodId.month().getKey();
      const thisMonthViews = template.views[thisMonthkey] || 0;
      const thisMonthLikes = template.likes[thisMonthkey] || 0;
      const thisMonthDownloads = template.downloads[thisMonthkey] || 0;

      const lastMonthkey = PeriodId.lastMonth().getKey();
      const lastMonthViews = template.views[lastMonthkey] || 0;
      const lastMonthLikes = template.likes[lastMonthkey] || 0;
      const lastMonthDownloads = template.downloads[lastMonthkey] || 0;

      const changeInViewsPercent = calculatePercentChange(
        thisMonthViews,
        lastMonthViews
      );
      const changeInLikesPercent = calculatePercentChange(
        thisMonthLikes,
        lastMonthLikes
      );
      const changeInDownloadsPercent = calculatePercentChange(
        thisMonthDownloads,
        lastMonthDownloads
      );

      stats.push({
        name: "Likes",
        value: `${formatter.format(lastMonthLikes)} ‣ ${formatter.format(
          thisMonthLikes
        )}`,
        change: percentageFormatter.format(changeInLikesPercent),
        changeType:
          changeInLikesPercent > 0
            ? "increase"
            : changeInLikesPercent < 0
            ? "decrease"
            : "neutral",
      });

      stats.push({
        name: "Downloads",
        value: `${formatter.format(lastMonthDownloads)} ‣ ${formatter.format(
          thisMonthDownloads
        )}`,
        change: percentageFormatter.format(changeInDownloadsPercent),
        changeType:
          changeInDownloadsPercent > 0
            ? "increase"
            : changeInDownloadsPercent < 0
            ? "decrease"
            : "neutral",
      });

      stats.push({
        name: "Views",
        value: `${formatter.format(lastMonthViews)} ‣ ${formatter.format(
          thisMonthViews
        )}`,
        change: percentageFormatter.format(changeInViewsPercent),
        changeType:
          changeInViewsPercent > 0
            ? "increase"
            : changeInViewsPercent < 0
            ? "decrease"
            : "neutral",
      });

      if (!template.promotion) {
        suggestions.push({
          template: template,
          stats: stats,
        });
      } else {
        active.push({
          template: template,
          stats: stats,
        });
      }
    }

    return [suggestions, active];
  }

  /**
   * Returns a list of draft finish suggestions to be displayed on the home screen.
   * @param profile Profile of the user.
   * @param templates Map of templates to be displayed on the home screen.
   * @returns {DraftTemplate[]} Template promotion suggestions to be displayed on the home screen.
   */
  function getDraftFinishSuggestions(
    profile: Profile | null,
    templates: Map<string, Template[]> | null
  ): DraftTemplate[] {
    const suggestions = Array<DraftTemplate>();
    if (!templates) return suggestions;
    const draftTemplates = templates.get("Drafts") || [];

    for (let template of draftTemplates) {
      suggestions.push({
        template: template,
      });
    }

    return suggestions;
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TITLE + SCREEN_ACCOUNT_DASHBOARD,
            ACCOUNT_DASHBOARD_DESCRIPTION
          )
        }
      />
      <DashboardH1
        title="Dashboard"
        subtitle="Welcome to your dashboard. Here you can view your statistics, drafts, promotions and more."
      />
      <div className="space-y-16">
        <DashboardH2
          title="Actions"
          visible={actions.length > 0}
          children={<HomeActionsSection actions={actions} />}
        />

        <DashboardH2
          title="Statistics"
          visible={stats.length > 0}
          children={<HomeStatisticsSection stats={stats} />}
        />

        <DashboardH2
          title="Drafts"
          subtitle={`You have ${draftTemplates.length}
                    ${draftTemplates.length === 1 ? "draft" : "drafts"}.
                    ${
                      draftTemplates.length === 1
                        ? "Wrap it up and publish it to the world."
                        : "Wrap them up and publish them to the world."
                    }`}
          visible={draftTemplates.length > 0}
          children={<DraftTemplatesComponent draftTemplates={draftTemplates} />}
        />

        <DashboardH2
          title="Promote your templates"
          subtitle="Promote your templates to get more traffic."
          visible={templatePromotions.length > 0}
          children={
            <HomeTemplatePromotionsSection
              templatePromotions={templatePromotions}
            />
          }
        />

        <DashboardH2
          title="Promote your profile"
          subtitle="Promote your profile to get more traffic for your complete profile."
          visible={profilePromotionSuggestion != null}
          children={
            <HomeProfilePromotionsSection
              profile={profilePromotionSuggestion}
            />
          }
        />

        <DashboardH2
          title="Active promotions"
          visible={
            activeTemplatePromotions.length > 0 ||
            activeProfilePromotion != null
          }
          children={
            <HomeActivePromotionsItem
              activeProfilePromotion={activeProfilePromotion}
              activeTemplatePromotions={activeTemplatePromotions}
            />
          }
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(HomePage);
