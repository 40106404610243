import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import React from "react";

import { Template } from "../../../models/Template";
import { Destination, setPathId } from "../../../utils/constants-navigation";
import { localizedValue } from "../../../utils/supported-locales";
import { classNames } from "../../../utils/utils-react";

export type TemplatePromotion = {
  template: Template;
  stats: TemplatePromotionStat[];
};

type TemplatePromotionStat = {
  name: string;
  value: string;
  changeType: "increase" | "decrease" | "neutral";
  change: string;
};

interface TemplatePromotionItemProps {
  suggestion: {
    template: {
      id: string;
      thumbnailImage: string;
      title: string;
    };
    stats: Array<{
      name: string;
      value: string;
      changeType: "increase" | "decrease" | "neutral";
      change: string;
    }>;
  };
}

export const TemplatePromotionItem: React.FC<TemplatePromotionItemProps> = ({
  suggestion,
}) => {
  /**
   * Initiates the template promotion flow.
   */
  async function promoteTemplate(templateId: string) {
    const path = setPathId(
      Destination.ACCOUNT_TEMPLATE_PROMOTION_ID,
      templateId
    );
    window.open(path, "_blank");
  }

  return (
    <div
      onClick={() => promoteTemplate(suggestion.template.id)}
      key={suggestion.template.id}
      className="transition-all ease-in-out duration-300 bg-white rounded-lg border border-gray-200/50 hover:shadow-md hover:md:scale-[1.02]"
    >
      <div className="bg-indigo-500/[0.025] w-full flex items-center gap-x-7 border-b border-gray-900/5 bg-white-50 p-3">
        <img
          src={suggestion.template.thumbnailImage || ""}
          alt={suggestion.template.title}
          className="h-16 w-16 flex-none rounded-md bg-white object-cover shadow"
        />
        <h4 className="text-md font-semibold leading-6 text-gray-900 line-clamp-2">
          {suggestion.template.title}
        </h4>
      </div>
      <dl className="px-3 divide-y divide-gray-200/50 text-sm leading-6">
        {suggestion.stats.map((stat) => (
          <div className="flex justify-between gap-x-4 py-3" key={stat.name}>
            <dt className="text-gray-500">{stat.name}</dt>
            <dd className="flex items-center gap-x-4">
              <div className="font-medium text-gray-900">{stat.value}</div>
              <div>
                {stat.changeType === "increase" && (
                  <ArrowUpIcon
                    className="h-4 w-4 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                )}
                {stat.changeType === "decrease" && (
                  <ArrowDownIcon
                    className="h-4 w-4 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}
                {stat.changeType === "neutral" && (
                  <div
                    className="h-4 w-4 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div
                className={classNames(
                  stat.changeType === "increase"
                    ? "text-green-500"
                    : stat.changeType === "decrease"
                    ? "text-red-500"
                    : "text-gray-500"
                )}
              >
                {stat.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

interface TemplatePromotionsSectionProps {
  templatePromotions: TemplatePromotion[];
}

const HomeTemplatePromotionsSection: React.FC<
  TemplatePromotionsSectionProps
> = ({ templatePromotions: templatePromotionSuggestions }) => {
  if (templatePromotionSuggestions.length === 0) return null;

  return (
    <ul
      role="list"
      className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
    >
      {templatePromotionSuggestions.map((suggestion) => (
        <TemplatePromotionItem
          key={suggestion.template.id}
          suggestion={{
            template: {
              id: suggestion.template.id,
              thumbnailImage:
                localizedValue(suggestion.template.thumbnailImage) || "",
              title: localizedValue(suggestion.template.title) || "",
            },
            stats: suggestion.stats.map((stat) => ({
              name: stat.name,
              value: stat.value,
              changeType: stat.changeType,
              change: stat.change,
            })),
          }}
        />
      ))}
    </ul>
  );
};

export default HomeTemplatePromotionsSection;
