import React from "react";
import NewsletterComponent from "../NewsletterComponent";
import { useLocation } from "react-router-dom";
import { Destination } from "../../../utils/constants-navigation";
import {
  AffiliateLinks,
  ExternalLinks,
  FooterSocialLinks,
} from "../../../utils/constants";
import FooterLinksComponent from "./FooterLinksComponent";
import { FooterSections } from "./FooterLinkComponent";
import FooterSocialsComponent from "./FooterSocialsComponent";
import FooterLegalComponent from "./FooterLegalComponent";
import NotionAffiliateBanner from "../../affiliate/notion/NotionAffiliateBanner";

const FooterComponent = () => {
  const location = useLocation();

  /**
   * Check if the newsletter should be visible.
   * @returns True if the newsletter should be visible.
   */
  function isNewsletterVisible() {
    if (
      location.pathname == "" ||
      location.pathname == Destination.DISCOVER ||
      location.pathname == Destination.TEMPLATES ||
      location.pathname == Destination.USERS
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      <NotionAffiliateBanner />
      <footer aria-label="Site Footer">
        <div>{isNewsletterVisible() && <NewsletterComponent />}</div>
        <div className="bg-gray-50">
          <div className="mx-auto max-w-screen-xl py-8 sm:px-6 lg:px-8">
            <div>
              <div className="mt-24"></div>
              <FooterLinksComponent />
              <div className="mt-16"></div>
              <FooterSocialsComponent />
            </div>
            <FooterLegalComponent />
          </div>
        </div>
      </footer>{" "}
    </div>
  );
};

export default FooterComponent;
