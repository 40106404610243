import { Link } from "react-router-dom";
import { TemplateCoverImage } from "../../../../components/promotion/TemplateCoverImage";
import { Template } from "../../../../models/Template";
import { localeKey, localizedValue } from "../../../../utils/supported-locales";
import { classNames } from "../../../../utils/utils-react";
import {
  setPathId,
  Destination,
  setPathIdAndAppend,
  ARGS_PARAM_DISCOVER,
} from "../../../../utils/constants-navigation";
import TemplateCoverImageSection from "../template/TemplateCoverImageSection";
import { template } from "lodash";
import { Profile } from "../../../../models/Profile";
import React from "react";
import {
  getUserByProfileId,
  getUserByUserId,
} from "../../../../services/firestore-service";
import ProfileItemComponent from "../../../../components/profile/ProfileItem";
import ProfileMentionComponent from "../../../../components/profile/ProfileMentionComponent";

type AlternatingTemmplateItemComponentProps = {
  index: number;
  isDiscover?: boolean;
  template: Template;
};

const AlternatingTemmplateItemComponent: React.FC<
  AlternatingTemmplateItemComponentProps
> = ({ index, template, isDiscover = false }) => {
  const [creator, setCreator] = React.useState<Profile | null>();

  async function loadCreator(id: string) {
    try {
      const creator = await getUserByUserId(id);
      setCreator(creator);
    } catch (error) {
      console.error("Error loading creator", error);
    }
  }

  React.useEffect(() => {
    if (template.userId) {
      loadCreator(template.userId);
    }
  }, [template]);

  return (
    <>
      <Link
        key={localizedValue(template.title)}
        className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center space-y-12 lg:space-y-0 lg:gap-x-16 group"
        to={setPathIdAndAppend(
          Destination.TEMPLATE_ID_SHORT,
          template.id,
          isDiscover ? ARGS_PARAM_DISCOVER : undefined
        )}
      >
        {/* Content Layout */}
        <div
          className={classNames(
            index % 2 === 0 ? "lg:col-start-1" : "lg:col-start-8 xl:col-start-",
            "mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4",
            "order-2", // Reversed order for smaller screens,
            "text-center lg:text-left max-w-xl mx-auto lg:mx-0 lg:max-w-none"
          )}
        >
          <h3 className="mt-8 text-1xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            {localizedValue(template.title)}
          </h3>
          <p className="mt-2 text-md text-gray-500 leading-7">
            {localizedValue(template.metaData?.desc)}
          </p>
          <p className="mt-4 ml-2">
            {creator && (
              <ProfileMentionComponent
                isDiscover={isDiscover}
                profile={creator}
              />
            )}
          </p>
        </div>

        {/* Image Layout */}
        <div
          className={classNames(
            index % 2 === 0
              ? "lg:col-start-6 xl:col-start-5"
              : "lg:col-start-1",
            "flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8",
            "order-1" // Reversed order for smaller screens
          )}
        >
          <TemplateCoverImageSection
            coverImages={template.coverImages}
            title={template.title}
            customImageClasses="transition-all ease-in-out duration-300 shadow-sm rounded-lg border border-1 border-gray-200/50 group-hover:shadow-md group-hover:lg:scale-[1.01]"
            locale={"en-US"}
            onViewImages={(images) => {}}
          />
        </div>
      </Link>
    </>
  );
};

type AlternatingTemplatesComponentProps = {
  templates: Template[];
  isDiscover?: boolean;
};

/**
 * Displays the template features.
 */
export const AlternatingTemplatesComponent = ({
  templates,
  isDiscover = false,
}: AlternatingTemplatesComponentProps) => {
  return (
    <>
      {templates && (
        <>
          <div className="mt-16 space-y-16 lg:space-y-32">
            {templates?.map((template: Template, index: number) => (
              <AlternatingTemmplateItemComponent
                isDiscover={isDiscover}
                key={template.id}
                index={index}
                template={template}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};
