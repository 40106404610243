import { getAuth, GoogleAuthProvider, signInWithPopup, TwitterAuthProvider, User } from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  FieldValue,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  runTransaction,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';

import { OrderDetails, orderDetailsConverter } from '../models/data/payment/Order';
import { Profile, profileConverter } from '../models/Profile';
import { Template, templateConverter } from '../models/Template';
import { UserSecret, userSecretConverter } from '../models/UserSecret';
import {
  FIREBASE_COLLECTION_CUSTOMER_CONSUMPTIONS,
  FIREBASE_COLLECTION_CUSTOMERS,
  FIREBASE_COLLECTION_DISCOVER,
  FIREBASE_COLLECTION_PROFILE_IDS,
  FIREBASE_COLLECTION_ORDERS,
  FIREBASE_COLLECTION_TEMPLATES,
  FIREBASE_COLLECTION_TEMPLATES_OWNED,
  FIREBASE_COLLECTION_USERS,
  FIREBASE_COLLECTION_USERS_SECRET,
  FIREBASE_PROFILE_LAST_ID_CHANGE,
  FIREBASE_PROFILE_PROFILE_ID,
  FIREBASE_COLLECTION_TEMPLATES_LIKED,
} from '../utils/constants';
import { firestore } from '../utils/firebase';
import { DEFAULT_LOCALE, localeKey } from '../utils/supported-locales';
import PeriodId from '../utils/utils-period';
import { Discover, discoverConverter, DiscoverList } from '../models/Discover';
import { Customer, customerConverter } from '../models/Customer';
import { CustomerConsumption, customerConsumptionConverter } from '../models/CustomerConsumption';
import { trackLogin, trackLogout, trackLogoutError, trackSignup } from './tracking-service';
import { ProfileId, profileIdConverter } from '../models/ProfileId';
import { Logger } from '../utils/utils-logging';
import { AvatarIconProvider } from '../utils/AvatarIconProvider';
import { getLocalStorage, LocalStorageKey, setLocalStorageWithPostfix, setLocalStorageWithSuffix } from '../utils/utils-local-storage';

const logger = new Logger("firestore-service.ts");

const auth = getAuth();
const currentUser = auth.currentUser;

const profileIdsCollection = collection(firestore, FIREBASE_COLLECTION_PROFILE_IDS);
const usersCollection = collection(firestore, FIREBASE_COLLECTION_USERS);
const usersSecretCollection = collection(firestore, FIREBASE_COLLECTION_USERS_SECRET);
const templatesCollection = collection(firestore, FIREBASE_COLLECTION_TEMPLATES);
const discoverCollection = collection(firestore, FIREBASE_COLLECTION_DISCOVER);

// Document references
const userSecretDocument = (userId: string) => doc(usersSecretCollection, userId);
const userDocument = (userId: string) => doc(usersCollection, userId);
const profileIdDocument = (profileId: string) => doc(profileIdsCollection, profileId);


export const updateUser = async (profile: Profile) => {
  const profileDoc = profileConverter.toFirestore(profile);
  console.log('updateUser() - profileDoc:', profileDoc);
  await updateDoc(userDocument(profile.id!!), profileDoc);
}

export const setUser = async (profile: Profile) => {
  const profileDoc = profileConverter.toFirestore(profile);
  await setDoc(userDocument(profile.id!!), profileDoc);
}

export const deleteUser = async (userId: any) => {
  await deleteDoc(userDocument(userId));
}

export const getUser = async (userId: string): Promise<Profile | null> => {
  const profile = await getUserByProfileId(userId);
  if (profile != null) {
    return profile;
  } else {
    return await getUserByUserId(userId);
  }
}

export const getUserByProfileId = async (profileId: string): Promise<Profile | null> => {
  const uid = await getNameId(profileId);
  if (uid == null) return null;
  const userDoc = await getDoc(userDocument(uid));
  if (userDoc.exists()) {
    const profile = profileConverter.fromFirestore(userDoc);
    return profile;
  } else {
    return null;
  }
}

export const getUserByUserId = async (userId: string): Promise<Profile | null> => {
  const userDoc = await getDoc(userDocument(userId));
  if (userDoc.exists()) {
    const profile = profileConverter.fromFirestore(userDoc);
    return profile;
  } else {
    return null;
  }
}

export const getUserSecretById = async (userId: string): Promise<UserSecret | null> => {
  const userSecretDoc = await getDoc(userSecretDocument(userId));
  if (userSecretDoc.exists()) {
    return userSecretConverter.fromFirestore(userSecretDoc.data());
  } else {
    return null;
  }
}

/**
 * Checks if the customer owns the template.
 * @param customerId The customer's id.
 * @param templateId The template's id.
 * @returns A Promise that resolves with a boolean.
 */
export const checkCustomerOwnsTemplate = async (creatorId: string, customerId: string, templateId: string): Promise<boolean> => {
  const reference = customerId + "-" + templateId;
  console.log("reference:", reference);
  const userConsumptionsCollectionRef = collection(userDocument(creatorId), FIREBASE_COLLECTION_CUSTOMER_CONSUMPTIONS)
  const userConsumptionDocRef = doc(userConsumptionsCollectionRef, reference);
  const userConsumptionDoc = await getDoc(userConsumptionDocRef);
  return userConsumptionDoc.exists();
};

/**
 * Setup a new user in Firestore. This is called when a user logs in for the first time.
 * @param user The user object from firebase auth.
 */
export const setupNewUserIfNecessary = async (user: User) => {
  const userDoc = await getDoc(userDocument(user.uid));
  if (userDoc.exists()) {
    trackLogin();
    return;
  }
  trackSignup();

  // Create a new profile
  const profile = new Profile(
    /* id */ user.uid,
    /* verified */ false,
    /* notionCertified */ false,
    /* creator */ false,
    /* promotion */ null,
    /* displayName */ user.displayName,
    /* profileId */ null,
    /* lastIdChange */ null,
    /* bio */ "",
    /* image */ new AvatarIconProvider().getRandomNotioly(),
    /* links */[],
    /* mail */ user.email,
    /* created */ Timestamp.now(),
    /* templateLikes */ {},
    /* templateViews */ {},
    /* templateDownloads */ {},
    /* views */ {},
  );
  logger.log("Setting up new user: ", profile);
  await setUser(profile);
}

/**
 * Sets the profileId for the user.
 * @param uid The user's id.
 * @param profileId The profileId to set.
 * @returns A Promise that resolves with void.
 */
export const setProfileId = async (uid: string, profileId: string) => {
  const obj = new ProfileId(profileId, uid, Timestamp.now());
  const profileIdDoc = profileIdConverter.toFirestore(obj);
  await setDoc(profileIdDocument(profileId), profileIdDoc);
}

/**
 * Checks if the profileId is available.
 * @param profileId The profileId to check.
 * @returns A Promise that resolves with a boolean.
 *         `true` if the profileId is available, `false` otherwise.
 */
export const isProfileIdAvailable = async (profileId: string): Promise<boolean> => {
  const profileIdDoc = await getDoc(profileIdDocument(profileId));
  return !profileIdDoc.exists();
}

/**
 * Function that updates the current profileId to the given `newProfileId`.
 * @param userId The user's id.
 * @param oldProfileId The old profileId to update.
 * @param newProfileId The new profileId to update to.
 * @return a [Result] of `true` if the profileId was successfully updated.
 */
export async function updateProfileId(userId: string, oldProfileId: string | null, newProfileId: string): Promise<void> {
  logger.log("updateProfileId()", { currentProfileId: oldProfileId, newProfileId });
  try {
    return await runTransaction(firestore, async (transaction) => {
      const newProfileIdRef = doc(profileIdsCollection, newProfileId);
      if ((await getDoc(newProfileIdRef)).exists()) {
        logger.logError("updateProfileId() - Profile id is already in use.");
        throw new Error("Profile id is already in use.");
      }

      // // Save new profile id reference
      const profileId = new ProfileId(newProfileId, userId, Timestamp.now());
      transaction.set(newProfileIdRef, profileIdConverter.toFirestore(profileId));
      logger.log("updateProfileId() - Saved new profile id reference: ", { profileId });

      // Update id in profile
      const profileDocument = doc(usersCollection, userId);
      transaction.update(profileDocument, FIREBASE_PROFILE_PROFILE_ID, newProfileId);
      transaction.update(profileDocument, FIREBASE_PROFILE_LAST_ID_CHANGE, serverTimestamp());
      logger.log("updateProfileId() - Updated id in profile: ", newProfileId);


      // Delete old profile id
      if (oldProfileId != null) {
        const oldProfileIdRef = doc(profileIdsCollection, oldProfileId);
        transaction.delete(oldProfileIdRef);
        logger.log("updateProfileId() - Deleted old profile id: ", oldProfileId);
      }
    });
  } catch (error) {
    logger.logError("updateProfileId() - Error:", error);
    throw error;
  }
}

export const getUserLikes = async (userId: string): Promise<string[] | null> => {
  const userDoc = await getDocs(collection(userDocument(userId), FIREBASE_COLLECTION_TEMPLATES_LIKED));
  if (userDoc.docs.length > 0) {
    const likes: string[] = [];
    userDoc.docs.forEach((doc) => {
      likes.push(doc.id);
    });
    return likes;
  }
  return null;
}

export const getNameId = async (nameId: string): Promise<string | null> => {
  const userDoc = await getDoc(profileIdDocument(nameId));
  if (userDoc.exists()) {
    const uid = userDoc.data().uid;
    return uid;
  } else {
    return null;
  }
}

export const getUsers = async (): Promise<Profile[]> => {
  const usersSnapshot = await getDocs(usersCollection);
  const profiles: Profile[] = [];
  usersSnapshot.docs.forEach((doc) => {
    const profile = profileConverter.fromFirestore(doc);
    profiles.push(profile);
  });
  return profiles;
};

export const getUsersByQuery = async (...queryConstraints: QueryConstraint[]): Promise<Profile[]> => {
  const templatesByUserCollection = query(usersCollection, ...queryConstraints);
  const usersSnapshot = await getDocs(templatesByUserCollection);
  const profiles: Profile[] = [];
  usersSnapshot.docs.forEach((doc) => {
    const profile = profileConverter.fromFirestore(doc);
    profiles.push(profile);
  });
  return profiles;
};

/**
 * Get the beta users ordered by the date they joined.
 * @returns A list of beta users.
 */
export const getBetaUsersByQuery = async (): Promise<Profile[]> => {
  const queryConstraints: QueryConstraint[] = [
    where("betaTester", "==", true),
    orderBy("created", "desc"),
  ];
  return getUsersByQuery(...queryConstraints);
};

const getCounterUsers = async (counterKey: string, periodId: PeriodId, amount?: number): Promise<Profile[]> => {
  const key = counterKey + "." + periodId.getKey();
  const queryConstraints: QueryConstraint[] = [
    // where(key, ">=", 1), // TODO: Set a minimum value higher
    orderBy(key, "desc"),
  ];
  if (amount !== undefined) queryConstraints.push(limit(amount));
  return getUsersByQuery(...queryConstraints);
};

export const getLatestUsers = async (amount?: number): Promise<Profile[]> => {
  const queryConstraints: QueryConstraint[] = [
    orderBy("created", "desc")
  ];
  if (amount !== undefined) queryConstraints.push(limit(amount));
  return getUsersByQuery(...queryConstraints);
};

export const getFeaturedUsers = async (): Promise<Profile[]> => {
  return getUsersByQuery(where("promotion", "!=", null));
};

export const getLikedUsers = async (periodId: PeriodId, limit?: number): Promise<Profile[]> => {
  return getCounterUsers("templateLikes", periodId, limit);
};

export const getViewsUsers = async (periodId: PeriodId, limit?: number): Promise<Profile[]> => {
  return getCounterUsers("templateViews", periodId, limit);
};

export const getDownloadsUsers = async (periodId: PeriodId, limit?: number): Promise<Profile[]> => {
  return getCounterUsers("templateDownloads", periodId, limit);
};

export const getTemplates = async (): Promise<Template[]> => {
  const templatesSnapshot = await getDocs(templatesCollection);
  const templates: Template[] = [];
  templatesSnapshot.docs.forEach((doc) => {
    const template = templateConverter.fromFirestore(doc);
    templates.push(template);
  });
  return templates;
};

export const getTemplatesWithMetaData = async (): Promise<Template[] | null> => {
  const queryConstraints = [
    where("metaData", "!=", null),     // Check if shared is explicitly true
  ]
  const templatesWithMetaDataCollection = query(templatesCollection, ...queryConstraints);
  const templatesSnapshot = await getDocs(templatesWithMetaDataCollection);
  const templates: Template[] = [];
  templatesSnapshot.docs.forEach((doc) => {
    const template = templateConverter.fromFirestore(doc);
    if (template.metaData?.shared != true) {
      templates.push(template);
    }
  });
  return templates;
}


export const getTemplatesByQuery = async (...queryConstraints: QueryConstraint[]): Promise<Template[]> => {
  const templatesByUserCollection = query(templatesCollection, ...queryConstraints);
  const templatesSnapshot = await getDocs(templatesByUserCollection);
  const templates: Template[] = [];
  templatesSnapshot.docs.forEach((doc) => {
    const template = templateConverter.fromFirestore(doc);
    templates.push(template);
  });
  return templates;
};

export const getLatestTemplates = async (amount?: number): Promise<Template[]> => {
  const queryConstraints: QueryConstraint[] = [
    orderBy("published", "desc")
  ];
  if (amount !== undefined) queryConstraints.push(limit(amount));
  return getTemplatesByQuery(...queryConstraints);
};

export const getFeaturedTemplates = async (): Promise<Template[]> => {
  return getTemplatesByQuery(where("promotion", "!=", null));
};

/**
 * Returns the discover object.
 * @return A Promise that resolves with a Discover object.
 */
export const getDiscover = async (): Promise<Discover | null> => {
  const key = PeriodId.week().getKey();

  const serializedValue = getLocalStorage<string>(LocalStorageKey.discover, key);
  if (serializedValue) {
    const chachedDiscover = deserializeDiscover(serializedValue);
    logger.log("getDiscover() - Using cached discover object.");
    return chachedDiscover;
  }

  try {
    const docRef = doc(discoverCollection, key);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      return null;
    } else {
      const discover = discoverConverter.fromFirestore(snapshot);
      const serializedValue = serializeDiscover(discover);
      setLocalStorageWithSuffix(LocalStorageKey.discover, key, serializedValue);
      logger.log("getDiscover() - Discover object was fetched from Firestore.");
      return discover;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const serializeDiscover = (discover: Discover): string => {
  return JSON.stringify(discover, (key, value) => {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries())
      };
    }
    return value;
  });
};

const deserializeDiscover = (jsonString: string): Discover => {
  const parsedObject = JSON.parse(jsonString, (key, value) => {
    if (value && value.dataType === 'Map') {
      return new Map(value.value);
    }
    return value;
  });

  const discover = new Discover(parsedObject.timestamp);
  parsedObject.data.forEach((value: any, key: string) => {
    discover.data.set(key, new DiscoverList(
      value.counterType,
      value.dataType,
      value.timePeriod,
      value.categoryKey,
      value.data
    ));
  });

  return discover;
};

/**
 * Returns the discover object.
 * @param ids The ids of the templates to be returned.
 * @return A Promise that resolves with a Discover object.
 */
export const getTemplatesByIds = async (ids: string[]): Promise<Template[]> => {
  const templateQuery = query(templatesCollection, where(documentId(), "in", ids));
  const templateSnapshot = await getDocs(templateQuery);
  const templates: Template[] = [];
  if (!templateSnapshot.empty) {
    templateSnapshot.forEach((doc) => {
      const template = templateConverter.fromFirestore(doc);
      templates.push(template);
    });
  }
  return templates;
};

/**
 * Returns the discover object.
 * @param ids The ids of the users to be returned.
 * @return A Promise that resolves with a Discover object.
 */
export const getUsersByIds = async (ids: string[]): Promise<Profile[]> => {
  const userQuery = query(usersCollection, where(documentId(), "in", ids));
  const userSnapshot = await getDocs(userQuery);
  const users: Profile[] = [];
  if (!userSnapshot.empty) {
    userSnapshot.forEach((doc) => {
      const user = profileConverter.fromFirestore(doc);
      users.push(user);
    });
  }
  return users;
};

const getCounterTemplates = async (counterKey: string, periodId: PeriodId, amount?: number): Promise<Template[]> => {
  const key = counterKey + "." + periodId.getKey();
  const queryConstraints: QueryConstraint[] = [
    // where(key, ">=", 0), // TODO: Set the minimum amount of likes higher
    orderBy(key, "desc"),
  ];
  if (amount !== undefined) queryConstraints.push(limit(amount));
  return getTemplatesByQuery(...queryConstraints);
};

export const getLikesTemplates = async (periodId: PeriodId, limit?: number): Promise<Template[]> => {
  return getCounterTemplates("likes", periodId, limit);
};

export const getViewsTemplates = async (periodId: PeriodId, limit?: number): Promise<Template[]> => {
  return getCounterTemplates("views", periodId, limit);
};

export const getDownloadsTemplates = async (periodId: PeriodId, limit?: number): Promise<Template[]> => {
  return getCounterTemplates("downloads", periodId, limit);
};

/**
 * Returns all the draft templates of the given user.
 * @param uid  The user's id.
 * @returns A Promise that resolves with an array of Template objects.
 */
export const getUserPublicTemplates = async (uid: string): Promise<Template[]> => {
  const queryConstraints: QueryConstraint[] = [
    where("userId", "==", uid),
    orderBy("published", "desc")
  ];
  return getTemplatesByQuery(...queryConstraints);
};

/**
 * Returns all the public templates of the given user.
 * @param uid  The user's id.
 * @returns A Promise that resolves with an array of Template objects.
 */
export const getUserDraftTemplates = async (uid: string): Promise<Template[]> => {
  const userCollectionTemplates = collection(userDocument(uid), FIREBASE_COLLECTION_TEMPLATES)
  const templatesSnapshot = await getDocs(userCollectionTemplates);
  const templates: Template[] = [];
  templatesSnapshot.docs.forEach((doc) => {
    const template = templateConverter.fromFirestore(doc);
    templates.push(template);
  });
  return templates;
};

/**
 * Returns all the orders of the given user.
 * @param uid  The user's id.
 * @returns A Promise that resolves with an array of Order objects.
 */
export const getUserOrders = async (uid: string): Promise<OrderDetails[]> => {
  const userCollectionOrders = collection(userDocument(uid), FIREBASE_COLLECTION_ORDERS)
  const ordersSnapshot = await getDocs(userCollectionOrders);
  const orders: OrderDetails[] = [];
  ordersSnapshot.docs.forEach((doc) => {
    const order = orderDetailsConverter.fromFirestore(doc);
    orders.push(order);
  });
  return orders;
};


/**
 * Returns all the customers of the given user.
 * @param uid  The user's id.
 * @returns A Promise that resolves with an array of Order objects.
 */
export const getUserCustomers = async (uid: string): Promise<Customer[]> => {
  const userCollectionCustomers = collection(userDocument(uid), FIREBASE_COLLECTION_CUSTOMERS)
  const ordersSnapshot = await getDocs(userCollectionCustomers);
  const customers: Customer[] = [];
  ordersSnapshot.docs.forEach((doc) => {
    const customer = customerConverter.fromFirestore(doc.data());
    customer.id = doc.id;
    customers.push(customer);
  });
  return customers;
};

/**
 * Returns all the customers of the given user.
 * @param uid  The user's id.
 * @returns A Promise that resolves with an array of Order objects.
 */
export const getUserConsumptions = async (uid: string): Promise<CustomerConsumption[]> => {
  const userCollectionConsumptions = collection(userDocument(uid), FIREBASE_COLLECTION_CUSTOMER_CONSUMPTIONS)
  const consumptionsSnapshot = await getDocs(userCollectionConsumptions);
  const consumptions: CustomerConsumption[] = [];
  consumptionsSnapshot.docs.forEach((doc) => {
    const consumption = customerConsumptionConverter.fromFirestore(doc.data());
    consumptions.push(consumption);
  });
  return consumptions;
};

/**
 * Returns the public template.
 * @param id The template's id. 
 * @returns A Promise that resolves with a Template object.
 */
export const getTemplateById = async (id: string | undefined): Promise<Template> => {
  const templateSnapshot = await getDoc(doc(templatesCollection, id));
  const template = templateConverter.fromFirestore(templateSnapshot);
  return template;
};

/**
 * Deletes the template.
 * @param id The template's id.
 * @returns A Promise that resolves with void.
 */
export const deleteTemplateById = async (id: string | undefined): Promise<void> => {
  const templateDoc = doc(templatesCollection, id)
  await deleteDoc(templateDoc);
};

/**
 * Returns the draft template.
 * @param uid The user's id.
 * @param id The template's id.
 * @returns A Promise that resolves with a Template object.
 */
export const getDraftTemplateById = async (uid: string, id: string | undefined): Promise<Template> => {
  const userCollectionTemplates = collection(userDocument(uid), FIREBASE_COLLECTION_TEMPLATES)
  const templateSnapshot = await getDoc(doc(userCollectionTemplates, id));
  const template = templateConverter.fromFirestore(templateSnapshot);
  return template;
};

/**
 * Updates the draft template.
 * @param uid The user's id.
 * @param rawTemplate The template object to be saved.
 * @returns A Promise that resolves with a Template object.
 */
export const updateDraftTemplate = async (uid: string, rawTemplate: Template): Promise<void> => {
  const template = templateConverter.toFirestore(rawTemplate);
  const userCollectionTemplates = collection(userDocument(uid), FIREBASE_COLLECTION_TEMPLATES)
  const templateRef = doc(userCollectionTemplates, rawTemplate.id);
  await setDoc(templateRef, template);
};

/**
 * Deletes the draft template.
 * @param uid The user's id.
 * @param id The template's id.
 * @returns A Promise that resolves with a Template object.
 */
export const deleteDraftTemplateById = async (uid: string, id: string): Promise<void> => {
  const userCollectionTemplates = collection(userDocument(uid), FIREBASE_COLLECTION_TEMPLATES)
  const templateRef = doc(userCollectionTemplates, id);
  await deleteDoc(templateRef);
};

/**
 * Creates a new draft template.
 * @param uid The user's id.
 */
export const createDraftTemplate = async (uid: string): Promise<string> => {
  const template = new Template(uid);
  template.locales = [localeKey(DEFAULT_LOCALE)];
  template.created = Timestamp.now();

  // TODO: Need to have the total period in order to be queried.
  const key = PeriodId.total().getKey();
  template.likes = { [key]: 0 };
  template.views = { [key]: 0 };
  template.downloads = { [key]: 0 };

  const firestoreTemplate = templateConverter.toFirestore(template);
  const userCollectionTemplates = collection(userDocument(uid), FIREBASE_COLLECTION_TEMPLATES)
  const templateRef = doc(userCollectionTemplates);
  await setDoc(templateRef, firestoreTemplate);
  return templateRef.id;
};

/** 
 * Updates the public template.
 * @param rawTemplate The template object to be saved.
 * @returns A Promise that resolves with a Template object.
*/
export const updatePublicTemplate = async (rawTemplate: Template): Promise<void> => {
  const template = templateConverter.toFirestore(rawTemplate);
  const templateRef = doc(templatesCollection, rawTemplate.id);
  await setDoc(templateRef, template);
};

// Login / Logout

/**
 * Signs in the user with Google.
 * @returns A Promise that resolves with a Result that represents whether the sign in operation was successful or not.
 */
export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('email');
  try {
    const result = await signInWithPopup(auth, provider);
    return result;
  } catch (error) {
    throw new Error(`Unable to sign in with Google: ${error}`);
  }
};

/**
 * Signs in the user with Twitter.
 * @returns A Promise that resolves with a Result that represents whether the sign in operation was successful or not.
 */
export const signInWithTwitter = async () => {
  const provider = new TwitterAuthProvider();
  provider.addScope("email");
  try {
    const result = await signInWithPopup(auth, provider);
    return result;
  } catch (error) {
    throw new Error(`Unable to sign in with Twitter: ${error}`);
  }
};

/**
 * Signs out the current user.
 */
export const signOut = async () => {
  try {
    await auth.signOut();
    trackLogout();
  } catch (error) {
    trackLogoutError();
    throw new Error(`Unable to sign in with Twitter: ${error}`);
  }
};

/**
 * Deletes the current user's Firebase account.
 */
export const deleteFirebaseAccount = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      await user.delete();
    } else {
      throw new Error('No user signed in.');
    }
  } catch (error) {
    throw new Error(`Unable to delete Firebase account: ${error}`);
  }
};