import React from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../../store";
import { Destination } from "../../../utils/constants-navigation";

type Props = {
  children: React.ReactNode;
};

const LoginComponent = ({ children }: Props) => {
  const navigate = useNavigate();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);

  function onLogin() {
    navigate(Destination.LOGIN);
  }

  return (
    <>
      {isSignedIn ? (
        <div>{children}</div>
      ) : (
        <div
          onClick={() => onLogin()}
          className="cursor-default aspect-[3/1] bg-white ring-1 p-8 rounded-md shadow ring-gray-300 hover:bg-gray-50 hover:ring-gray-300 text-center"
        >
          <img
            className="mt-3 h-8 w-8 mx-auto text-indigo-600"
            src={process.env.PUBLIC_URL + "/icons/elcovia.svg"}
            alt="Elcovia"
          />
          <h1 className="mt-6 mx-auto max-w-xl text-xl font-bold tracking-tight text-gray-900 sm:text-1xl md:text-xl">
            Login on Elcovia
          </h1>
          <p className="mt-3 mx-auto max-w-xl text-sm leading-6 text-gray-600">
            Ensure continuous access to your purchased products by logging into
            your Elcovia account, allowing you to conveniently access and enjoy
            your products at any time on the platform.
          </p>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
});

export default connect(mapStateToProps)(LoginComponent);
