import React from "react";
import {
  FAQS_ELCOVIA_DESCRIPTION,
  SCREEN_FAQS_ELCOVIA,
  TITLE_SUFFIX,
} from "../../../../utils/constants-seo";
import { Helmet } from "react-helmet-async";
import { Destination } from "../../../../utils/constants-navigation";
import {
  SupportedLocales,
  getLocaleDisplayName,
} from "../../../../utils/supported-locales";
import { supportedPaymentPlatforms } from "../../../../models/data/PaymentPlatform";
import SEOComponent, {
  SEOPage,
} from "../../../../components/common/seo/SEOComponent";
import { FAQ } from "./FAQ";
import { FAQGroupItem } from "./FAQGroupItem";

export default function FAQsElcoviaPage() {
  /**
   * Helper function to create a link
   * @param info - URL and text separated by a pipe (|)
   * @returns - HTML link
   */
  function link(info: string) {
    const [url, text] = info.split("|");
    return `<i><a href='${url}' target='_blank' class'text-indigo-500' rel='noopener noreferrer'>${text}</a></i>`;
  }

  const faqs: FAQ[] = [
    {
      question: "What is Elcovia?",
      answer: `Elcovia is a marketplace for Notion templates. We help Notion users find the best templates for their needs. We also help Notion template creators reach a wider audience.`,
    },
    {
      question:
        "I want to offer my Notion template on Elcovia. How do I start?",
      answer: `${link("/login|Login")} and go to your ${link(
        "/account| Dashboard"
      )}. Update your public profile and create your Notion template draft.`,
    },
    {
      question: "What languages does Elcovia support for Notion Templates?",
      answer: `We support Notion Templates in the following languages: <i>${Object.values(
        SupportedLocales
      )
        .map((locale) => getLocaleDisplayName(locale))
        .join(", ")}</i>.`,
    },
    {
      question: "Can I sell my Notion template on Elcovia?",
      answer: `Yes, you can sell your Notion template on Elcovia.`,
    },
    {
      question: "How much does it cost to sell my Notion template on Elcovia?",
      answer: `It's free to sell your Notion template on Elcovia. We only charge a small fee when you make a sale.`,
    },
    {
      question: "Can I sell my Notion template only on Elcovia?",
      answer: `No, you can sell your Notion template on other platforms as well. Paid templates that are only sold on other platforms will not display a price. When a user views your template and wants to purchase it, they are redirected to the specified platform. We only ask that you keep your Notion template up to date on Elcovia.`,
    },
    {
      question: "To which platforms can I link to for my paid Notion template?",
      answer: `We support the following platforms: <strong>${Object.values(
        supportedPaymentPlatforms
      )
        .map((platform) => platform.name)
        .join(", ")}</strong>.`,
    },
    {
      question: "How do I get paid for my Notion template sales?",
      answer: `We use ${link(
        "https://stripe.com|Stripe"
      )} to process payments. You can connect your Stripe account to Elcovia to receive payments.`,
    },
  ];

  // Split intwo two columns
  const faqsChunked = faqs.map((_, i) =>
    faqs.slice(i * (faqs.length / 2), (i + 1) * (faqs.length / 2))
  );

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_FAQS_ELCOVIA + TITLE_SUFFIX,
            FAQS_ELCOVIA_DESCRIPTION
          )
        }
      />
      <section className="mt-12 py-12">
        <div className="mx-auto">
          <div className="flex gap-x-16 items-center justify-center">
            <div>
              <h2 className="text-5xl font-bold tracking-tight text-gray-900 leading-snug">
                FAQs for <span className="text-indigo-600">Creators</span>
              </h2>
              <p className="mt-6 text-lg leading-7 text-gray-600">
                Have a different question?{" "}
                <a
                  href={Destination.CONTACT}
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Contact
                </a>{" "}
                us and we'll be happy to help!
              </p>
            </div>
            <embed
              src="/illustrations/notioly/community.svg"
              className="h-80"
            />
          </div>
          <FAQGroupItem faqsChunked={faqsChunked} />
        </div>
      </section>
    </>
  );
}
