import React from "react";
import SEOComponent, {
  SEOPage,
} from "../../../../components/common/seo/SEOComponent";
import { FAQGroupItem } from "../../info/faq/FAQGroupItem";
import { Category } from "../../../../models/Category";
import TemplateCategoryItem from "./TemplateCategoryItem";
import {
  SCREEN_TEMPLATE_CATEGORIES,
  TITLE_SUFFIX,
  TEMPLATE_CATEGORIES_DESCRIPTION,
} from "../../../../utils/constants-seo";

const TemplateCategoriesPage = () => {
  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TEMPLATE_CATEGORIES + TITLE_SUFFIX,
            TEMPLATE_CATEGORIES_DESCRIPTION
          )
        }
      />
      <div className="mx-auto w-full mb-32">
        <div className="flex gap-x-16 items-center justify-center text-center">
          <div className="max-w-3xl">
            <h2 className="text-5xl font-bold tracking-tight text-gray-900 leading-snug">
              Categories for{" "}
              <span className="text-indigo-600">Notion Templates</span>
            </h2>
            <p className="mt-6 text-lg leading-7 text-gray-600">
              Discover the most popular categories in the Notion community.
              Whether you're a student, entrepreneur, or developer, we have
              templates for you.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-16">
          {Object.values(Category).map((category) => (
            <TemplateCategoryItem category={category} />
          ))}
        </div>
      </div>
    </>
  );
};

export default TemplateCategoriesPage;
