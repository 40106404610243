export type CategoryType = {
    key: string;
    title: string;
    desc: string;
    image: string;
};

const getFilePath = (imageName: string): string => {
    return process.env.PUBLIC_URL + "/illustrations/categories/" + imageName + ".svg";
};

export const Category: { [key: string]: CategoryType } = {
    PERSONAL_LIFE_MANAGEMENT: {
        key: "00100",
        title: "Personal & Life Management",
        desc: "Templates for personal growth, goal setting, and daily routines.",
        image: getFilePath("illustration_category_personal_life_management")
    },
    WORK_PRODUCTIVITY: {
        key: "00200",
        title: "Work & Productivity",
        desc: "Tools to improve productivity, time management, and organization at work.",
        image: getFilePath("illustration_category_work_productivity")
    },
    EDUCATION_LEARNING: {
        key: "00300",
        title: "Education & Learning",
        desc: "Resources for students, teachers, and lifelong learners.",
        image: getFilePath("illustration_category_education_learning")
    },
    HEALTH_WELLNESS: {
        key: "00400",
        title: "Health & Wellness",
        desc: "Templates to track health goals, fitness routines, and mental well-being.",
        image: getFilePath("illustration_category_health_wellness")
    },
    HOME_LIVING: {
        key: "00500",
        title: "Home & Living",
        desc: "Organizers for home maintenance, family schedules, and household budgets.",
        image: getFilePath("illustration_category_home_living")
    },
    DESIGN_CREATIVITY: {
        key: "00600",
        title: "Design & Creativity",
        desc: "Tools for brainstorming, project planning, and design workflows.",
        image: getFilePath("illustration_category_design_creativity")
    },
    SOFTWARE_DEVELOPMENT_ENGINEERING: {
        key: "00700",
        title: "Software Development & Engineering",
        desc: "Templates for coding projects, team collaboration, and software development processes.",
        image: getFilePath("illustration_category_software_development_engineering")
    },
    FREELANCE_REMOTE_WORK: {
        key: "00800",
        title: "Freelance & Remote Work",
        desc: "Resources for freelancers and remote workers to manage projects and clients.",
        image: getFilePath("illustration_category_freelance_remote_work")
    },
    HUMAN_RESOURCES_PEOPLE_MANAGEMENT: {
        key: "00900",
        title: "Human Resources & People Management",
        desc: "Tools for hiring, onboarding, and employee performance tracking.",
        image: getFilePath("illustration_category_human_resources_people_management")
    },
    MARKETING_ADVERTISING: {
        key: "01000",
        title: "Marketing & Advertising",
        desc: "Templates for marketing plans, social media calendars, and ad campaigns.",
        image: getFilePath("illustration_category_marketing_advertising")
    },
    SALES_CUSTOMER_MANAGEMENT: {
        key: "01100",
        title: "Sales & Customer Management",
        desc: "Tools for managing leads, customer relationships, and sales pipelines.",
        image: getFilePath("illustration_category_sales_customer_management")
    },
    NON_PROFIT_PHILANTHROPY: {
        key: "01200",
        title: "Non-Profit & Philanthropy",
        desc: "Resources for planning, fundraising, and managing non-profit organizations.",
        image: getFilePath("illustration_category_non_profit_philantrophy")
    },
    REAL_ESTATE_PROPERTY_MANAGEMENT: {
        key: "01300",
        title: "Real Estate & Property Management",
        desc: "Templates for property listings, lease agreements, and property management.",
        image: getFilePath("illustration_category_real_estate_proproperty_management")
    },
    STARTUPS_ENTREPRENEURSHIP: {
        key: "01400",
        title: "Startups & Entrepreneurship",
        desc: "Tools for business planning, project management, and startup growth.",
        image: getFilePath("illustration_category_startups_entrepreneurship")
    },
    PUBLIC_RELATIONS_COMMUNICATIONS: {
        key: "01500",
        title: "Public Relations & Communications",
        desc: "Templates for press releases, media outreach, and communication plans.",
        image: getFilePath("illustration_category_public_relations_communications")
    },
    CONTENT_PRODUCTION_MEDIA: {
        key: "01600",
        title: "Content Production & Media",
        desc: "Resources for content planning, editorial calendars, and multimedia production.",
        image: getFilePath("illustration_category_content_production_media")
    },
    LEGAL_COMPLIANCE: {
        key: "01700",
        title: "Legal & Compliance",
        desc: "Templates for contracts, legal documents, and compliance tracking.",
        image: getFilePath("illustration_category_legal_compliance")
    },
    FINANCE_ACCOUNTING: {
        key: "01800",
        title: "Finance & Accounting",
        desc: "Tools for budgeting, financial planning, and expense tracking.",
        image: getFilePath("illustration_category_finance_accounting")
    },
    TRAVEL_LEISURE: {
        key: "01900",
        title: "Travel & Leisure",
        desc: "Templates for trip planning, itinerary organization, and travel budgets.",
        image: getFilePath("illustration_category_travel_leisure")
    },
    EVENTS_CONFERENCES: {
        key: "02000",
        title: "Events & Conferences",
        desc: "Tools for event planning, attendee management, and scheduling.",
        image: getFilePath("illustration_category_events_conferences")
    },
    SCIENCE_RESEARCH: {
        key: "02100",
        title: "Science & Research",
        desc: "Templates for research projects, data organization, and scientific collaboration.",
        image: getFilePath("illustration_category_science_research")
    },
    ENVIRONMENT_SUSTAINABILITY: {
        key: "02200",
        title: "Environment & Sustainability",
        desc: "Resources for tracking environmental goals, sustainable practices, and green initiatives.",
        image: getFilePath("illustration_category_environment_sustainability")
    },
    ARTIFICIAL_INTELLIGENCE: {
        key: "02300",
        title: "Artificial Intelligence",
        desc: "Templates for AI concepts, project management, and machine learning workflows.",
        image: getFilePath("illustration_category_ai")
    }
};