import React from "react";
import { connect, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { RootState } from "../store";
import { Destination } from "../utils/constants-navigation";
import AdminPage from "../pages/admin/AdminPage";
import TemplatesMetaDataPage from "../pages/admin/meta-data/TemplatesMetaDataPage";
import DataExportPage from "../pages/admin/data-export/DataExportPage";

const AdminApp = () => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const [title, setTitle] = React.useState<string>("");
  const [titleView, setTitleView] = React.useState<React.ReactNode>(<></>);
  const location = useLocation();

  /**
   * Handle title update event.
   * @param newTitle The new title.
   */
  const handleTitleUpdate = (newTitle: string) => {
    setTitle(newTitle);
  };

  /**
   * Map of titles to be displayed on the dashboard.
   */
  const titleMap: { [key: string]: string } = {
    dashboard: "Dashboard",
    "meta-data": "Meta Data",
    "data-export": "Data Export",
  };

  React.useEffect(() => {
    // Get the current pathname from thπe location object
    const pathnames = location.pathname.split("/");
    const pathname = pathnames[pathnames.length - 1];

    // Set the title based on the current pathname or other conditions
    if (pathname in titleMap) {
      setTitle(titleMap[pathname]);
    } else if (location.pathname.includes("template-promotion")) {
      setTitle("Template Promotion");
    } else {
      // No title or dynamic title
    }
  }, [location]);

  return (
    <>
      {isSignedIn ? (
        <AdminPage title={title} titleView={titleView}>
          <Routes>
            <Route
              path={Destination.REL_ADMIN_DASHBOARD}
              element={<TemplatesMetaDataPage />}
            />
            <Route
              path={Destination.REL_ADMIN_DASHBOARD_START}
              element={<TemplatesMetaDataPage />}
            />
            <Route
              path={Destination.REL_ADMIN_META_DATA}
              element={<TemplatesMetaDataPage />}
            />
            <Route
              path={Destination.REL_ADMIN_DATA_EXPORT}
              element={<DataExportPage />}
            />
          </Routes>
        </AdminPage>
      ) : (
        <Navigate to={Destination.LOGIN} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(AdminApp);
