import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import LargeElcoviaButtonComponent from "../../../../components/common/buttons/LargeElcoviaButtonComponent";
import PlatformRedirectButtonComponent from "../../../../components/common/buttons/PlatformRedirectButtonComponent";
import PriceTextFieldComponent from "../../../../components/common/input/PriceTextFieldComponent";
import LoginComponent from "../../../../components/common/sections/LoginComponent";
import { Template } from "../../../../models/Template";
import { createStripeMarketplaceTemplateCheckoutSession } from "../../../../services/functions-service";
import { handleStripeResponse } from "../../../../services/stripe-service";
import { Destination, setPathId } from "../../../../utils/constants-navigation";
import { localizedValueWithKey } from "../../../../utils/supported-locales";
import { getFormattedPrice } from "../../../../utils/utils-formatting";
import { Logger } from "../../../../utils/utils-logging";

const logger = new Logger("TemplatePurchaseComponent");

interface TemplatePurchaseSectionProps {
  template: Template;
  locale: string;
  inGrid: boolean;
}

const TemplatePurchaseSection: React.FC<TemplatePurchaseSectionProps> = ({
  template,
  locale,
  inGrid,
}) => {
  const navigate = useNavigate();

  const [customPrice, setCustomPrice] = React.useState<number | null>(null);
  const [customPriceError, setCustomPriceError] =
    React.useState<boolean>(false);
  const priceRef = React.useRef<HTMLInputElement>(null);

  const [onBlockPayButton, setOnBlockPayButton] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setCustomPrice(template.priceSuggested || template.price);
  }, [template]);

  React.useEffect(() => {
    if (template && template.price) {
      const customPriceValid = customPrice
        ? customPrice >= template.price
        : true;
      const correctedCustomPrice = customPriceValid
        ? customPrice
        : template.price;
      logger.log("Validate custom price", {
        customPrice,
        customPriceValid,
        correctedCustomPrice,
      });
      setCustomPriceError(!customPriceValid);
    }
  }, [template, customPrice]);

  /**
   * Purchase template.
   * @param template Template to promote.
   */
  async function purchaseTemplate(template: Template) {
    try {
      setOnBlockPayButton(true);
      const templateId = template.id;
      purchaseTemplateStripe(templateId, customPrice);
    } catch (error) {
      console.log(error);
      setOnBlockPayButton(false);
    }
  }

  /**
   * Promote template with Stripe.
   * @param templateId Template ID.
   * @param purchaseKey Purchase key.
   */
  async function purchaseTemplateStripe(
    templateId: string,
    customPrice: number | null
  ) {
    const response = await createStripeMarketplaceTemplateCheckoutSession(
      templateId,
      locale,
      customPrice
    );
    const session = response.data.session;
    const stripeAccountId = response.data.stripeAccountId;
    await handleStripeResponse(session, stripeAccountId);
  }

  /**
   * Open the paid template in a new tab.
   * @param url The URL of the paid template.
   */
  function openPaidTemplate(url: string) {
    window.open(url, "_blank");
  }

  /**
   * Open the free template in a new tab.
   * @param url The URL of the free template.
   */
  function openFreeTemplate(url: string) {
    navigate(
      setPathId(
        Destination.PURCHASE_MARKETPLACE_TEMPLATE_REFERENCE_ID,
        template!!.id
      )
    );
  }

  return (
    <>
      {template.isPaid ? (
        <>
          <h3 className="mb-3 text-md font-semibold leading-6 text-gray-900">
            Purchase
          </h3>
          <section
            className={
              inGrid
                ? "grid gap-x-4 gap-y-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-end"
                : ""
            }
          >
            {!true && (
              <>
                {template?.price && (
                  <div>
                    <LoginComponent>
                      <div>
                        <div className="space-y-3">
                          {template.priceSuggested && (
                            <PriceTextFieldComponent
                              name="price"
                              placeholder={
                                getFormattedPrice(template.price) + "+" || ""
                              }
                              error={customPriceError}
                              initialValue={
                                getFormattedPrice(customPrice) ||
                                getFormattedPrice(template.priceSuggested) ||
                                getFormattedPrice(template.price) ||
                                ""
                              }
                              onChange={(price) => {
                                setCustomPrice(price);
                              }}
                              ref={priceRef}
                            />
                          )}

                          <LargeElcoviaButtonComponent
                            loading={onBlockPayButton}
                            disabled={customPriceError || onBlockPayButton}
                            prefix={
                              getFormattedPrice(customPrice) ||
                              getFormattedPrice(template.priceSuggested) ||
                              getFormattedPrice(template.price) ||
                              ""
                            }
                            text="Purchase"
                            target="_blank"
                            onClick={() => purchaseTemplate(template)}
                            rel="noopener noreferrer"
                          />
                        </div>
                      </div>
                    </LoginComponent>
                    <div className="mb-3" />
                  </div>
                )}
              </>
            )}

            {localizedValueWithKey(template.paidUrls, locale)?.map(
              (paidUrl) => (
                <PlatformRedirectButtonComponent
                  href={paidUrl}
                  target="_blank"
                  onClick={() => openPaidTemplate(paidUrl)}
                  rel="noopener noreferrer"
                />
              )
            )}
          </section>
        </>
      ) : (
        <section>
          <h3 className="mb-3 text-md font-semibold leading-6 text-gray-900">
            Download
          </h3>
          <div
            className={
              inGrid
                ? "grid gap-x-4 gap-y-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3"
                : ""
            }
          >
            <LargeElcoviaButtonComponent
              prefix="Free"
              text="Download"
              target="_blank"
              onClick={() =>
                openFreeTemplate(
                  localizedValueWithKey(template.templateUrl, locale)!!
                )
              }
              rel="noopener noreferrer"
            />
          </div>
        </section>
      )}
    </>
  );
};

export default TemplatePurchaseSection;
