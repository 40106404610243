import React, { ReactNode } from "react";
import { HelmetProvider } from "react-helmet-async";
import { connect, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { RootState } from "../store";
import { Destination } from "../utils/constants-navigation";
import AccountApp from "./AccountApp";
import PublicApp from "./PublicApp";
import Purchase from "./Purchase";
import AdminApp from "./AdminApp";
import { FIREBASE_ADMIN_UID } from "../utils/constants";

const App = () => {
  const profile = useSelector((state: RootState) => state.user.profile);
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsAdmin(profile?.id == FIREBASE_ADMIN_UID);
  }, [profile]);

  const AppContainer = ({ children }: { children: ReactNode }) => {
    return <div className="bg-white">{children}</div>;
  };

  return (
    <HelmetProvider>
      <Router>
        <AppContainer>
          <Routes>
            <Route path={Destination.APP_PUBLIC} element={<PublicApp />} />
            <Route path={Destination.APP_ACCOUNT} element={<AccountApp />} />
            <Route path={Destination.APP_ADMIN} element={<AdminApp />} />
            <Route path={Destination.PURCHASE} element={<Purchase />} />
          </Routes>
        </AppContainer>
      </Router>
    </HelmetProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(App);
