import {
  AcademicCapIcon,
  CheckBadgeIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";

import { Profile } from "../../models/Profile";
import {
  ARGS_PARAM_DISCOVER,
  Destination,
  setPathId,
  setPathIdAndAppend,
} from "../../utils/constants-navigation";
import { getProfileId } from "../../utils/utils-profile";

interface TemplateProps {
  itemKey?: string;
  discover?: boolean;
  profile: Profile;
}

const ProfileItemComponent: React.FC<TemplateProps> = ({
  itemKey,
  discover = false,
  profile,
}) => {
  return (
    <Link
      to={setPathIdAndAppend(
        Destination.USER_ID_SHORT,
        getProfileId(profile),
        discover ? ARGS_PARAM_DISCOVER : undefined
      )}
      key={itemKey + "-" + profile.id}
    >
      <div className="transition-all ease-in-out duration-300 group relative bg-white divide-y divide-solid divide-gray-200/50 shadow-sm rounded-lg border border-1 border-gray-200/50 hover:shadow-md hover:md:scale-[1.04]">
        <div className="bg-indigo-500/[0.025] w-full flex justify-center">
          <img
            src={profile.image}
            alt={profile.name || ""}
            className="h-20 w-20 mt-4 mb-4 rounded-2xl shadow-md"
          />
        </div>
        <div>
          <div className="pt-4 pb-4 ml-4 mr-4">
            <div className="w-full flex flex-inline items-center">
              <p className="text-base font-semibold text-gray-900 line-clamp-1">
                {profile.name}{" "}
              </p>
              <div className="ml-auto flex ml-2 gap-x-2">
                {profile.verified == true && (
                  <CheckBadgeIcon className="text-indigo-600 h-4 w-4" />
                )}
                {profile.notionCertified == true && (
                  <AcademicCapIcon className="text-gray-600 h-4 w-4" />
                )}
                {profile.promotion != null && (
                  <SparklesIcon className="text-gray-600 h-4 w-4" />
                )}
              </div>
            </div>
            <p className="mt-1 text-xs text-gray-500 leading-5 line-clamp-3">
              {profile.bio}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProfileItemComponent;
