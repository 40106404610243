import "swiper/css";

import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { connect } from "react-redux";

import HeaderH1Item from "../../../../components/common/page/HeaderH1Item";
import SEOComponent, {
  SEOPage,
} from "../../../../components/common/seo/SEOComponent";
import {
  FeatureSection,
  FeatureSectionType,
} from "../../../../components/promotion/FeatureSection";
import { Template } from "../../../../models/Template";
import { getLatestTemplates } from "../../../../services/firestore-service";
import { RootState } from "../../../../store";
import {
  TEMPLATES_LATEST_DESCRIPTION,
  TITLE_SUFFIX,
} from "../../../../utils/constants-seo";
import { Logger } from "../../../../utils/utils-logging";
import { AlternatingTemplatesComponent } from "./AlternatingTemplatesComponent";
import { SCREEN_TEMPLATES_LATEST } from "../../../../utils/constants-seo";

const TemplatesLatestPage = () => {
  const logger = new Logger("TemplatesLatestScreen");
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const loadTemplates = async () => {
      setLoading(true);
      try {
        const latestTemplates = await getLatestTemplates(50);
        logger.log("loadTemplates: Latest templates", latestTemplates);
        const filteredTemplates = latestTemplates.filter(
          (template) =>
            template.metaData?.desc !== undefined &&
            Object.keys(template.metaData?.desc).length > 0
        );
        setTemplates(filteredTemplates);
      } catch (error) {
        setTemplates([]);
        logger.logError("loadTemplates: Error loading templates", error);
      }
      setLoading(false);
    };

    loadTemplates();
  }, []);

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TEMPLATES_LATEST + TITLE_SUFFIX,
            TEMPLATES_LATEST_DESCRIPTION
          )
        }
      />
      <div>
        <div>
          <main>
            <HeaderH1Item
              title="New Notion Templates"
              desc="Explore our latest collection of Notion templates, showcased for your convenience. Easily spot the perfect template tailored to your specific needs and preferences."
            />
            <section aria-labelledby="header-title" className="pb-24 pt-6">
              <Transition
                show={templates.length > 0}
                enter="transition ease-out duration-300 transform"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-200 transform"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="mt-6">
                  <AlternatingTemplatesComponent
                    isDiscover={true}
                    templates={templates}
                  />
                </div>
              </Transition>
            </section>
          </main>
        </div>
      </div>
      <FeatureSection type={FeatureSectionType.USERS} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
  userLikes: state.user.likes,
});

export default connect(mapStateToProps)(TemplatesLatestPage);
