import React from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Autoplay, EffectCoverflow, EffectCreative, EffectFlip } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Template } from "../../models/Template";
import { RootState } from "../../store";
import {
  ARGS_PARAM_DISCOVER,
  Destination,
  setPathId,
  setPathIdAndAppend,
} from "../../utils/constants-navigation";
import { localizedValue } from "../../utils/supported-locales";
import TemplateStatisticItemComponent from "./TemplateStatisticsItem";
import TemplatesStatusltem from "./TemplatesStatusltem";
import HtmlShortTextComponent from "../common/html/preview_short/HtmlShortTextComponent";
import { classNames } from "../../utils/utils-react";

interface TemplateProps {
  discover?: boolean;
  itemKey?: string;
  template: Template;
  detailedBar: boolean;
}

const minDelay = 25800; // Minimum delay in milliseconds
const maxDelay = 85000; // Maximum delay in milliseconds

const minStartDelay = 15800; // Minimum delay in milliseconds
const maxStartDelay = 35000; // Maximum delay in milliseconds
const startDelay = Math.floor(
  Math.random() * (maxStartDelay - minStartDelay + 1)
);

const TemplateItemComponent: React.FC<TemplateProps> = ({
  discover = false,
  itemKey,
  template,
  detailedBar,
}) => {
  const delay =
    Math.floor(Math.random() * (maxDelay - minDelay + 1)) + startDelay;

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const sessionProfileLikes = useSelector(
    (state: RootState) => state.user.likes
  );

  const [likeable, setLikeable] = React.useState<boolean>(false);
  const [liked, setLiked] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!isSignedIn) {
      setLikeable(false);
      setLiked(false);
      return;
    }
    setLikeable(true);
    setLiked(sessionProfileLikes?.includes(template.id) || false);
  }, [isSignedIn, sessionProfile, sessionProfileLikes, template.id]);

  return (
    <Link
      to={setPathIdAndAppend(
        Destination.TEMPLATE_ID_SHORT,
        template.id,
        discover ? ARGS_PARAM_DISCOVER : undefined
      )}
      key={itemKey + "-" + template.id}
    >
      <div className="transition-all ease-in-out duration-300 group relative bg-white divide-y divide-solid divide-gray-200/50 shadow-sm rounded-lg border border-1 border-gray-200/50 hover:shadow-md hover:md:scale-[1.04]">
        <div className="overflow-hidden bg-indigo-500/[0.025] w-full aspect-[16/9]">
          {(localizedValue(template.coverImages)?.length || 0) > 1 ? (
            <Swiper
              pagination={{ clickable: true }}
              modules={[EffectCreative, Autoplay]}
              navigation={true}
              loop={true}
              className="coverImagesSwiper"
              speed={1000}
              autoplay={{
                pauseOnMouseEnter: true,
                delay: delay,
              }}
            >
              {localizedValue(template.coverImages)?.map((image: string) => (
                <SwiperSlide key={image}>
                  <img
                    src={image}
                    alt={localizedValue(template.title)!!}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-md group-hover:scale-[1.05] transition-all ease-in-out duration-300"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <img
              src={localizedValue(template.coverImages)?.[0] || ""}
              alt={localizedValue(template.title)!!}
              className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-lg group-hover:scale-[1.05] transition-all ease-in-out duration-300"
            />
          )}
        </div>
        <div>
          <div className="p-2.5">
            <div className="flex items-center">
              <p className="text-base font-semibold text-gray-900 line-clamp-1">
                {localizedValue(template.title)}
              </p>
              {!detailedBar && (
                <a className="ml-auto">
                  <TemplatesStatusltem template={template} />
                </a>
              )}
            </div>
            <p className="mt-1 text-xs text-gray-500 leading-5 line-clamp-2">
              <HtmlShortTextComponent html={localizedValue(template.desc)!!} />
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
  userLikes: state.user.likes,
});

export default connect(mapStateToProps)(TemplateItemComponent);
