import { CategoryType } from "../../models/Category";

interface Props {
  content: CategoryType;
  onClick?: () => void;
}

export const CategoryLabel: React.FC<Props> = ({ content, onClick }) => {
  return (
    <span
      onClick={() => onClick && onClick()}
      className="cursor-pointer inline-flex items-center px-3 py-2.5 gap-x-3 rounded-md bg-gray-50 text-sm font-medium text-gray-600"
    >
      <h5>{content.title}</h5>
    </span>
  );
};
