import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Template } from "../../models/Template";
import { Destination, setPathId } from "../../utils/constants-navigation";
import { localizedValue } from "../../utils/supported-locales";

interface FeatureTemplateItemProps {
  itemKey?: string;
  template: Template;
}

export const FeatureTemplateItemComponent: React.FC<
  FeatureTemplateItemProps
> = ({ itemKey, template }) => {
  return (
    <Link
      to={setPathId(Destination.TEMPLATE_ID_SHORT, template.id)}
      key={itemKey + "-" + template.id}
    >
      <div className="transition-all ease-in-out duration-300 group relative bg-white divide-y divide-solid rounded-lg drop-shadow hover:drop-shadow-lg hover:scale-105">
        <div className="bg-indigo-500/[0.025] w-full aspect-[16/9]">
          {(localizedValue(template.coverImages)?.length || 0) > 1 ? (
            <Swiper
              effect={"coverflow"}
              pagination={{ clickable: true }}
              modules={[EffectCoverflow, Autoplay]}
              navigation={true}
              loop={true}
              className="coverImagesSwiper"
              autoplay={{ delay: 8000 }}
            >
              {localizedValue(template.coverImages)?.map((image: string) => (
                <SwiperSlide key={image}>
                  <img
                    src={image}
                    alt={localizedValue(template.title)!!}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-lg"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <img
              src={localizedValue(template.coverImages)?.[0] || ""}
              alt={localizedValue(template.title)!!}
              className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-lg"
            />
          )}
        </div>
        <div>
          <div className="pt-4 pb-4 ml-4 mr-4">
            <p className="text-base font-semibold text-gray-900 line-clamp-1 md:line-clamp-none">
              {localizedValue(template.title)}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
