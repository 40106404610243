import exp from "constants";

export const TAB_TITLE_SUFFIX = " ‣ Elcovia";
export const SCREEN_TITLE = " Elcovia";
export const TITLE_SUFFIX = " ‣ Elcovia - Notion Marketplace";

export const SCREEN_TEMPLATE_SUFFIX = " ‣ Notion Template";
export const SCREEN_PROFILE_SUFFIX = " ‣ Notion Creator";
export const SCREEN_LOGIN_SUFFIX = " ‣ Login";
export const SCREEN_ACCOUNT_DASHBOARD = " ‣ Dashboard";
export const SCREEN_ACCOUNT_PROFILE = " ‣ Edit Profile";
export const SCREEN_ACCOUNT_TEMPLATES = " ‣ Templates";
export const SCREEN_ACCOUNT_TEMPLATES_ID = " ‣ Edit Template";
export const SCREEN_ACCOUNT_TEMPLATE_ID_SUFFIX = "Edit ";
export const SCREEN_ACCOUNT_TEMPLATE_PROMOTION = " ‣ Template Promotion";
export const SCREEN_ACCOUNT_PROFILE_PROMOTION = " ‣ Profile Promotion";
export const SCREEN_ACCOUNT_SETTINGS = " ‣ Settings";
export const SCREEN_FAQS_ELCOVIA = "FAQs - Elcovia";
export const SCREEN_FAQS_NOTION = "FAQs - Notion";
export const SCREEN_BETA = "Elcovia Beta";
export const SCREEN_BETA_TESTERS = "Elcovia's Beta Testers";
export const SCREEN_APPS = "Apps";
export const SCREEN_ABOUT = "About";
export const SCREEN_CONTACT = "Contact";
export const SCREEN_AFFILIATE_GUMROAD_GUIDE = "Affiliate Gumroad Guide";

// Main pages

export const SCREEN_DISCOVER = "Elcovia - Notion Templates Marketplace";
export const SCREEN_TEMPLATES = "Search Notion Templates";
export const SCREEN_TEMPLATES_LATEST = "New Notion Templates";
export const SCREEN_CREATORS = "Search Notion Creators";
export const SCREEN_LOGIN = "Login to Elcovia";
export const SCREEN_TEMPLATE_CATEGORIES = "Categories for Notion Templates";

export const DISCOVER_OG_DESCRIPTION = "Explore Elcovia, your go-to destination for a unique mix of free and premium Notion templates. Find the perfect template for any project, or share your own designs with our vibrant, growing community!";
export const DISCOVER_DESCRIPTION = DISCOVER_OG_DESCRIPTION;
export const DEFAULT_DESCRIPTION = DISCOVER_DESCRIPTION;

export const CREATORS_OG_DESCRIPTION = "Discover leading Notion creators at Elcovia. Browse through detailed profiles, check out their latest templates, and find inspiration to elevate your workflow. Visit Elcovia’s Creators page for all your Notion needs!";
export const CREATORS_DESCRIPTION = CREATORS_OG_DESCRIPTION;

export const TEMPLATES_OG_DESCRIPTION = "Discover Elcovia's vast selection of Notion templates to boost productivity and organization. Find the perfect tool for task management, scheduling, and more, to streamline your daily routine and enhance efficiency.";
export const TEMPLATES_DESCRIPTION = TEMPLATES_OG_DESCRIPTION;

export const TEMPLATES_LATEST_OG_DESCRIPTION = "Explore our latest collection of Notion templates, showcased for your convenience. Easily spot the perfect template tailored to your specific needs and preferences.";
export const TEMPLATES_LATEST_DESCRIPTION = TEMPLATES_LATEST_OG_DESCRIPTION;

export const LOGIN_OG_DESCRIPTION = "Join the conversation, interact with Elcovia creators and content and become a creator yourself."
export const LOGIN_DESCRIPTION = LOGIN_OG_DESCRIPTION;


// Account pages

export const ACCOUNT_DASHBOARD_OG_DESCRIPTION = "Access your personalized Elcovia dashboard to manage your Notion templates, track your statistics, and promote your profile for greater engagement. Begin your creative journey with Elcovia by crafting and sharing your very own Notion template today.";
export const ACCOUNT_DASHBOARD_DESCRIPTION = ACCOUNT_DASHBOARD_OG_DESCRIPTION;

export const ACCOUNT_PROFILE_OG_DESCRIPTION = "Customize your Elcovia profile to showcase your unique Notion templates and connect with the community. Add a personal touch with a profile picture, display name, and bio that reflects your creativity and expertise. Share your work and grow your presence with a comprehensive Elcovia profile.";
export const ACCOUNT_PROFILE_DESCRIPTION = ACCOUNT_PROFILE_OG_DESCRIPTION;

export const ACCOUNT_TEMPLATES_OG_DESCRIPTION = "Elcovia's Templates page is your canvas for creativity and efficiency. Browse, manage, and publish your Notion templates with ease, and share your innovative solutions with a community eager to enhance their productivity and organization.";
export const ACCOUNT_TEMPLATES_DESCRIPTION = ACCOUNT_TEMPLATES_OG_DESCRIPTION;

export const ACCOUNT_AUDIENCE_OG_DESCRIPTION = "Navigate Elcovia's Audience page to seamlessly access a comprehensive list of your customers. Effortlessly monitor sales, scrutinize customer information, and derive valuable insights to enhance your offerings and customer experience.";
export const ACCOUNT_AUDIENCE_DESCRIPTION = ACCOUNT_AUDIENCE_OG_DESCRIPTION;

export const ACCOUNT_LIKED_OG_DESCRIPTION = "Elcovia's Liked page is your hub for all your favorite Notion templates. Browse through your liked templates.";
export const ACCOUNT_LIKED_DESCRIPTION = ACCOUNT_LIKED_OG_DESCRIPTION;

export const ACCOUNT_PROFILE_PROMOTION_OG_DESCRIPTION = "Promote your profile on Elcovia.";
export const ACCOUNT_PROFILE_PROMOTION_DESCRIPTION = ACCOUNT_PROFILE_PROMOTION_OG_DESCRIPTION;

export const ACCOUNT_TEMPLATE_PROMOTION_OG_DESCRIPTION = "Promote your template on Elcovia.";
export const ACCOUNT_TEMPLATE_PROMOTION_DESCRIPTION = ACCOUNT_TEMPLATE_PROMOTION_OG_DESCRIPTION;

export const AFFILIATE_GUMROAD_GUIDE_OG_DESCRIPTION = "Master the art of earning with Elcovia’s comprehensive guide to becoming a successful Gumroad affiliate. Unlock the secrets to affiliate marketing and start generating income today!";
export const AFFILIATE_GUMROAD_GUIDE_DESCRIPTION = AFFILIATE_GUMROAD_GUIDE_OG_DESCRIPTION;

// Other pages

export const FAQS_ELCOVIA_DESCRIPTION = "Exlore out details FAQ page to find answers to common questions about Elcovia. Learn how to get started, customize your profile, and improve your presence on Elcovia. Discover tips for template creation, promotion, and audience engagement. Find out how to use Elcovia for template sales, customer support, and more. Stay informed latest updates to maximize your use of Elcovia.";
export const FAQS_NOTION_DESCRIPTION = "Explore our detailed FAQ page to find answers to common questions about Notion. Learn how to get started, customize your workspace, and improve productivity with Notion's features. Discover tips for project management, team collaboration, and personal organization. Find out how to use Notion for goal setting, budgeting, habit tracking, and more. Stay informed about integrations, security, and the latest updates to maximize your use of Notion.";

export const TEMPLATE_CATEGORIES_DESCRIPTION = "Browse through Elcovia's diverse categories of Notion templates to find the perfect tool for your project. Discover templates for task management, project planning, personal organization, and more, tailored to your specific needs and preferences.";

export const BETA_OG_DESCRIPTION = "Elcovia's beta program.";
export const BETA_DESCRIPTION = BETA_OG_DESCRIPTION;

export const BETA_TESTERS_OG_DESCRIPTION = "Elcovia's beta testers.";
export const BETA_TESTERS_DESCRIPTION = BETA_TESTERS_OG_DESCRIPTION;

export const APPS_OG_DESCRIPTION = "Elcovia's mobile apps for Android and iOS. Download now and discover your Notion templates on the go.";
export const APPS_DESCRIPTION = APPS_OG_DESCRIPTION;

export const ABOUT_OG_DESCRIPTION = "About Elcovia and our mission.";
export const ABOUT_DESCRIPTION = ABOUT_OG_DESCRIPTION;

export const CONTACT_OG_DESCRIPTION = "Contact Elcovia for any questions, feedback or suggestions.";
export const CONTACT_DESCRIPTION = CONTACT_OG_DESCRIPTION;


export const NOTION_TEMPLATES_KEYWORDS = "Notion templates, Notion template, Productivity, Note-taking, Task, management, Project, planning templates, Notion, Personal, organization templates, Creative Notion templates, Notion page designs, Notion content creators, Productivity experts, Notion enthusiasts, Content producers, Notion influencers, Creative minds, Notion gurus, Content innovators, Expert creators, Notion community leaders, Notion tools, Notion guides, Notion tutorials, Notion add-ons, Notion hacks, Notion, resources, Notion, tips, Notion, tricks, Notion, apps, Notion, integrations"
export const NOTION_PROFILES_KEYWORDS = "Notion creators, Notion content creators, Notion influencers, Notion gurus, Content innovators, Expert creators, Notion community leaders, Notion enthusiasts, Content producers, Creative minds, Notion experts, Notion, resources, Notion, tips, Notion, tricks, Notion, apps, Notion, integrations, Notion, templates, Notion, template, Productivity, Note-taking, Task, management, Project, planning templates, Notion, Personal, organization templates, Creative Notion templates, Notion page designs"
export const NOTION_GENERAL_KEYWORDS = "Notion, resources, Notion, tips, Notion, tricks, Notion, apps, Notion, integrations, Notion, templates, Notion, template, Productivity, Note-taking, Task, management, Project, planning templates, Notion, Personal, organization templates, Creative Notion templates, Notion page designs, Notion content creators, Notion influencers, Notion gurus, Content innovators, Expert creators, Notion community leaders, Notion enthusiasts, Content producers, Creative minds, Notion experts, Notion guides, Notion tutorials, Notion add-ons, Notion hacks"