type HeaderItemProps = {
  title: React.ReactNode;
  desc?: string;
};

const HeaderH1LargeItem: React.FC<HeaderItemProps> = ({ title, desc }) => {
  return (
    <div className="max-w-8xl">
      <h1
        id="header-title"
        className="text-5xl lg:text-7xl leading-tight font-bold tracking-tight text-gray-900"
      >
        {title}
      </h1>
      {desc && (
        <p className="mt-6 font-normal text-lg text-gray-600 leading-7">
          {desc}
        </p>
      )}
    </div>
  );
};

export default HeaderH1LargeItem;
