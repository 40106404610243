import { PlusCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import SEOComponent, {
  SEOPage,
} from "../../../components/common/seo/SEOComponent";
import EmptyButtonStateComponent from "../../../components/common/state/EmptyButtonState";
import DashboardTemplateItem from "../../../components/template/account/DashboardTemplateItem";
import { Template } from "../../../models/Template";
import {
  createDraftTemplate,
  getUserDraftTemplates,
  getUserPublicTemplates,
} from "../../../services/firestore-service";
import {
  trackCreateTemplate,
  trackCreateTemplateError,
} from "../../../services/tracking-service";
import { RootState } from "../../../store";
import { Destination, setPathId } from "../../../utils/constants-navigation";
import {
  ACCOUNT_TEMPLATES_DESCRIPTION,
  SCREEN_ACCOUNT_TEMPLATES,
  SCREEN_TITLE,
} from "../../../utils/constants-seo";
import {
  DashboardH1,
  DashboardH2,
  DashboardH3,
} from "../../../components/common/common/DashboardHeaderComponents";

interface TemplatesPageProps {
  onUpdateTitle: (title: string) => void;
}
const TemplatesPage: React.FC<TemplatesPageProps> = ({ onUpdateTitle }) => {
  const navigate = useNavigate();
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [draftTemplates, setDraftTemplates] = React.useState<Template[]>([]);
  const [publicTemplates, setPublicTemplates] = React.useState<Template[]>([]);

  /**
   * Handle create template event.
   */
  async function createTemplate() {
    try {
      const id = await createDraftTemplate(sessionProfile!!.id);
      navigate(setPathId(Destination.ACCOUNT_TEMPLATES_ID, id));
      trackCreateTemplate();
    } catch (error) {
      trackCreateTemplateError();
      console.error(error);
    }
  }

  React.useEffect(() => {
    const fetchTemplates = async () => {
      const publicTemplates = await getUserPublicTemplates(sessionProfile!!.id);
      const sortedPublicTemplates = publicTemplates.sort(
        (a, b) => b.published!.seconds - a.published!.seconds
      );
      setPublicTemplates(sortedPublicTemplates);
      const draftTemplates = await getUserDraftTemplates(sessionProfile!!.id);
      const sortedDraftTemplates = draftTemplates.sort(
        (a, b) => b.created!!.seconds - a.created!!.seconds
      );
      setDraftTemplates(sortedDraftTemplates);
    };
    fetchTemplates();
  }, []);

  const gridClassName =
    "grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-7";

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TITLE + SCREEN_ACCOUNT_TEMPLATES,
            ACCOUNT_TEMPLATES_DESCRIPTION
          )
        }
      />
      <DashboardH1
        title="Templates"
        subtitle="Updated, create, and manage your Notion templates."
      />
      <section className="mt-12 space-y-16">
        <section>
          <DashboardH2
            title="Drafts"
            subtitle="Start and refine Notion template until it's ready to be published."
          />
          <div className={gridClassName}>
            <EmptyButtonStateComponent
              icon={PlusCircleIcon}
              onClick={() => createTemplate()}
              visible={true}
              text="New Template"
            />

            {draftTemplates.map((template) => (
              <DashboardTemplateItem
                itemKey={template.id!!}
                template={template}
              />
            ))}
          </div>
        </section>
        {publicTemplates.length > 0 && (
          <section>
            <DashboardH2
              title="Published"
              subtitle="All your published Notion templates are listed here."
            />
            <div className={gridClassName}>
              {publicTemplates.map((template) => (
                <DashboardTemplateItem
                  itemKey={template.id!!}
                  template={template}
                />
              ))}
            </div>
          </section>
        )}
      </section>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(TemplatesPage);
