import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import { sendEmail } from "../../../services/mail-service";
import { Destination } from "../../../utils/constants-navigation";
import {
  APPS_DESCRIPTION,
  CONTACT_DESCRIPTION,
  SCREEN_APPS,
  SCREEN_CONTACT,
  SCREEN_TEMPLATES,
  TITLE_SUFFIX,
} from "../../../utils/constants-seo";
import SEOComponent, {
  SEOPage,
} from "../../../components/common/seo/SEOComponent";
import {
  ArrowDownTrayIcon,
  BellAlertIcon,
  FunnelIcon,
  MagnifyingGlassCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

export default function AppsPage() {
  const features = [
    {
      title: "Discover Weekly",
      description:
        "Welcome to Elcovia, where you'll find fresh Notion templates every week to keep your digital life exciting and dynamic.",
      icon: SparklesIcon,
    },
    {
      title: "Notion Templates",
      description:
        "Easily find the perfect Notion template for your needs. Whether you're looking for free or paid templates, or browsing by categories, we've got you covered.",
      icon: MagnifyingGlassCircleIcon,
    },
    {
      title: "Free & Paid Templates",
      description:
        "Discover a diverse range of Notion templates, both free and paid, that suit various aspects of your life and work.",
      icon: FunnelIcon,
    },
    {
      title: "Categories Galore",
      description:
        "Browse through a wide array of template categories, meticulously organized to cater to different interests, professions, and hobbies.",
      icon: Square2StackIcon,
    },
    {
      title: "Notion Enthausiasts",
      description:
        "Explore profiles of Notion creators and users, discover their creations, and connect with like-minded individuals who share your interests and passions.",
      icon: UserGroupIcon,
    },
    {
      title: "Easy Checkout",
      description:
        "Purchase and download Notion templates directly from creators with a seamless buying experience, empowering you to elevate your digital workflow effortlessly.",
      icon: ArrowDownTrayIcon,
    },
  ];

  const DownloadButtons = () => {
    return (
      <div className="mt-8 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 p-4">
        {/* <a
          href="#"
          title="Download on the App Store"
          className="flex items-center space-x-2 bg-gray-900 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-gray-800 transition duration-200"
        >
          <svg
            aria-hidden="true"
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M19.665 16.811a10.316 10.316 0 0 1-1.021 1.837c-.537.767-.978 1.297-1.316 1.592-.525.482-1.089.73-1.692.744-.432 0-.954-.123-1.562-.373-.61-.249-1.17-.371-1.683-.371-.537 0-1.113.122-1.73.371-.616.25-1.114.381-1.495.393-.577.025-1.154-.229-1.729-.764-.367-.32-.826-.87-1.377-1.648-.59-.829-1.075-1.794-1.455-2.891-.407-1.187-.611-2.335-.611-3.447 0-1.273.275-2.372.826-3.292a4.857 4.857 0 0 1 1.73-1.751 4.65 4.65 0 0 1 2.34-.662c.46 0 1.063.142 1.81.422s1.227.422 1.436.422c.158 0 .689-.167 1.593-.498.853-.307 1.573-.434 2.163-.384 1.6.129 2.801.759 3.6 1.895-1.43.867-2.137 2.08-2.123 3.637.012 1.213.453 2.222 1.317 3.023a4.33 4.33 0 0 0 1.315.863c-.106.307-.218.6-.336.882zM15.998 2.38c0 .95-.348 1.838-1.039 2.659-.836.976-1.846 1.541-2.941 1.452a2.955 2.955 0 0 1-.021-.36c0-.913.396-1.889 1.103-2.688.352-.404.8-.741 1.343-1.009.542-.264 1.054-.41 1.536-.435.013.128.019.255.019.381z" />
          </svg>
          <div className="text-start">
            <span className="block text-xs">Download on the</span>
            <span className="block text-lg font-bold">App Store</span>
          </div>
        </a> */}

        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.elcovia.android"
          title="Download on the Google Play"
          className="flex items-center space-x-2 bg-gray-900 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-gray-800 transition duration-200"
        >
          <svg
            aria-hidden="true"
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="m12.954 11.616 2.957-2.957L6.36 3.291c-.633-.342-1.226-.39-1.746-.016l8.34 8.341zm3.461 3.462 3.074-1.729c.6-.336.929-.812.929-1.34 0-.527-.329-1.004-.928-1.34l-2.783-1.563-3.133 3.132 2.841 2.84zM4.1 4.002c-.064.197-.1.417-.1.658v14.705c0 .381.084.709.236.97l8.097-8.098L4.1 4.002zm8.854 8.855L4.902 20.91c.154.059.32.09.495.09.312 0 .637-.092.968-.276l9.255-5.197-2.666-2.67z" />
          </svg>
          <div className="text-start">
            <span className="block text-xs font-light">Download on the</span>
            <span className="block text-lg font-semibold">Google Play</span>
          </div>
        </a>
      </div>
    );
  };

  const RatingComponent = () => {
    return (
      <div className="mt-3 flex flex-col items-center space-y-2 p-4">
        <div className="flex space-x-3">
          <div className="flex items-center space-x-1">
            {[...Array(5)].map((_, index) => (
              <svg
                key={index}
                className="w-5 h-5 text-yellow-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ))}
          </div>
          <span className="text-sm font-normal text-gray-600">5.0</span>
        </div>
        <p className="text-sm font-light text-gray-600">
          Rated Best Over <span className="font-bold text-gray-900">15.7k</span>{" "}
          Reviews
        </p>
      </div>
    );
  };

  const FeatureSection = () => {
    return (
      <section className="mt-12 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-800">
              Designed for Notion enthusiasts
            </h2>
            <p className="mt-3 mx-auto text-xl font-light leading-7 text-gray-600 max-w-2xl">
              Either use it to discover new templates or to share your own.
              Elcovia's apps are build to make your life easier, as an
              individual and as an creator.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {features.map((feature, index) => (
              <div
                key={index}
                className="transition-all ease-in-out duration-300 group relative bg-white  shadow-sm rounded-lg border border-1 border-gray-200/50 px-6 py-4"
              >
                <div className="flex items-center justify-center">
                  <feature.icon className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="mt-3 text-xl font-semibold text-gray-800 mb-2">
                  {feature.title}
                </h3>
                <p className="text-md font-normal text-gray-500 leading-6">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };

  // Inspired by: https://flowbite.com/marketing-ui/demo/landing/mobile-application/
  return (
    <>
      <SEOComponent
        seo={new SEOPage(SCREEN_APPS + TITLE_SUFFIX, APPS_DESCRIPTION)}
      />
      <div>
        <div className="mx-auto text-center py-12">
          <h1 className="text-6xl font-bold leading-10 tracking-tight text-gray-900">
            Elcovia Apps
          </h1>
          <p className="mt-6 text-xl font-light leading-7 text-gray-600">
            Elcovia Apps is a collection of web applications that are designed
            to help you in your daily life.
          </p>
          <DownloadButtons />
          <div>
            <img
              src={process.env.PUBLIC_URL + "/imgs/android_app.png"}
              alt="Elcovia Apps"
              className="mt-12 w-full max-w-md mx-auto rounded-lg shadow-lg"
            />
            {/* <RatingComponent /> */}
          </div>
          <FeatureSection />
        </div>
      </div>
    </>
  );
}
