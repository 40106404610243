import React from "react";

const LoadingBarComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-16">
      <p className="text-gray-600 text-sm font-normal">
        Please wait a moment...
      </p>
      <div className="mt-8 w-10 h-10 border-t-4 border-b-0 border-black border-solid rounded-full animate-spin"></div>
    </div>
  );
};

export default LoadingBarComponent;
