import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";

import SEOComponent, {
  SEOPage,
} from "../../../../components/common/seo/SEOComponent";
import {
  FAQS_ELCOVIA_DESCRIPTION,
  FAQS_NOTION_DESCRIPTION,
  SCREEN_FAQS_ELCOVIA,
  SCREEN_FAQS_NOTION,
  TITLE_SUFFIX,
} from "../../../../utils/constants-seo";
import { FAQGroupItem } from "./FAQGroupItem";
import { Destination } from "../../../../utils/constants-navigation";
import { FAQ } from "./FAQ";

export default function FAQsNotionPage() {
  const faqs: FAQ[] = [
    {
      question: "What is Notion and how can it be used?",
      answer:
        "Notion is an all-in-one workspace that combines note-taking, task management, and database functions to help users organize their work and personal life.",
    },
    {
      question: "How do I get started with Notion?",
      answer:
        "To get started with Notion, sign up for an account, explore templates, and create your first page to start organizing your information.",
    },
    {
      question: "What are the main features of Notion?",
      answer:
        "Notion's main features include note-taking, task management, databases, calendars, and team collaboration tools.",
    },
    {
      question: "What are the benefits of using Notion templates?",
      answer:
        "Notion templates save time by providing pre-built structures for various use cases, allowing you to quickly set up and start working.",
    },
    {
      question: "How does Notion compare to other productivity tools?",
      answer:
        "Notion stands out for its flexibility and ability to integrate multiple functions in one platform, unlike other tools that specialize in specific areas.",
    },
    {
      question:
        "Can Notion be used for both personal and professional purposes?",
      answer:
        "Yes, Notion is versatile and can be used for personal projects, professional work, team collaboration, and more.",
    },
    {
      question:
        "What are the best practices for organizing information in Notion?",
      answer:
        "Best practices include using templates, creating databases for structured information, and organizing pages hierarchically.",
    },
    {
      question: "How can Notion help improve team collaboration?",
      answer:
        "Notion improves team collaboration by allowing shared workspaces, real-time editing, and easy communication within the platform.",
    },
    {
      question: "Is Notion suitable for project management?",
      answer:
        "Yes, Notion offers features like task lists, kanban boards, calendars, and timelines, making it suitable for project management.",
    },
    {
      question: "How does Notion handle task management?",
      answer:
        "Notion handles task management through to-do lists, reminders, due dates, and kanban boards to track progress.",
    },
    {
      question: "What is the learning curve for new Notion users?",
      answer:
        "The learning curve for Notion is moderate, but there are many tutorials and templates available to help new users get started quickly.",
    },
    {
      question: "How can Notion be customized to fit my workflow?",
      answer:
        "Notion can be customized with templates, database views, custom properties, and integrations to fit any workflow.",
    },
    {
      question: "What are some creative ways to use Notion?",
      answer:
        "Creative uses of Notion include personal journals, recipe collections, travel planning, habit tracking, and more.",
    },
    {
      question: "How does Notion support remote work?",
      answer:
        "Notion supports remote work through shared workspaces, real-time collaboration, and integrations with other remote work tools.",
    },
    {
      question: "Can Notion replace other tools I currently use?",
      answer:
        "Notion can replace several tools like note-taking apps, task managers, and project management tools, depending on your needs.",
    },
    {
      question: "What integrations are available with Notion?",
      answer:
        "Notion integrates with tools like Google Calendar, Slack, Zapier, and more to streamline your workflow.",
    },
    {
      question: "How secure is the data stored in Notion?",
      answer:
        "Notion uses encryption and follows industry-standard security practices to ensure your data is secure.",
    },
    {
      question: "What are Notion blocks and how do they work?",
      answer:
        "Notion blocks are the building units of Notion pages, including text, images, databases, and more, which can be arranged flexibly.",
    },
    {
      question: "How does Notion's database feature enhance productivity?",
      answer:
        "Notion's database feature allows for structured data organization, easy filtering, sorting, and visualization to enhance productivity.",
    },
    {
      question: "Can Notion be used offline?",
      answer:
        "Notion has limited offline capabilities; you can view and edit recently opened pages, but full functionality requires an internet connection.",
    },
    {
      question:
        "What are some common challenges when using Notion and how can they be overcome?",
      answer:
        "Common challenges include the initial learning curve and setting up complex databases, which can be overcome with tutorials and practice.",
    },
    {
      question: "How do updates and new features get rolled out in Notion?",
      answer:
        "Notion regularly rolls out updates and new features, which are announced on their official blog and social media channels.",
    },
    {
      question: "How can I share my Notion workspace with others?",
      answer:
        "You can share your Notion workspace by inviting team members via email or sharing a link with specific permissions.",
    },
    {
      question:
        "What are the advantages of using Notion for knowledge management?",
      answer:
        "Notion centralizes information, making it easy to organize, search, and access knowledge, enhancing productivity and collaboration.",
    },
    {
      question:
        "How do I find inspiration and ideas for using Notion more effectively?",
      answer:
        "You can find inspiration and ideas by exploring Notion templates, joining Notion communities, and following productivity blogs and forums.",
    },
    {
      question: "Can I use Notion for journaling?",
      answer:
        "Yes, Notion is an excellent tool for journaling, allowing you to create daily entries, track habits, and reflect on your thoughts.",
    },
    {
      question: "What are the best ways to organize a Notion dashboard?",
      answer:
        "Organize a Notion dashboard by grouping related pages, using widgets for quick access to information, and customizing it to fit your workflow.",
    },
    {
      question: "How do I manage permissions in Notion?",
      answer:
        "Manage permissions in Notion by sharing pages with specific users or groups and setting access levels like view, comment, or edit.",
    },
    {
      question: "Can Notion be used for content planning?",
      answer:
        "Yes, Notion is ideal for content planning, allowing you to create editorial calendars, manage drafts, and collaborate with your team.",
    },
    {
      question: "How do I search for information in Notion?",
      answer:
        "Notion provides a powerful search function that allows you to find text, files, and pages within your workspace quickly and easily.",
    },
    {
      question: "Can Notion be used for budgeting and finance tracking?",
      answer:
        "Yes, Notion can be used for budgeting and finance tracking by creating databases or tables to record and manage financial information.",
    },
    {
      question: "What are Notion widgets and how can they be used?",
      answer:
        "Notion widgets are third-party tools that can be embedded into Notion pages to add functionality like weather updates, calendars, and more.",
    },
    {
      question: "How does Notion handle recurring tasks?",
      answer:
        "Notion can handle recurring tasks by using databases with date properties and automation tools like Notion API integrations.",
    },
    {
      question: "Can I use Notion for CRM purposes?",
      answer:
        "Yes, Notion can be customized to function as a CRM by creating databases to track leads, clients, interactions, and sales pipelines.",
    },
    {
      question: "How do I track habits using Notion?",
      answer:
        "Track habits in Notion by creating a habit tracker template, using checkboxes or databases to monitor your daily or weekly habits.",
    },
    {
      question: "What are some advanced features of Notion?",
      answer:
        "Advanced features of Notion include relational databases, rollup properties, API integrations, and advanced customization options.",
    },
  ];

  const faqsChunked = faqs.map((_, i) =>
    faqs.slice(i * (faqs.length / 2), (i + 1) * (faqs.length / 2))
  );

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_FAQS_NOTION + TITLE_SUFFIX,
            FAQS_NOTION_DESCRIPTION
          )
        }
      />
      <div className="mx-auto w-full">
        <div className="flex gap-x-16 items-center justify-center">
          <div>
            <h2 className="text-5xl font-bold tracking-tight text-gray-900 leading-snug">
              FAQs for{" "}
              <span className="text-indigo-600">Notion Enthusiasts</span>
            </h2>
            <p className="mt-6 text-lg leading-7 text-gray-600">
              Have a different question?{" "}
              <a
                href={Destination.CONTACT}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Contact
              </a>{" "}
              us and we'll be happy to help!
            </p>
          </div>
          <embed src="/illustrations/notioly/community.svg" className="h-80" />
        </div>
        <FAQGroupItem faqsChunked={faqsChunked} />
      </div>
    </>
  );
}
