import React, { ReactNode } from "react";
import { connect, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";

import FooterComponent from "../components/core/footer/FooterComponent";
import FooterPoweredComponent from "../components/core/footer/FooterPoweredComponent";
import PublicHeaderComponent from "../components/core/header/PublicHeaderComponent";
import AffiliateGumroadGuideScreen from "../pages/account/misc/AffiliateGumroadGuideScreen";
import AppsScreen from "../pages/public/info/AppsPage";
import BetaTestersPage from "../pages/public/info/BetaTestersPage";
import ContactPage from "../pages/public/info/ContactPage";
import FAQsElcoviaPage from "../pages/public/info/faq/FAQsElcoviaPage";
import FAQsNotionPage from "../pages/public/info/faq/FAQsNotionPage";
import { PrivacyPolicyPage } from "../pages/public/legal/PrivacyPolicyPage";
import { TermsAndConditionsPage } from "../pages/public/legal/TermsAndConditionsPage";
import DiscoverPage from "../pages/public/main/discover/DiscoverPage";
import LoginPage from "../pages/public/main/LoginPage";
import MarketplaceTemplatePage from "../pages/public/main/MarketplaceTemplatePage";
import ProfileScreen from "../pages/public/main/ProfileScreen";
import ProfilesPage from "../pages/public/main/ProfilesPage";
import TemplatePage from "../pages/public/main/template/TemplatePage";
import TemplatesPage from "../pages/public/main/templates/TemplatesPage";
import TemplatesLatestPage from "../pages/public/main/templates_latest/TemplatesLatestPage";
import { RootState } from "../store";
import {
  Args,
  Destination,
  URL_PARAM_DISCOVER_VALUE,
} from "../utils/constants-navigation";
import { Logger } from "../utils/utils-logging";
import TemplateCategoriesPage from "../pages/public/main/templates_categories/TemplateCategoriesPage";

const logger = new Logger("PublicApp");

const PublicApp = () => {
  const location = useLocation();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const [isHeaderVisible, setIsHeaderVisible] = React.useState(true);

  interface PageContainerProps {
    children: ReactNode;
    hasHeader: boolean;
  }

  const PageContainer = ({ children, hasHeader }: PageContainerProps) => {
    logger.log("PageContainer: hasHeader", hasHeader);
    return (
      <div
        className={`max-w-3xl lg:max-w-6xl xl:max-w-7xl min-h-screen mx-auto px-4 md:px-6 xl:px-8 ${
          hasHeader ? "mt-20 pt-12 md:pt-12 xl:pt-16" : "pt-8 md:pt-12 xl:pt-16"
        }`}
      >
        <div>{children}</div>
      </div>
    );
  };

  React.useEffect(() => {
    const specialPaths = [
      Destination.TEMPLATE_ID,
      Destination.TEMPLATE_ID_SHORT,
      Destination.TEMPLATE_PREVIEW_ID,
      Destination.USER_ID,
      Destination.USER_ID_SHORT,
    ];
    // Check if the URL contains the discover param
    const urlParams = new URLSearchParams(location.search);
    const pathContainsParam =
      urlParams.get(Args.LAYOUT) === URL_PARAM_DISCOVER_VALUE;

    // Remove last segment of the path
    const currentPath = location.pathname;
    const pathSegments = currentPath.split("/");
    const pathWithoutId = pathSegments.slice(0, -1).join("/");

    const currentPathSameAsDestination = specialPaths.some((path) => {
      const fixedPathWithoutId = path.replace("/:id", "");
      const samePath = pathWithoutId === fixedPathWithoutId;
      logger.log("currentPathSameAsDestination", samePath, {
        pathWithoutId,
        fixedPathWithoutId,
      });
      return samePath;
    });

    // Hide the header if the path contains the param and the current path is the same as the destination
    const isNotVisible = !pathContainsParam && currentPathSameAsDestination;

    logger.log("isNotVisible", isNotVisible, {
      pathContainsParam,
      currentPathSameAsDestination,
    });

    setIsHeaderVisible(!isNotVisible);
  }, [location]);

  return (
    <>
      {isHeaderVisible && <PublicHeaderComponent />}
      <PageContainer hasHeader={isHeaderVisible}>
        <Routes>
          <Route path={Destination.DISCOVER} element={<DiscoverPage />} />
          <Route path={Destination.LOGIN} element={<LoginPage />} />
          <Route
            path={Destination.TEMPLATES_LATEST}
            element={<TemplatesLatestPage />}
          />
          <Route path={Destination.TEMPLATES} element={<TemplatesPage />} />
          <Route
            path={Destination.TEMPLATES_CATEGORIES}
            element={<TemplateCategoriesPage />}
          />
          <Route
            path={Destination.TEMPLATE_ID}
            element={<TemplatePage isDiscover={isHeaderVisible} />}
          />
          <Route
            path={Destination.TEMPLATE_ID_SHORT}
            element={<TemplatePage isDiscover={isHeaderVisible} />}
          />
          <Route
            path={Destination.PURCHASE_MARKETPLACE_TEMPLATE_REFERENCE_ID}
            element={<MarketplaceTemplatePage />}
          />
          <Route
            path={Destination.CREATORS_ALTERNATIVE}
            element={<ProfilesPage />}
          />
          <Route path={Destination.CREATORS} element={<ProfilesPage />} />
          <Route
            path={Destination.USERS_ALTERNATIVE}
            element={<ProfilesPage />}
          />
          <Route path={Destination.USERS} element={<ProfilesPage />} />
          <Route
            path={Destination.USER_ID}
            element={<ProfileScreen discover={isHeaderVisible} />}
          />
          <Route
            path={Destination.USER_ID_SHORT_DEPRECIATED}
            element={<ProfileScreen discover={true} />}
          />

          <Route
            path={Destination.USER_ID_SHORT}
            element={<ProfileScreen discover={isHeaderVisible} />}
          />
          <Route
            path={Destination.BETA_TESTERS}
            element={<BetaTestersPage />}
          />
          <Route
            path={Destination.FAQS_ELCOVIA}
            element={<FAQsElcoviaPage />}
          />
          <Route path={Destination.FAQS_NOTION} element={<FAQsNotionPage />} />
          <Route path={Destination.CONTACT} element={<ContactPage />} />
          <Route path={Destination.APPS} element={<AppsScreen />} />
          <Route
            path={Destination.PRIVACY_POLICY}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={Destination.TERMS_AND_CONDITIONS}
            element={<TermsAndConditionsPage />}
          />
          <Route
            path={Destination.AFFILIATE_GUMROAD_GUIDE}
            element={<AffiliateGumroadGuideScreen />}
          />
          {isSignedIn && (
            <Route
              path={Destination.TEMPLATE_PREVIEW_ID}
              element={<TemplatePage isDiscover={isHeaderVisible} />}
            />
          )}
          {/* <Route path={location.pathname} element={<NotFoundScreen />} /> */}
        </Routes>
      </PageContainer>
      {isHeaderVisible ? <FooterComponent /> : <FooterPoweredComponent />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(PublicApp);
