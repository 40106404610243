import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import ButtonComponent, { ButtonStyle } from "../buttons/ButtonComponent";

interface DialogButton {
  visible: boolean;
  text: string;
  style: ButtonStyle;
  onClick: () => void;
}

interface DialogComponentParams {
  show: boolean;
  title?: string;
  description?: string;
  content: React.ReactNode;
  buttons: DialogButton[];
  onClose: () => void;
}

export const DialogComponent: React.FC<DialogComponentParams> = ({
  show,
  title,
  description,
  content,
  buttons,
  onClose,
}) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="">
                  <div className="text-center">
                    {/* Dialog Header Component */}
                    {title ||
                      (description && (
                        <div className="z-20 py-6 border-b border-gray-200 drop-shadow-sm">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            {title}
                          </Dialog.Title>
                          <p className="mt-1 text-sm font-normal text-gray-600">
                            {description}
                          </p>
                        </div>
                      ))}
                    {/* Dialog Content Component */}
                    <div className="p-6 overflow-y-scroll max-h-120 z-0">
                      {content}
                    </div>
                  </div>
                </div>
                {/* Dialog Footer Component */}
                <div className="py-4 px-4 text-right border-t border-gray-200 flex justify-end gap-x-4">
                  {buttons.map((button) => (
                    <>
                      {button.visible && (
                        <ButtonComponent
                          onClick={button.onClick}
                          type="button"
                          style={button.style}
                          text={button.text}
                        />
                      )}
                    </>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
