import {
  BookmarkSquareIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  HeartIcon,
  HomeIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { connect, useSelector } from "react-redux";

import { RootState } from "../../store";
import { Destination, setPathId } from "../../utils/constants-navigation";
import { getProfileId } from "../../utils/utils-profile";
import DashboardComponent, {
  NavigationGroup,
  NavigationItem,
} from "../../components/common/common/DashboardComponent";

interface AccountPageProps {
  title?: string;
  titleView?: React.ReactNode;
  children?: React.ReactNode;
}

const AccountPage: React.FC<AccountPageProps> = ({
  title,
  titleView,
  children,
}) => {
  const userProfile = useSelector((state: RootState) => state.user.profile);

  const navigation: NavigationItem[] = [
    {
      name: "Dashboard",
      href: Destination.ACCOUNT_DASHBOARD,
      icon: HomeIcon,
    },
    {
      name: "Profile",
      href: Destination.ACCOUNT_PROFILE,
      icon: UserCircleIcon,
    },
    {
      name: "Templates",
      href: Destination.ACCOUNT_TEMPLATES,
      icon: RectangleGroupIcon,
    },
    {
      name: "Audience",
      href: Destination.ACCOUNT_AUDIENCE,
      icon: UserGroupIcon,
    },
    // {
    //   name: "Analytics",
    //   href: Destination.ACCOUNT_ANALYTICS,
    //   icon: ChartBarIcon,
    // },
  ];

  const bottomNavigation: NavigationItem[] = [
    {
      name: "Settings",
      href: Destination.ACCOUNT_SETTINGS,
      icon: Cog6ToothIcon,
    },
  ];

  const navigationLists: NavigationItem[] = [
    {
      name: "Wishlist",
      href: Destination.ACCOUNT_WISHLIST,
      icon: HeartIcon,
    },
    {
      name: "Library",
      href: Destination.ACCOUNT_LIBRARY,
      icon: BookmarkSquareIcon,
    },
  ];

  const navigationExternal: NavigationItem[] = [
    {
      name: "Public Profile",
      href: setPathId(Destination.USER_ID_SHORT, getProfileId(userProfile!!)),
      icon: UserCircleIcon,
      external: true,
    },
    {
      name: "Discover",
      href: Destination.DISCOVER,
      icon: RectangleStackIcon,
      external: true,
    },
    {
      name: "Templates",
      href: Destination.TEMPLATES,
      icon: RectangleGroupIcon,
      external: true,
    },
    {
      name: "Creators",
      href: Destination.USERS,
      icon: UsersIcon,
      external: true,
    },
  ];

  const navigationGroups: NavigationGroup[] = [
    {
      name: "Account",
      items: navigation,
    },
    {
      name: "Lists",
      items: navigationLists,
    },
    {
      name: "Elcovia",
      items: navigationExternal,
    },
  ];

  return (
    <DashboardComponent
      key="account"
      title={title}
      titleView={titleView}
      navigationGroups={navigationGroups}
      bottomNavigation={bottomNavigation}
      children={children}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(AccountPage);
