import React from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SEOComponent, {
  SEOPage,
} from "../../../components/common/seo/SEOComponent";
import { Template } from "../../../models/Template";
import {
  getTemplatesByIds,
  getUserLikes,
} from "../../../services/firestore-service";
import { RootState } from "../../../store";
import {
  ACCOUNT_LIKED_DESCRIPTION,
  ACCOUNT_TEMPLATES_DESCRIPTION,
  SCREEN_ACCOUNT_TEMPLATES,
  SCREEN_TITLE,
} from "../../../utils/constants-seo";
import {
  LocalStorageKey,
  setLocalStorage,
} from "../../../utils/utils-local-storage";
import { Logger } from "../../../utils/utils-logging";
import { classNames } from "../../../utils/utils-react";
import TemplateItem from "../../../components/template/TemplateItem";
import TabsComponent from "../../../components/common/tabs/TabsComponent";
import { DashboardH1 } from "../../../components/common/common/DashboardHeaderComponents";

const logger = new Logger("LikedScreen");

interface WishlistScreenParams {}

const TabType = {
  TEMPLATES: "Templates",
};

type Tab = {
  name: (typeof TabType)[keyof typeof TabType];
  current: boolean;
};

const initTabs: Tab[] = [{ name: TabType.TEMPLATES, current: true }];

const WishlistPage: React.FC<WishlistScreenParams> = () => {
  const navigate = useNavigate();
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [tabs, setTabs] = React.useState<Tab[]>(initTabs);

  /**
   * Handle tab click event.
   */
  function handleTabClick(tab: Tab) {
    const newTabs = tabs.map((t) => ({
      ...t,
      current: t.name === tab.name,
    }));
    const selectedIndex = newTabs.findIndex((t) => t.current);
    setLocalStorage(LocalStorageKey.templatesTabIndex, selectedIndex);
    setTabs(newTabs);
    setTemplates(getDataForCurrentTab(newTabs, templates));
  }

  React.useEffect(() => {
    const fetchTemplates = async () => {
      const likedTemplates = (await getUserLikes(sessionProfile!!.id)) || [];
      logger.log("likedTemplates", likedTemplates);
      const templates = await getTemplatesByIds(likedTemplates);
      const sortedPublicTemplates = templates.sort(
        (a, b) => b.published!.seconds - a.published!.seconds
      );
      setTemplates(sortedPublicTemplates);
    };
    fetchTemplates();
  }, []);

  /**
   * Get the templates to display based on the current tab.
   */
  function getDataForCurrentTab(
    tabs: Tab[],
    templates: Template[]
  ): Template[] {
    if (tabs[0].current) {
      return templates;
    } else {
      return [];
    }
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TITLE + SCREEN_ACCOUNT_TEMPLATES,
            ACCOUNT_LIKED_DESCRIPTION
          )
        }
      />
      <DashboardH1
        title="Wishlist"
        subtitle="Products you've wishlisted for later."
      />
      <div>
        <div className="w-full flex items-top">
          <TabsComponent handleTabClick={handleTabClick} tabs={tabs} />
        </div>
      </div>
      {templates.length > 0 ? (
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 xl:gap-x-8">
          {templates &&
            templates.map((template) => (
              <TemplateItem
                itemKey={template.id}
                template={template}
                detailedBar={false}
              />
            ))}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(WishlistPage);
