import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ConfirmationDialogComponent } from "../../../components/common/dialogs/ConfirmationDialog";
import { OrderDetails } from "../../../models/data/payment/Order";
import {
  getLabelOfOrderType,
  OrderType,
} from "../../../models/data/payment/OrderType";
import { setSignedIn, setUserProfile } from "../../../redux/UserActions";
import {
  deleteFirebaseAccount,
  getUserOrders,
  getUserSecretById,
} from "../../../services/firestore-service";
import {
  deleteAccountData,
  getStripeOnboardingLink,
} from "../../../services/functions-service";
import { RootState } from "../../../store";
import { Destination, setPathId } from "../../../utils/constants-navigation";
import {
  ACCOUNT_DASHBOARD_DESCRIPTION,
  SCREEN_ACCOUNT_DASHBOARD,
  SCREEN_TITLE,
} from "../../../utils/constants-seo";
import {
  durationKeyToLabel,
  timestampToLocalDate,
} from "../../../utils/utils-formatting";
import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import { UserSecret } from "../../../models/UserSecret";
import TextFieldComponent from "../../../components/common/input/TextFieldComponent";
import SEOComponent, {
  SEOPage,
} from "../../../components/common/seo/SEOComponent";
import { classNames } from "../../../utils/utils-react";
import TabsComponent from "../../../components/common/tabs/TabsComponent";
import SettingsHeaderComponent from "../../../components/common/settings/SettingsHeaderComponent";
import SettingsSplitGroupComponent from "../../../components/common/settings/SettingsSplitGroupComponent";
import SettingsGroupComponent from "../../../components/common/settings/SettingsGroupComponent";
import {
  DashboardH1,
  DashboardH2,
} from "../../../components/common/common/DashboardHeaderComponents";

const TabType = {
  STRIPE: "Stripe",
  // DRAFTS: "Drafts",
};

type Tab = {
  name: (typeof TabType)[keyof typeof TabType];
  current: boolean;
};

const initTabs: Tab[] = [
  { name: TabType.STRIPE, current: true },
  // { name: TabType.DRAFTS, current: false },
];

interface SettingsPageProps {}

const SettingsPage: React.FC<SettingsPageProps> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    React.useState(false);

  const [orders, setOrders] = React.useState<OrderDetails[] | null>(null);
  const [userSecret, setUserSecret] = React.useState<UserSecret | null>(null);
  const [tabs, setTabs] = React.useState<Tab[]>(initTabs);

  React.useEffect(() => {
    async function loadUserSecret() {
      try {
        if (!isSignedIn || !sessionProfile) return;
        const userSecret = await getUserSecretById(sessionProfile.id);
        setUserSecret(userSecret);
      } catch (error) {
        console.error(error);
      }
    }
    if (userSecret === null) loadUserSecret();

    /**
     * This function loads the user's orders from the database.
     */
    async function loadOrders() {
      try {
        if (!isSignedIn || !sessionProfile) return;
        const orders = await getUserOrders(sessionProfile.id);
        const completedOrders = orders
          .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
          .filter((order) => order.isCompleted());
        setOrders(completedOrders);
      } catch (error) {
        console.error(error);
      }
    }
    if (orders === null) loadOrders();
  }, []);

  /**
   * Handle tab click event.
   */
  function handleTabClick(tab: Tab) {
    const newTabs = tabs.map((t) => ({
      ...t,
      current: t.name === tab.name,
    }));
    setTabs(newTabs);
  }

  async function openStripeOnboarding() {
    if (!sessionProfile) return;
    const response = await getStripeOnboardingLink();
    window.open(response.data.url);
  }

  /**
   * Deletes the user's account and the account data.
   */
  async function deleteAccount() {
    if (!sessionProfile) return;
    try {
      await deleteAccountData();
      await deleteFirebaseAccount();
      dispatch(setSignedIn(false));
      dispatch(setUserProfile(null));
      navigate(Destination.DISCOVER);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TITLE + SCREEN_ACCOUNT_DASHBOARD,
            ACCOUNT_DASHBOARD_DESCRIPTION
          )
        }
      />
      {/* This `div` block is the main container for the screen. */}
      <ConfirmationDialogComponent
        show={openDeleteConfirmationDialog}
        title="Delete Account"
        positiveButtonText="Delete"
        description="Are you sure you want to delete your account? This includes all your data and cannot be undone."
        onNegative={() => setOpenDeleteConfirmationDialog(false)}
        onPositive={() => {
          deleteAccount();
          setOpenDeleteConfirmationDialog(false);
        }}
      />
      <DashboardH1 title="Settings" />
      <div className="space-y-12">
        <section>
          <DashboardH2
            title="Payment Profile"
            subtitle="If you are a creator of paid Notion Templates, you have to setup a
              payment profile to receive payments from your customers and to be
              able to sell your Notion Templates. Customers do not have to setup
              a payment profile."
          />
          <>
            <div className="w-full flex items-top">
              <TabsComponent
                title="Payment Methods"
                handleTabClick={handleTabClick}
                tabs={tabs}
              />
            </div>
            {tabs.map((tab) => (
              <div key={tab.name}>
                {tab.current && (
                  <div>
                    {tab.name === TabType.STRIPE && (
                      <div className="mt-8">
                        <div className="bg-white shadow-sm rounded-md border border-gray-200">
                          <div className="px-4 py-5 sm:p-6">
                            <SettingsHeaderComponent
                              withoutBottomPadding={true}
                              title="Stripe"
                              description=" Seamlessly integrate with Stripe and receive
                                    payments directly to your own Stripe
                                    account."
                              content={
                                <>
                                  {userSecret != null &&
                                    (userSecret?.valid == null ||
                                      userSecret?.valid == false) && (
                                      <div className="max-w-3xl p-4 bg-red-100 rounded-md mt-3 text-xs text-red-500 leading-5">
                                        There are some issues with your Stripe
                                        account. Please manage your Stripe
                                        account to resolve these issues. You
                                        will not be able to sell your Notion
                                        Templates until these issues are
                                        resolved.
                                      </div>
                                    )}
                                  {userSecret?.stripeAccountId && (
                                    <SettingsGroupComponent
                                      title="Stripe Account ID"
                                      description="Seamlessly integrate with Stripe and receive payments directly to your own Stripe account."
                                      content={
                                        <>
                                          <TextFieldComponent
                                            id="stripe-account-id"
                                            name="stripe-account-id"
                                            type="text"
                                            required
                                            additionalClassName="mt-2 max-w-xs"
                                            value={userSecret?.stripeAccountId}
                                            placeholder="Name"
                                            disabled
                                          />
                                        </>
                                      }
                                    />
                                  )}
                                  <div className="mt-5">
                                    <ButtonComponent
                                      text={
                                        userSecret == null
                                          ? "Connect Stripe"
                                          : "Manage Stripe"
                                      }
                                      onClick={() => openStripeOnboarding()}
                                      style="structural"
                                    />
                                  </div>
                                </>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </>
        </section>
        <section>
          <DashboardH2
            title="Order History"
            subtitle="Access and review your order history to track and view details of your past purchases."
          />
          <>
            {orders && orders.length > 0 && (
              <div className="mx-auto max-w-screen-xl">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Order History
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-500"></p>
                <dl className="mt-3 space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  {orders?.map((order) => (
                    <div className="pt-3 sm:flex items-center">
                      <dt className="sm:w-64 sm:flex-none sm:pr-6">
                        <div className="grid items-start">
                          <p className="text-md font-medium text-gray-900">
                            {getLabelOfOrderType(order.type)}
                          </p>
                          <div className="text-xs font-normal text-gray-500">
                            {timestampToLocalDate(order.timestamp)}
                          </div>
                        </div>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        {order.durationKey && (
                          <div className="grid items-start">
                            <p className="text-xs font-semibold text-gray-900">
                              {order.price} {order.currency}
                            </p>
                            <div className="text-xs font-normal text-gray-500">
                              / {durationKeyToLabel(order.durationKey)}
                            </div>
                          </div>
                        )}
                        <div className="grid items-start w-auto items-center">
                          {order.type == OrderType.TemplatePromotion && (
                            <a
                              href={setPathId(
                                Destination.TEMPLATE_ID,
                                order.referenceId.split("-")[1]
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-xs font-normal text-gray-500 hover:text-gray-900"
                            >
                              View Template
                            </a>
                          )}
                          {order.type == OrderType.MarketplaceTemplate && (
                            <a
                              href={setPathId(
                                Destination.PURCHASE_MARKETPLACE_TEMPLATE_REFERENCE_ID,
                                order.referenceId
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-xs font-normal text-gray-500 hover:text-gray-900"
                            >
                              View Purchase
                            </a>
                          )}
                          {order.type == OrderType.ProfilePromotion && (
                            <a
                              href={setPathId(
                                Destination.USER_ID,
                                order.referenceId.split("-")[0]
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-xs font-normal text-gray-500 hover:text-gray-900"
                            >
                              View Profile
                            </a>
                          )}
                        </div>

                        <div className="grid items-start">
                          <p
                            className={classNames(
                              order.isCompleted()
                                ? "text-green-600"
                                : "text-red-600",
                              "font-semibold text-xs"
                            )}
                          >
                            {order.isCompleted()
                              ? "Success"
                              : order.paypalResponse?.status}
                          </p>
                          <p className="ml-2 font-semibold text-xs">
                            {order.getSource()}
                          </p>
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            )}
          </>
        </section>
        <section>
          <DashboardH2
            title="Account Settings"
            subtitle="Manage your account settings here. Changes here have deep impact on your account and cannot be undone."
          />
          <>
            <SettingsSplitGroupComponent
              name="deleteAccount"
              title="Delete account"
              description={
                "No longer want to use our service? You can delete your  account here. This action is not reversible. All information related to this account will be deleted permanently."
              }
              content={
                <ButtonComponent
                  style="structural"
                  onClick={() => setOpenDeleteConfirmationDialog(true)}
                  text="Delete"
                />
              }
            />
          </>
        </section>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(SettingsPage);
