import { Dialog, Disclosure, Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  BuildingStorefrontIcon,
  ChatBubbleBottomCenterTextIcon,
  Cog6ToothIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  FaceSmileIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment, MouseEventHandler, useState } from "react";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { LinkProps, NavLink, useLocation, useNavigate } from "react-router-dom";

import { logout } from "../../../services/action-service";
import { RootState } from "../../../store";
import { ExternalLinks } from "../../../utils/constants";
import {
  ARGS_PARAM_DISCOVER,
  Destination,
  setPathIdAndAppend,
} from "../../../utils/constants-navigation";
import { Logger } from "../../../utils/utils-logging";
import { getProfileId } from "../../../utils/utils-profile";
import { classNames } from "../../../utils/utils-react";
import HeaderProfileComponent, {
  ActionSectionItem,
  LinkSectionItem,
} from "./profile_state/HeaderProfileComponent";

const logger = new Logger("Header");

type CustomNavLinkProps = {
  baseClassName: string;
  to: LinkProps["to"];
  children: React.ReactNode;
  onClick?: MouseEventHandler<any>;
};

const PublicHeaderComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const headerItems = [
    { title: "Discover", path: Destination.DISCOVER },
    { title: "Templates", path: Destination.TEMPLATES },
    { title: "Categories", path: Destination.TEMPLATES_CATEGORIES },
    { title: "Creators", path: Destination.USERS },
  ];

  const CustomNavLink: React.FC<CustomNavLinkProps> = ({
    baseClassName,
    to,
    children,
    onClick,
  }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    return (
      <NavLink
        to={to}
        onClick={onClick}
        className={classNames(
          baseClassName,
          isActive
            ? "text-sm font-medium text-indigo-500"
            : "text-sm font-medium text-gray-900",
          "transition-all ease-in-out duration-300"
        )}
      >
        {children}
      </NavLink>
    );
  };

  const [scrolled, setScrolled] = useState(false);

  /**
   * Handle scroll event.
   */
  React.useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.pageYOffset > 80);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /**
   * Handle logout button click.
   */
  const handleLogout = () => {
    logout(dispatch);
    navigate(Destination.DISCOVER);
  };

  const linkSectionItems: LinkSectionItem[] = [
    {
      name: "Profile",
      description: "View your public profile on Elcovia.",
      href: setPathIdAndAppend(
        Destination.USER_ID_SHORT,
        getProfileId(userProfile),
        ARGS_PARAM_DISCOVER
      ),
      icon: FaceSmileIcon,
    },
    {
      name: "Dashboard",
      description: "Mange your profile and all of your products.",
      href: Destination.ACCOUNT_DASHBOARD,
      icon: BuildingStorefrontIcon,
    },
    {
      name: "Settings",
      description: "Manage your settings and preferences.",
      href: Destination.ACCOUNT_SETTINGS,
      icon: Cog6ToothIcon,
    },
    {
      name: "Logout",
      description: "Sign out of your Elcovia account.",
      onClick: () => handleLogout(),
      icon: ArrowLeftOnRectangleIcon,
    },
  ];

  const actionSectionItems: ActionSectionItem[] = [
    {
      name: "Mobile Apps",
      href: Destination.APPS,
      icon: DevicePhoneMobileIcon,
    },
    {
      name: "ElcoviaGPT",
      href: ExternalLinks.ELCOVIA_GTP,
      icon: ChatBubbleBottomCenterTextIcon,
    },
    {
      name: "Contact",
      href: Destination.CONTACT,
      icon: EnvelopeIcon,
    },
  ];

  return (
    <header className="transition-all ease-in-out duration-300 bg-white fixed top-0 left-0 right-0 z-10 border-b border-1 border-gray-200">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between h-16 px-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Elcovia</span>
            <div className="flex flex-inline items-center gap-x-4">
              <img
                className="h-6 w-6"
                src={process.env.PUBLIC_URL + "/logo.png"}
                alt="Elcovia Logo"
              />
              <img
                className="h-6 w-auto"
                src={process.env.PUBLIC_URL + "/text-logo.png"}
                alt="Elcovia Text Logo"
              />
            </div>
          </a>
        </div>
        <div className="flex md:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden md:pl-12 md:flex md:gap-x-12">
          {headerItems.map((item, index) => (
            <CustomNavLink
              baseClassName={classNames("leading-6 header-item")}
              key={item.title}
              to={item.path}
            >
              {item.title}
            </CustomNavLink>
          ))}
        </Popover.Group>
        <div className="hidden md:flex md:flex-1 md:justify-end">
          <HeaderProfileComponent
            linkSectionItems={linkSectionItems}
            actionSectionItems={actionSectionItems}
          />
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="flex -m-1.5 p-1.5 items-center">
              <span className="sr-only">Elcovia</span>
              <img
                className="h-6 w-6 w-auto"
                src="../logo.png"
                alt="Elcovia Logo"
              />{" "}
              <p className="ml-4 font-bold text-sm text-indigo-500">BETA</p>
            </a>

            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {headerItems.map((item, index) => (
                  <CustomNavLink
                    onClick={() => setMobileMenuOpen(false)}
                    baseClassName="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    key={index}
                    to={item.path}
                  >
                    {item.title}
                  </CustomNavLink>
                ))}
              </div>
              <div className="py-6">
                {isSignedIn ? (
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                          <img
                            src={userProfile?.image}
                            alt={userProfile?.name || ""}
                            className="w-6 h-6 rounded-full drop-shadow max"
                          />
                          <h3 className="ml-6 mr-12">{userProfile?.name}</h3>

                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              " h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {linkSectionItems.map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              onClick={item.onClick}
                              href={item.href}
                              className="flex items-center rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              <item.icon
                                className="h-4 w-4 text-gray-600 group-hover:text-indigo-600 mr-4"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ) : location.pathname != "/login" ? (
                  <a
                    href=""
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Login
                  </a>
                ) : null}
              </div>

              <div className="py-6">
                <div className="space-y-2">
                  {actionSectionItems.map((item) => (
                    <a
                      key={item.name}
                      onClick={item.onClick}
                      href={item.href}
                      className="flex items-center rounded-lg py-2 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      <item.icon
                        className="h-4 w-4 text-gray-600 group-hover:text-indigo-600 mr-4"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(PublicHeaderComponent);
