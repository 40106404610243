import React from "react";
import { Helmet } from "react-helmet";
import { Template } from "../../../models/Template";
import { Logger } from "../../../utils/utils-logging";
import {
  SCREEN_TEMPLATE_SUFFIX,
  TAB_TITLE_SUFFIX,
  TITLE_SUFFIX,
  DEFAULT_DESCRIPTION,
  SCREEN_PROFILE_SUFFIX,
  NOTION_TEMPLATES_KEYWORDS,
  NOTION_PROFILES_KEYWORDS,
  NOTION_GENERAL_KEYWORDS,
} from "../../../utils/constants-seo";
import { localizedValueWithKey } from "../../../utils/supported-locales";
import { Profile } from "../../../models/Profile";
import { ExternalLinks } from "../../../utils/constants";
import { ImageTargetKeys } from "../../../models/metadata/ImageTarget";
import {
  Destination,
  setWebsitePathId,
} from "../../../utils/constants-navigation";
import {
  ELCOVIA_FAVICON,
  ELCOVIA_LOGO,
  ELCOVIA_OPEN_GRAPH,
  ELCOVIA_TWITTER_CARD,
} from "../../../utils/constants-resources";

const logger = new Logger("SEOComponent");

interface SEOComponentProps {
  seo: SEOPage | SEOTemplatePage | SEOProfilePage;
}

export class SEOPage {
  title: string;
  description: string;
  url?: string;
  imgUrl?: string;

  constructor(
    title: string,
    description: string,
    url?: string,
    imgUrl?: string
  ) {
    this.title = title;
    this.description = description;
    this.url = url;
    this.imgUrl = imgUrl;
  }
}

export class SEOTemplatePage {
  template: Template;
  profile: Profile;
  localeKey: string;

  constructor(template: Template, profile: Profile, localeKey: string) {
    this.template = template;
    this.profile = profile;
    this.localeKey = localeKey;
  }
}

export class SEOProfilePage {
  profile: Profile;

  constructor(profile: Profile) {
    this.profile = profile;
  }
}

interface TwitterCardTags {
  title: string;
  description?: string;
  imageUrl?: string;
}

interface OpenGraphTags {
  title: string;
  type?: string;
  url?: string;
  image?: string;
  description?: string;
  siteName?: string;
  locale?: string;
  alternateLocale?: string[];
  imageAlt?: string;
  article?: OpenGraphArticleTags;
  profile?: OpenGraphProfileTags;
  product?: OpenGraphProduct;
}

interface OpenGraphArticleTags {
  published_time?: string;
  modified_time?: string;
  expiration_time?: string;
  author?: string[];
  section?: string;
  tag?: string[];
}

interface OpenGraphProfileTags {
  first_name?: string;
  last_name?: string;
  username?: string;
  gender?: string;
}

interface OpenGraphProduct {
  price_amount?: string;
  price_currency?: string;
  availability?: string;
  condition?: string;
  retailer_item_id?: string;
  category?: string;
  brand?: string;
  release_date?: string;
  expiration_date?: string;
  is_downloadable?: string;
  is_preorder?: string;
  product_id?: string;
}

const SEOComponent: React.FC<SEOComponentProps> = ({ seo }) => {
  const getHelmetContent = (): React.FC => {
    if (seo instanceof SEOTemplatePage) {
      return getTemplateHelmetContent(seo);
    } else if (seo instanceof SEOProfilePage) {
      return getProfileHelmetContent(seo);
    } else {
      return getPageHelmetContent(seo);
    }
  };

  const getTemplateHelmetContent = (data: SEOTemplatePage): React.FC => {
    const { template, profile, localeKey: locale } = data;
    const title = `${localizedValueWithKey(template.title, locale)} by ${
      profile?.name
    }${SCREEN_TEMPLATE_SUFFIX}${TAB_TITLE_SUFFIX}`;
    const openGraphTitle = `${
      localizedValueWithKey(template.metaData?.title, locale) ||
      localizedValueWithKey(template.title, locale)
    } by ${profile?.name}${SCREEN_TEMPLATE_SUFFIX}${TITLE_SUFFIX}`;
    const openGraphDescription =
      localizedValueWithKey(template.metaData?.desc, locale)?.[0] ||
      localizedValueWithKey(template.desc, locale) ||
      DEFAULT_DESCRIPTION;
    const keywords =
      localizedValueWithKey(template.metaData?.keywords, locale)?.[0] ||
      NOTION_TEMPLATES_KEYWORDS;

    const metaDataImages: Record<string, string[] | undefined> | null =
      localizedValueWithKey(template.metaData?.images, locale);
    const openGraphImageHref =
      metaDataImages?.[ImageTargetKeys.OPEN_GRAPH]?.[0] ||
      localizedValueWithKey(template.coverImages, locale)?.[0] ||
      ELCOVIA_OPEN_GRAPH;
    const twitterCardHref =
      metaDataImages?.[ImageTargetKeys.TWITTER_CARD]?.[0] ||
      localizedValueWithKey(template.coverImages, locale)?.[0] ||
      ELCOVIA_TWITTER_CARD;

    const twitterCard: TwitterCardTags = {
      title: openGraphTitle,
      description: openGraphDescription,
      imageUrl: twitterCardHref,
    };

    const openGraph: OpenGraphTags = {
      title: openGraphTitle,
      type: "product",
      url: setWebsitePathId(Destination.TEMPLATE_ID_SHORT, template.id || ""),
      image: openGraphImageHref,
      description: openGraphDescription,
      siteName: "Elcovia",
      locale,
      alternateLocale: template.locales || undefined,
      imageAlt: openGraphTitle,
      product: {
        price_amount: `${template.price || 0}`,
        price_currency: "USD",
        availability: "https://schema.org/OnlineOnly",
        brand: profile.name || "Elcovia",
        retailer_item_id: template.id,
        product_id: template.id,
        release_date: template.published?.toDate().toISOString(),
        is_downloadable: "yes",
      },
    };

    return () => (
      <Helmet>
        <link rel="canonical" href={openGraph.url} />
        <title>{title}</title>
        <meta name="description" content={openGraphDescription} />
        <meta name="author" content={profile.name || "Elcovia"} />
        <meta name="keywords" content={keywords} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@elcovia_notion" />
        <meta name="twitter:creator" content="@elcovia_notion" />
        <meta name="twitter:title" content={twitterCard.title} />
        <meta name="twitter:description" content={twitterCard.description} />
        <meta name="twitter:image" content={twitterCard.imageUrl} />
        <meta property="og:type" content={openGraph.type} />
        <meta property="og:url" content={openGraph.url} />
        <meta property="og:title" content={openGraph.title} />
        <meta property="og:description" content={openGraph.description} />
        <meta property="og:image" content={openGraph.image} />
        <meta property="og:site_name" content={openGraph.siteName} />
        <meta property="og:locale" content={openGraph.locale} />
        {openGraph.alternateLocale &&
          openGraph.alternateLocale.map((locale) => (
            <meta
              key={locale}
              property="og:locale:alternate"
              content={locale}
            />
          ))}
        <meta
          property="product:price:amount"
          content={openGraph.product?.price_amount}
        />
        <meta
          property="product:price:currency"
          content={openGraph.product?.price_currency}
        />
        <meta
          property="product:availability"
          content={openGraph.product?.availability}
        />
        <meta property="product:brand" content={openGraph.product?.brand} />
        {SchemaOrgJSONLD({
          "@context": "http://schema.org",
          "@type": "Product",
          name: title,
          url: openGraph.url,
          image: openGraph.image,
          description: openGraph.description,
          sku: template.id,
          brand: { "@type": "Person", name: profile.name || "Elcovia" },
          offers: {
            "@type": "Offer",
            price: template.price || 0,
            priceCurrency: "USD",
            availability: "https://schema.org/OnlineOnly",
          },
        })}
      </Helmet>
    );
  };

  const getProfileHelmetContent = (data: SEOProfilePage): React.FC => {
    const { profile } = data;
    const url = setWebsitePathId(
      Destination.USER_ID_SHORT,
      profile.profileId || profile.id || ""
    );

    const twitterCard: TwitterCardTags = {
      title: profile.name || "Elcovia",
      description: profile.bio || DEFAULT_DESCRIPTION,
      imageUrl: profile.image || ELCOVIA_TWITTER_CARD,
    };

    const openGraph: OpenGraphTags = {
      title: profile.name || "Elcovia",
      type: "profile",
      url,
      image: profile.image || ELCOVIA_OPEN_GRAPH,
      description: profile.bio || DEFAULT_DESCRIPTION,
      siteName: "Elcovia",
      imageAlt: profile.name || "Elcovia",
      profile: { username: profile.profileId || profile.id },
    };

    return () => (
      <Helmet>
        <link rel="canonical" href={url} />
        <title>
          {profile.name}
          {SCREEN_PROFILE_SUFFIX}
          {TAB_TITLE_SUFFIX}
        </title>
        <meta name="description" content={profile.bio || DEFAULT_DESCRIPTION} />
        <meta name="keywords" content={NOTION_PROFILES_KEYWORDS} />
        <meta name="author" content={profile.name || "Elcovia"} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@elcovia_notion" />
        <meta name="twitter:creator" content="@elcovia_notion" />
        <meta name="twitter:title" content={twitterCard.title} />
        <meta name="twitter:description" content={twitterCard.description} />
        <meta name="twitter:image" content={twitterCard.imageUrl} />
        <meta property="og:type" content={openGraph.type} />
        <meta property="og:url" content={openGraph.url} />
        <meta property="og:title" content={openGraph.title} />
        <meta property="og:description" content={openGraph.description} />
        <meta property="og:image" content={openGraph.image} />
        <meta property="og:site_name" content={openGraph.siteName} />
        <meta property="og:locale" content={openGraph.locale} />
        {openGraph.alternateLocale &&
          openGraph.alternateLocale.map((locale) => (
            <meta
              key={locale}
              property="og:locale:alternate"
              content={locale}
            />
          ))}
        <meta property="profile:first_name" content={profile.first_name} />
        <meta property="profile:last_name" content={profile.last_name} />
        <meta property="profile:username" content={profile.username} />
        <meta property="profile:gender" content={profile.gender} />
        {SchemaOrgJSONLD({
          "@context": "http://schema.org",
          "@type": "ProfilePage",
          url,
          mainEntity: {
            "@type": "Person",
            name: profile.name,
            alternateName: profile.profileId,
            identifier: profile.id,
            description: profile.bio,
            image: profile.image,
            sameAs: profile.links,
          },
        })}
      </Helmet>
    );
  };

  const getPageHelmetContent = (data: SEOPage): React.FC => {
    const currentUrl = window.location.href;

    const twitterCard: TwitterCardTags = {
      title: data.title,
      description: data.description,
      imageUrl: data.imgUrl || ELCOVIA_TWITTER_CARD,
    };

    const openGraph: OpenGraphTags = {
      title: data.title,
      type: "website",
      url: data.url || currentUrl,
      image: data.imgUrl || ELCOVIA_OPEN_GRAPH,
      description: data.description,
      siteName: "Elcovia",
      imageAlt: data.title,
    };

    return () => (
      <Helmet>
        <link rel="canonical" href={data.url || currentUrl} />
        <title>{data.title}</title>
        <meta name="description" content={data.description} />
        <meta name="keywords" content={NOTION_GENERAL_KEYWORDS} />
        <meta name="author" content="Elcovia" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@elcovia_notion" />
        <meta name="twitter:creator" content="@elcovia_notion" />
        <meta name="twitter:title" content={twitterCard.title} />
        <meta name="twitter:description" content={twitterCard.description} />
        <meta name="twitter:image" content={twitterCard.imageUrl} />
        <meta property="og:type" content={openGraph.type} />
        <meta property="og:url" content={openGraph.url} />
        <meta property="og:title" content={openGraph.title} />
        <meta property="og:description" content={openGraph.description} />
        <meta property="og:image" content={openGraph.image} />
        <meta property="og:site_name" content={openGraph.siteName} />
        <meta property="og:locale" content={openGraph.locale} />
        {openGraph.alternateLocale &&
          openGraph.alternateLocale.map((locale) => (
            <meta
              key={locale}
              property="og:locale:alternate"
              content={locale}
            />
          ))}
        {SchemaOrgJSONLD({
          "@context": "http://schema.org",
          "@type": "WebPage",
          name: data.title,
          description: data.description,
          url: data.url || currentUrl,
          publisher: {
            "@type": "Organization",
            name: "Elcovia",
            logo: {
              "@type": "ImageObject",
              url: ELCOVIA_LOGO,
            },
          },
          mainEntityOfPage: ExternalLinks.ELCOVIA,
        })}
      </Helmet>
    );
  };

  function SchemaOrgJSONLD(JSONLD: {}): JSX.Element {
    return <script type="application/ld+json">{JSON.stringify(JSONLD)}</script>;
  }

  const HelmetContent = getHelmetContent();
  return <HelmetContent />;
};

export default SEOComponent;
