import {
  CurrencyDollarIcon,
  GlobeAmericasIcon,
  SparklesIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import SEOComponent, {
  SEOPage,
} from "../../../components/common/seo/SEOComponent";
import { TemplatePromotionConfig } from "../../../models/data/payment/TemplatePromotionConfig";
import { Template } from "../../../models/Template";
import { getTemplateById } from "../../../services/firestore-service";
import {
  createPaypalTemplatePromotionOrder,
  createStripeTemplatePromotionOrder,
} from "../../../services/functions-service";
import { getTemplatePromotionConfig } from "../../../services/remote-configs-service";
import { handleStripeResponse } from "../../../services/stripe-service";
import { RootState } from "../../../store";
import {
  ACCOUNT_TEMPLATE_PROMOTION_DESCRIPTION,
  SCREEN_ACCOUNT_TEMPLATE_PROMOTION,
  SCREEN_TITLE,
} from "../../../utils/constants-seo";
import { localizedValue } from "../../../utils/supported-locales";
import {
  durationKeyToLabel,
  timestampToLocalDate,
} from "../../../utils/utils-formatting";
import { Logger } from "../../../utils/utils-logging";
import { classNames } from "../../../utils/utils-react";
import PromotionComponent, { Benefit, Showcase } from "./PromotionComponent";
import { delay } from "lodash";
import { Autoplay, EffectCoverflow, EffectCreative, EffectFlip } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import LoadingScreenComponent from "../../../components/common/loading/LoadingScreenComponent";

const logger = new Logger("TemplatePromotionScreen");

const benefits: Benefit[] = [
  {
    name: "Increased Visibility",
    Icon: GlobeAmericasIcon,
    description: "Boost your template's visibility and reach.",
  },
  {
    name: "Enhanced Recognition",
    Icon: SparklesIcon,
    description: "Stand out from the competition and gain recognition.",
  },
  {
    name: "Attract Enthusiasts",
    Icon: UserGroupIcon,
    description:
      "Attract more Notion enthusiasts for your template and your brand.",
  },
  {
    name: "Revenue Expansion",
    Icon: CurrencyDollarIcon,
    description: "Unlock revenue potential by reaching more customers.",
  },
];

const showcases: Showcase[] = [
  {
    image: process.env.PUBLIC_URL + "/imgs/showcase_template_promotion.png",
    title: "Featured on Discover",
    description:
      "This is an example of how your template will be featured on Elcovia's discover page.",
  },
];

interface TemplatePromotionScreenParams {
  id: string;
  [key: string]: any;
}

const TemplatePromotionScreen: React.FC<
  TemplatePromotionScreenParams
> = ({}) => {
  const { id } = useParams<TemplatePromotionScreenParams>();
  const [template, setTemplate] = React.useState<Template | null>(null);
  const [promotionable, setPromotionable] = React.useState<boolean>(true);
  const [onBlockPayButton, setOnBlockPayButton] = React.useState<boolean>(true);

  const [selectedTemplatePromotionConfig, setSelectedTemplatePromotionConfig] =
    React.useState<TemplatePromotionConfig | null>(null);

  const [templatePromotionConfig, setTemplatePromotionConfig] = React.useState<
    TemplatePromotionConfig[] | null
  >(null);

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);

  /**
   * Set promotionable state.
   */
  React.useEffect(() => {
    if (!template) return;
    setPromotionable(template.promotion == null);
  }, [template]);

  /**
   * Load initial template data.
   */
  React.useEffect(() => {
    if (templatePromotionConfig == null) {
      const fetchTemplatePromotionConfig = async () => {
        try {
          const config = await getTemplatePromotionConfig();
          setTemplatePromotionConfig(config);
          setSelectedTemplatePromotionConfig(config[1]);
          setOnBlockPayButton(false);
          logger.log("fetchTemplatePromotionConfig", {
            config,
          });
        } catch (e) {
          logger.logError("fetchTemplatePromotionConfig", {
            e,
          });
        }
      };
      fetchTemplatePromotionConfig();
    }

    if (template == null) {
      const fetchTemplate = async () => {
        try {
          const template = await getTemplateById(id);

          setTemplate(template);
          logger.log("fetchTemplate", {
            id,
            template,
          });
        } catch (e) {
          logger.logError("fetchTemplate", {
            id,
            e,
          });
        }
      };
      fetchTemplate();
    }
  }, [id]);

  /**
   * Promote template.
   * @param template Template to promote.
   */
  async function promoteTemplate(template: Template) {
    try {
      setOnBlockPayButton(true);
      const templateId = template.id;
      const purchaseKey = selectedTemplatePromotionConfig!!.key;
      // promoteTemplatePayPal(templateId, purchaseKey);
      promoteTemplateStripe(templateId, purchaseKey);
    } catch (error) {
      console.log(error);
    }
    // setOnBlockPayButton(false);
  }

  /**
   * Promote template with Stripe.
   * @param templateId Template ID.
   * @param purchaseKey Purchase key.
   */
  async function promoteTemplateStripe(
    templateId: string,
    purchaseKey: string
  ) {
    const response = await createStripeTemplatePromotionOrder(
      templateId,
      purchaseKey
    );
    await handleStripeResponse(response.data);
  }

  /**
   * Promote template with PayPal.
   * @param templateId Template ID.
   * @param purchaseKey Purchase key.
   */
  async function promoteTemplatePayPal(
    templateId: string,
    purchaseKey: string
  ) {
    const result = await createPaypalTemplatePromotionOrder(
      templateId,
      purchaseKey
    );
    console.log("Result:" + result);
    const jsonResult = JSON.parse(result.data as string);
    const url = jsonResult.links[1].href;
    window.location.href = url;
  }

  if (
    template == null ||
    selectedTemplatePromotionConfig == null ||
    templatePromotionConfig == null
  ) {
    return <LoadingScreenComponent />;
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TITLE + SCREEN_ACCOUNT_TEMPLATE_PROMOTION,
            ACCOUNT_TEMPLATE_PROMOTION_DESCRIPTION
          )
        }
      />

      <PromotionComponent
        title={"Promotion of " + localizedValue(template?.title)}
        promotionable={promotionable}
        price={selectedTemplatePromotionConfig.price}
        currencyCode={selectedTemplatePromotionConfig.currencyCode}
        preTitleChildren={
          <div className="aspect-[16/9] w-120 shadow mx-auto mb-12">
            {(localizedValue(template.coverImages)?.length || 0) > 1 ? (
              <Swiper
                pagination={{ clickable: true }}
                modules={[EffectCreative, Autoplay]}
                navigation={true}
                loop={true}
                className="coverImagesSwiper"
                speed={1000}
                autoplay={{
                  pauseOnMouseEnter: true,
                }}
              >
                {localizedValue(template.coverImages)?.map((image: string) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt={localizedValue(template.title)!!}
                      className="h-full w-full object-cover object-center  lg:h-full lg:w-full rounded-t-md"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <img
                src={localizedValue(template.coverImages)?.[0] || ""}
                alt={localizedValue(template.title)!!}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-lg"
              />
            )}
          </div>
        }
        description="Your template will be featured prominently on Elcovia's website and app discover page, ensuring it's among the first templates users see. It will also receive a promotion indicator for increased visibility and recognition."
        postDescriptionChildren={
          <div>
            {promotionable ? (
              <>
                {/* Duration picker */}
                <div className="mt-12">
                  <div className="mt-1 flex items-center justify-between">
                    <h2 className="text-sm font-medium text-gray-900">
                      Duration
                    </h2>
                  </div>

                  <div className="mt-3">
                    <p className="sr-only">Choose a duration</p>
                    <div className="grid grid-cols-3 gap-4 sm:grid-cols-3">
                      {templatePromotionConfig.map((config) => (
                        <ButtonComponent
                          key={config.key}
                          baseClassNames="w-full"
                          text={durationKeyToLabel(config.duration)}
                          onClick={() =>
                            setSelectedTemplatePromotionConfig(config)
                          }
                          style={
                            selectedTemplatePromotionConfig == config
                              ? "colored"
                              : "standard"
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>

                {/* Divider separator */}
                <div className="border-t border-gray-200 mt-8 pt-6" />

                {/* Buy button */}
                <div className="mt-6 grid ">
                  <ButtonComponent
                    text="Promote"
                    style="structural"
                    baseClassNames="w-64"
                    loading={onBlockPayButton}
                    onClick={() => promoteTemplate(template)}
                    disabled={onBlockPayButton}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mt-12 border-y py-12 border-gray-200">
                  <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                    Your template is{" "}
                    <span className="text-green-500">actively</span> promoted
                  </h3>
                  <p className="mt-4 text-md font-normal text-gray-500 leading-6">
                    Your template is currently being promoted. It will be
                    featured until{" "}
                    <span className="font-semibold">
                      {timestampToLocalDate(template.promotion!.end)}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        }
        benefits={benefits}
        showcases={showcases}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(TemplatePromotionScreen);
