import React from "react";

import { ExternalUrlType } from "../../../../models/ExternalUrlType";
import { Template } from "../../../../models/Template";
import { LocaleDropDownComponent } from "../../../../components/common/dropdowns/LocaleDropDown";
import { CategoryLabel } from "../../../../components/common/CategoryLabel";
import { Category } from "../../../../models/Category";
import {
  appendParamsToPath,
  Destination,
  Args,
} from "../../../../utils/constants-navigation";
import { useNavigate } from "react-router-dom";

interface TemplateFooterSectionProps {
  template: Template;
  locale: string;
  isDiscover: boolean;
  onUpdateLocale: (locale: string) => void;
}

const TemplateFooterSection: React.FC<TemplateFooterSectionProps> = ({
  template,
  locale,
  isDiscover,
  onUpdateLocale,
}) => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8">
      {template.locales.length > 1 && (
        <section>
          <h3 className="text-sm font-medium text-gray-900">
            Available languages
          </h3>
          <LocaleDropDownComponent
            className="mt-6"
            currentLocale={locale}
            locales={template.locales}
            onSelectLocale={(locale) => {
              onUpdateLocale(locale);
            }}
          />
        </section>
      )}

      <div>
        {isDiscover && template.categories.length > 0 && (
          <>
            <h3 className="text-sm font-medium text-gray-900">Categories</h3>
            <div className="mt-6 space-y-3 inline-grid">
              {template?.categories
                ?.map((key) =>
                  Object.values(Category).find((c) => c.key === key)
                )
                .filter(Boolean)
                .filter((category) => category != null)
                .map((category) => (
                  <CategoryLabel
                    key={category?.key || ""}
                    content={category!!}
                    onClick={() => {
                      navigate(
                        appendParamsToPath(Destination.TEMPLATES, [
                          { key: Args.SORT, value: "views" },
                          { key: Args.TIME, value: "month" },
                          {
                            key: Args.CATEGORY,
                            value: encodeURIComponent(category?.title || ""),
                          },
                        ])
                      );
                    }}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TemplateFooterSection;
