export type LocaleKey = string;

/**
 * A locale is a combination of a language and a country
 * @see https://docs.oracle.com/javase/8/docs/api/java/util/Locale.html
 */
interface Locale {
  language: string;
  country?: string;
}

/**
 * A supported locale
 */
export interface SupportedLocale {
  locale: Locale;
}

/**
 * A map of supported locales
 */
export const SupportedLocales: Record<string, SupportedLocale> = {
  // ENGLISH: { locale: { language: "en" } },
  ENGLISH_US: { locale: { language: "en", country: "US" } },
  // ENGLISH_AUSTRALIA: { locale: { language: "en", country: "AU" } },
  CHINESE_TRADITIONAL: { locale: { language: "zh", country: "TW" } },
  CHINESE_SIMPLE: { locale: { language: "zh", country: "CN" } },
  GERMAN: { locale: { language: "de", country: "DE" } },
  ITALIAN: { locale: { language: "it", country: "IT" }, },
  FRENCH: { locale: { language: "fr", country: "FR" } },
  GREEK: { locale: { language: "el", country: "GR" } },
  JAPANESE: { locale: { language: "ja", country: "JP" } },
  KOREAN: { locale: { language: "ko", country: "KR" } },
  RUSSIAN: { locale: { language: "ru", country: "RU" } },
  TURKISH: { locale: { language: "tr", country: "TR" } },
  DUTCH: { locale: { language: "nl", country: "NL" } },
  PORTUGUESE_BRAZIL: { locale: { language: "pt", country: "BR" } },
  PORTUGUESE_PORTUGAL: { locale: { language: "pt", country: "PT" } },
  SPANISH_LATIN_AMERICA: { locale: { language: "es", country: "419" } },
  SPANISH_SPAIN: { locale: { language: "es", country: "ES" } },
  VIETNAMESE: { locale: { language: "vi", country: "VN" } },
  THAI: { locale: { language: "th", country: "TH" } },
};


/**
 * The default locale
 */
export const DEFAULT_LOCALE: SupportedLocale = SupportedLocales.ENGLISH_US;

export function fromKey(key: LocaleKey): SupportedLocale | undefined {
  return Object.values(SupportedLocales).find((locale) => localeKey(locale) === key);
}

export function localeKey(supportedLocale: SupportedLocale): LocaleKey {
  const { language, country } = supportedLocale.locale;
  return country ? `${language}-${country}` : language;
}

function emojiFlag(supportedLocale: SupportedLocale): string | null {
  const { country } = supportedLocale.locale;
  if (!country) return null;
  return [...country].map((c) => String.fromCodePoint(c.charCodeAt(0) - 0x41 + 0x1F1E6)).join('');
}

export function currentSupportedLocale(): SupportedLocale {
  const languageTag = new Intl.Locale(navigator.language).toString();
  return Object.values(SupportedLocales).find((locale) => localeKey(locale) === languageTag) || DEFAULT_LOCALE;
}

export function currentLocaleKey(): LocaleKey {
  return localeKey(currentSupportedLocale());
}

export type LocalizedMap<T> = Record<LocaleKey, T>;

export function localizedValue<T>(localizedMap: LocalizedMap<T> | null | undefined): T | null {
  if (!localizedMap) return null;
  const key = localeKey(currentSupportedLocale());
  return localizedMap[key] || localizedMap[Object.keys(localizedMap)[0]];
}

export function localizedValueWithKey<T>(localizedMap: LocalizedMap<T> | null | undefined, localizedKey: LocaleKey): T | null {
  if (!localizedMap) return null;
  return localizedMap[localizedKey] || null;
}

export function localizedValueWithLocale<T>(localizedMap: LocalizedMap<T>, supportedLocale: SupportedLocale): T | undefined {
  return localizedMap[localeKey(supportedLocale)];
}


export function getLocaleDisplayName(locale: SupportedLocale): string | undefined {
  const key = localeKey(locale)
  const displayNames = new Intl.DisplayNames([key], { type: 'language' });
  return displayNames.of(key);
}

export function getLocaleDisplayNameLanguage(locale: SupportedLocale): string | undefined {
  let key = localeKey(locale)
  if (key.includes('-')) {
    key = key.split('-')[0];
  }
  const displayNames = new Intl.DisplayNames([key], { type: 'language' });
  return displayNames.of(key);
}

export function getLocaleDisplayNameLanguageByKey(locale: string): string | undefined {
  let key = locale;
  if (key.includes('-')) {
    key = key.split('-')[0];
  }
  const displayNames = new Intl.DisplayNames([key], { type: 'language' });
  return displayNames.of(key);
}