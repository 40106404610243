import React, { Fragment, MouseEventHandler, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RootState } from "../../../../store";
import { classNames } from "../../../../utils/utils-react";

interface HeaderProfileComponentProps {
  origin: string;
  navigationGroups: NavigationGroup[];
  bottomNavigation: NavigationItem[];
}

export type CustomNavLinkProps = {
  item: NavigationItem;
  children?: React.ReactNode;
};

export type NavigationItem = {
  name: string;
  href?: string;
  icon: any;
  external?: boolean;
};

export type NavigationGroup = {
  name: string;
  items: NavigationItem[];
};

export const SidebarItem: React.FC<CustomNavLinkProps> = ({
  item,
  children,
}) => {
  const location = useLocation();
  const isActive = !item.external && location.pathname.startsWith(item.href!!);
  return (
    <a
      key={item.name}
      href={item.href}
      className={classNames(
        isActive
          ? "bg-gray-100/50 text-indigo-600 font-medium"
          : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
        "group flex gap-x-6 rounded-xl py-1 ml-3 pr-3 items-center transition-colors duration-200 ease-in-out"
      )}
    >
      <div
        className={classNames(
          isActive ? " text-white" : "bg-gray-100",
          "p-3 rounded-xl group-hover:bg-transparent justify-center items-centered transition-colors duration-200 ease-in-out"
        )}
      >
        <item.icon
          className={classNames(
            isActive
              ? "text-indigo-600"
              : "text-gray-600 group-hover:text-indigo-600",
            "h-4 w-4 shrink-0 transition-colors duration-200 ease-in-out"
          )}
          aria-hidden="true"
        />
      </div>
      <p
        className={classNames(
          isActive
            ? "text-indigo-600 font-semibold"
            : "text-gray-600 group-hover:text-indigo-600 font-normal",
          "text-sm transition-colors duration-200 ease-in-out"
        )}
      >
        {children}
      </p>
    </a>
  );
};

const DashboardSidebarComponent: React.FC<HeaderProfileComponentProps> = ({
  origin,
  navigationGroups,
  bottomNavigation,
}) => {
  return (
    <div className="hidden md:fixed mt-4 lg:inset-y-0 lg:z-20 lg:flex lg:w-60 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 h-screen">
        <div className="flex h-16 shrink-0 items-center gap-x-4"></div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            {navigationGroups.map((group) => (
              <li>
                <div className="font-semibold text-sm text-gray-900 line-clamp-1">
                  {group.name}
                </div>
                <ul role="list" className="-ml-3 mt-3 space-y-1">
                  {group.items.map((item) => (
                    <li key={origin + item.name}>
                      <SidebarItem item={item} children={item.name} />
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <ul role="list-bottom" className="-ml-3 mt-6 space-y-1">
            {bottomNavigation.map((item) => (
              <li key={origin + item.name}>
                <SidebarItem item={item} children={item.name} />
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(DashboardSidebarComponent);
