import { Dialog, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { profile } from "console";
import React from "react";
import { Fragment } from "react";

import { Profile } from "../../../models/Profile";
import {
  isProfileIdAvailable,
  updateProfileId,
} from "../../../services/firestore-service";
import { Logger } from "../../../utils/utils-logging";
import ButtonComponent from "../buttons/ButtonComponent";
import TextFieldComponent from "../input/TextFieldComponent";

const logger = new Logger("ProfileIdDialog");

interface ProfileIdDialogParams {
  show: boolean;
  profile: Profile;
  onFinish: (profileId: string) => void;
  onCancel: () => void;
}

export const ProfileIdDialog: React.FC<ProfileIdDialogParams> = ({
  show,
  profile,
  onFinish,
  onCancel,
}) => {
  const [changeAllowed, setChangeAllowed] = React.useState<boolean | null>(
    null
  );
  const [changeAllowedDate, setChangeAllowedDate] = React.useState<Date | null>(
    null
  );

  const [profileIdInvalid, setProfileIdInvalid] = React.useState<
    boolean | null
  >(false);
  const [profileIdAvailable, setProfileIdAvailable] = React.useState<
    boolean | null
  >(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [nameId, setNameId] = React.useState<string>("");

  const cancelButtonRef = React.useRef(null);

  React.useEffect(() => {
    if (profile) {
      setProfileIdAvailable(null);
      setProfileIdInvalid(null);
      initCheckAllowed();
    }
  }, [profile]);

  /**
   * Checks whether the user is allowed to change the profile ID.
   * @returns {boolean} Whether the user is allowed to change the profile ID.
   */
  function initCheckAllowed() {
    // Check difference between last change and now, if a month then valid
    let date: Date | null = null;
    if (profile.lastIdChange) {
      date = new Date(profile.lastIdChange.seconds * 1000);
      date.setDate(date.getDate() + 30);
      setChangeAllowedDate(date || null);
    }

    const isAllowed =
      profile.profileId == null ||
      profile.lastIdChange == null ||
      date == null ||
      date < new Date();
    setChangeAllowed(isAllowed);
    logger.log("isChangeAllowed", {
      isAllowed,
      profileId: profile.profileId,
      lastIdChange: profile.lastIdChange,
    });
  }

  function isProfileIdValid(profileId: string | null): boolean {
    const regexPattern: RegExp = /^[a-z0-9._]{3,15}$/;
    return profileId !== null ? regexPattern.test(profileId) : false;
  }

  /**
   * Checks whether the profile ID is available.
   */
  async function check() {
    setLoading(true);

    const isValid = isProfileIdValid(nameId);
    setProfileIdInvalid(!isValid);

    if (isValid) {
      const isAvailable = await isProfileIdAvailable(nameId);
      setProfileIdAvailable(isAvailable);
      setLoading(false);
    } else {
      setLoading(false);
      setProfileIdAvailable(null);
    }
  }

  /**
   * Claims the profile ID.
   */
  async function claimProfileId() {
    check();
    if (!profileIdAvailable) {
      return;
    }
    try {
      setLoading(true);
      await updateProfileId(profile.id, profile.profileId, nameId);
      setProfileIdAvailable(null);
      setProfileIdInvalid(null);
      setLoading(false);
      onFinish(nameId);
    } catch (error) {
      setLoading(false);
      check();
      logger.logError("claimProfileId", error);
    }
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => onCancel && onCancel()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-elcovia-100 sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    <UserCircleIcon
                      className={`h-6 w-6 text-elcovia-600`}
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      User name
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="font-normal text-sm text-gray-600">
                        To ensure consistency, you can only change your user
                        name once a month. Choose a user name that will make
                        your profile immediately recognizable to potential
                        customers.
                      </p>
                      {!changeAllowed && changeAllowedDate && (
                        <>
                          <p className="mt-3 text-xs text-gray-500">
                            {`You can change your user name again on ${changeAllowedDate.toLocaleDateString()}.`}
                          </p>
                        </>
                      )}

                      <TextFieldComponent
                        disabled={!changeAllowed}
                        additionalClassName="mt-6"
                        placeholder={profile.profileId || "User name"}
                        value={nameId}
                        minLength={3}
                        maxLength={30}
                        count={changeAllowed == true}
                        onChange={(value) => {
                          setProfileIdInvalid(null);
                          setProfileIdAvailable(null);
                          setNameId(value?.target.value);
                        }}
                      />

                      {profileIdInvalid == true ? (
                        <p className="mt-3 text-xs text-red-500">
                          A valid user name should be 3 to 30 characters long
                          and contain only lowercase letters (a-z), digits
                          (0-9), periods (.), or underscores (_).
                        </p>
                      ) : (
                        <></>
                      )}
                      {profileIdAvailable == true && (
                        <p className="mt-3 text-xs text-green-500">
                          This user name is available. Claim it now!
                        </p>
                      )}

                      {profileIdAvailable == false && (
                        <p className="mt-3 text-xs text-red-500">
                          This user name is already used.
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-8 sm:flex sm:flex-row-reverse gap-x-3">
                  {!changeAllowed ? (
                    <ButtonComponent
                      style="structural-text"
                      parentClassNames="inline-block"
                      text="Close"
                      onClick={() => onCancel && onCancel()}
                    />
                  ) : (
                    <>
                      {profileIdAvailable ? (
                        <ButtonComponent
                          disabled={loading}
                          style="structural"
                          parentClassNames="inline-block"
                          text="Use"
                          onClick={() => claimProfileId()}
                        />
                      ) : (
                        <ButtonComponent
                          disabled={loading}
                          style="structural"
                          parentClassNames="inline-block"
                          text="Check"
                          onClick={() => check()}
                        />
                      )}
                      <ButtonComponent
                        style="structural-text"
                        parentClassNames="inline-block"
                        text="Cancel"
                        onClick={() => onCancel && onCancel()}
                      />
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
