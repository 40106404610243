import {
  CircleStackIcon,
  Cog6ToothIcon,
  FolderOpenIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { Destination } from "../../utils/constants-navigation";
import DashboardComponent, {
  NavigationItem,
  NavigationGroup,
} from "../../components/common/common/DashboardComponent";

interface AdminDashboardPageProps {
  title?: string;
  titleView?: React.ReactNode;
  children: React.ReactNode;
}

const AdminPage: React.FC<AdminDashboardPageProps> = ({
  title,
  titleView,
  children,
}) => {
  const navigation: NavigationItem[] = [
    {
      name: "Meta Data",
      href: Destination.ADMIN_META_DATA,
      icon: FolderOpenIcon,
    },
    {
      name: "Data Export",
      href: Destination.ADMIN_DATA_EXPORT,
      icon: CircleStackIcon,
    },
  ];

  const bottomNavigation: NavigationItem[] = [
    {
      name: "Settings",
      href: Destination.ACCOUNT_SETTINGS,
      icon: Cog6ToothIcon,
    },
  ];

  const navigationExternal: NavigationItem[] = [
    {
      name: "Dashboard",
      href: Destination.ACCOUNT_DASHBOARD,
      icon: UserCircleIcon,
      external: true,
    },
  ];

  const navigationGroups: NavigationGroup[] = [
    {
      name: "Content",
      items: navigation,
    },
    {
      name: "Other",
      items: navigationExternal,
    },
  ];

  return (
    <DashboardComponent
      key="admin"
      title={title}
      titleView={titleView}
      navigationGroups={navigationGroups}
      bottomNavigation={bottomNavigation}
      children={children}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(AdminPage);
