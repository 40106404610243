import React from "react";

import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import SEOComponent, {
  SEOPage,
} from "../../../components/common/seo/SEOComponent";
import {
  AFFILIATE_GUMROAD_GUIDE_DESCRIPTION,
  SCREEN_AFFILIATE_GUMROAD_GUIDE,
  SCREEN_TITLE,
} from "../../../utils/constants-seo";
import { classNames } from "../../../utils/utils-react";

type Info = {
  label: string;
  value: string;
};

type Action = {
  title: string;
  link: string;
};

type Step = {
  name: string;
  description: string;
  imageSrc: string;
  imageRatio?: string;
  infos?: Info[];
  actions?: Action[];
};

const steps: Step[] = [
  {
    name: "Add Affiliate",
    description: `Visit your Gumroad Affiliate Page and add Elcovia as an affiliate. Click the "Add affiliate" button on the top right.`,
    actions: [
      {
        title: "Gumroad Affiliate Page",
        link: "https://app.gumroad.com/affiliates",
      },
    ],
    imageRatio: "aspect-w-5 aspect-h-2",
    imageSrc:
      process.env.PUBLIC_URL +
      "/affiliate/gumroad/affiliate_gumroad_guide_1.png",
  },
  {
    name: "Setup Affiliate",
    description:
      "Setup Elcovia as an Affiliate by entering the email address and percentage fee. You can set the fee for each template individually or select all templates and set the fee for all templates at once.",
    infos: [
      {
        label: "Email",
        value: "elcovia@gmail.com",
      },
      {
        label: "Affiliate Fee",
        value: "10%",
      },
    ],
    imageSrc:
      process.env.PUBLIC_URL +
      "/affiliate/gumroad/affiliate_gumroad_guide_2.png",
  },
  {
    name: "Affiliate Added",
    description:
      "You will see Elcovia as an affiliate in your Gumroad Affiliate Page. Click on the entry to see the products details.",
    imageRatio: "aspect-w-5 aspect-h-2",
    imageSrc:
      process.env.PUBLIC_URL +
      "/affiliate/gumroad/affiliate_gumroad_guide_3.png",
  },
  {
    name: "Use Affiliate Links",
    description:
      "In the product details you can find the affiliate link for each product. Make sure to only use these affilate links for Elcovia.",
    imageSrc:
      process.env.PUBLIC_URL +
      "/affiliate/gumroad/affiliate_gumroad_guide_4.png",
  },
];

export default function AffiliateGumroadGuideScreen() {
  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            SCREEN_TITLE + SCREEN_AFFILIATE_GUMROAD_GUIDE,
            AFFILIATE_GUMROAD_GUIDE_DESCRIPTION
          )
        }
      />
      <div>
        <div className="mx-auto max-w-7xl px-6 py-6 sm:py-24 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Affiliate Guide for Gumroad Creators
            </h2>
            <p className="mt-4 text-gray-500">
              This guide will show you how to add Elcovia as an affiliate on
              your Gumroad Affiliate Page.
            </p>
          </div>
          <div className="mt-24 space-y-16">
            {steps.map((step, stepIdx) => (
              <div
                key={step.name}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-12"
              >
                <div
                  className={classNames(
                    stepIdx % 2 === 0
                      ? "lg:col-start-1"
                      : "lg:col-start-8 xl:col-start-9",
                    "mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4"
                  )}
                >
                  <h3 className="text-lg font-medium text-gray-900">
                    {step.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {step.description}
                  </p>
                  <div className="mt-8 space-y-4 text-center">
                    {step.infos &&
                      step.infos.map((info) => (
                        <div
                          key={info.label}
                          className="flex flex-col bg-gray-100 rounded-lg py-4 px-3"
                        >
                          <p className="text-lg font-bold tracking-tight text-indigo-500">
                            {info.value}
                          </p>
                          <p className="text-xs font-normal text-gray-600">
                            {info.label}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div className="mt-4 space-y-4">
                    {step.actions &&
                      step.actions.map((action) => (
                        <ButtonComponent
                          key={action.title}
                          target="_blank"
                          style="redirect"
                          rel="noopener noreferrer"
                          href={action.link}
                          baseClassNames="w-full"
                          text={action.title}
                        />
                      ))}
                  </div>
                </div>
                <div
                  className={classNames(
                    stepIdx % 2 === 0
                      ? "lg:col-start-6 xl:col-start-5"
                      : "lg:col-start-1",
                    "flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8"
                  )}
                >
                  <div
                    className={classNames(
                      step.imageRatio || "aspect-video",
                      "overflow-hidden rounded-lg shadow-md bg-gray-100"
                    )}
                  >
                    <img
                      src={step.imageSrc}
                      className="object-cover object-top"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
