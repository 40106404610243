import React from "react";
import { NavLink } from "react-router-dom";
import { Profile } from "../../models/Profile";
import {
  setPathId,
  Destination,
  setPathIdAndAppend,
} from "../../utils/constants-navigation";
import { getProfileId } from "../../utils/utils-profile";
import { ARGS_PARAM_DISCOVER } from "../../utils/constants-navigation";

interface ProfileMentionComponentProps {
  profile: Profile;
  isDiscover?: boolean;
}

const ProfileMentionComponent: React.FC<ProfileMentionComponentProps> = ({
  profile,
  isDiscover = false,
}) => {
  return (
    <NavLink
      to={setPathIdAndAppend(
        Destination.USER_ID_SHORT,
        getProfileId(profile),
        isDiscover ? ARGS_PARAM_DISCOVER : undefined
      )}
      key={profile.profileId}
    >
      <div className="group ease-in-out duration-300 inline-block">
        <div className="flex items-center inline-block align-middle gap-x-3">
          <img
            src={profile.image}
            alt={profile.name + " on Elcovia" || ""}
            className="shrink-0 w-10 h-10 rounded-md"
          />
          <h3 className="mr-3 text-md font-medium text-gray-700">
            {profile.name}
          </h3>
        </div>
      </div>
    </NavLink>
  );
};

export default ProfileMentionComponent;
