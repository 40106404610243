import React from "react";
import { signupToMailchimpList } from "../../services/functions-service";
import { Destination } from "../../utils/constants-navigation";
import TextFieldComponent from "../common/input/TextFieldComponent";
import ButtonComponent from "../common/buttons/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function NewsletterComponent() {
  const [sucess, setSucess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  /**
   * Handle form submit event.
   */
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setError(false);
    setSucess(false);
    try {
      await signupToMailchimpList(name, email);
      setError(false);
      setSucess(true);
    } catch (error) {
      console.error("Subscription failed", error);
      setError(true);
      setSucess(false);
    }
  };

  return (
    <div className="w-full transition-all ease-in-out duration-300 group relative bg-white rounded-lg border-t border-1 border-gray-200 px-8 py-12">
      <div className="mx-auto max-w-7xl ">
        <h2 className="text-3xl font-bold text-gray-900">
          Elcovia - Templates, Tips, and More!
        </h2>
        <p className="mt-4 text-md font-normal text-gray-600 max-w-4xl leading-7">
          Discover Notion's potential with our templates, both free and premium.
          Stay current with updates, tips, and tricks. We'll regularly showcase
          the top-ranked Notion Templates—weekly, monthly, yearly, and all-time
          favorites, based on views, likes, and downloads. Join the Elcovia
          community now!
        </p>
        <div className="w-full max-w-lg mt-6" />
        {sucess ? (
          <p className="mt-6 text-md font-normal text-gray-600">
            You are now <span className="text-indigo-500">subscribed</span>.
          </p>
        ) : (
          <form className="mt-10 max-w-3xl" onSubmit={handleSubmit}>
            <div className="flex gap-x-4">
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              <TextFieldComponent
                id="name"
                name="name"
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <TextFieldComponent
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <ButtonComponent
                style="structural"
                type="submit"
                text="Subscribe"
              />
            </div>
            {error && (
              <p className="mt-6 text-md font-normal text-red-500">
                There was an error subscribing to the newsletter. Please try it
                again.
              </p>
            )}

            <p className="mt-4 text-xs font-normal text-gray-500">
              We care about your data. Read our{" "}
              <a href={Destination.PRIVACY_POLICY} className="text-indigo-600">
                privacy&nbsp;policy
              </a>
              .
            </p>
          </form>
        )}
      </div>
    </div>
  );
}
