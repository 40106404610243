import {
  AcademicCapIcon,
  CheckBadgeIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";

import { Profile } from "../../models/Profile";
import { Destination, setPathId } from "../../utils/constants-navigation";

interface FeatureProfileItemProps {
  itemKey?: string;
  profile: Profile;
}

export const FeatureProfileItemComponent: React.FC<FeatureProfileItemProps> = ({
  itemKey,
  profile,
}) => {
  return (
    <Link
      to={setPathId(Destination.USER_ID_SHORT, profile.profileId || profile.id)}
      key={itemKey + "-" + profile.id}
    >
      <div className="flex justify-center transition-all ease-in-out duration-300 group relative bg-white divide- divide-solid rounded-lg drop-shadow hover:drop-shadow-lg hover:scale-105">
        <div className="w-full bg-indigo-500/[0.025] justify-center p-4">
          <img
            src={profile.image}
            alt={profile.name || ""}
            className="h-12 w-12 rounded-full shadow-md m-auto"
          />
        </div>
        <div className="pl-4 pr-4 w-full">
          <div className="w-full h-full flex flex-inline items-center">
            <p className="text-base font-semibold text-gray-900 line-clamp-1 md:line-clamp-none">
              {profile.name}{" "}
            </p>
            <div className="flex ml-2 gap-x-2">
              {profile.verified == true && (
                <CheckBadgeIcon className="text-indigo-600 h-4 w-4" />
              )}
              {profile.notionCertified == true && (
                <AcademicCapIcon className="text-gray-600 h-4 w-4" />
              )}
              {profile.promotion != null && (
                <SparklesIcon className="text-gray-600 h-4 w-4" />
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
