import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Template } from "../../../models/Template";
import { IMAGE_COVER_PLACEHOLDER } from "../../../utils/constants";
import { Destination, setPathId } from "../../../utils/constants-navigation";
import { localizedValue } from "../../../utils/supported-locales";
import { timestampToLocalDate } from "../../../utils/utils-formatting";
import HtmlShortTextComponent from "../../common/html/preview_short/HtmlShortTextComponent";
import TemplateIndicatorsItemComponent from "../TemplateIndicatorsItem";

interface DashboardTemplateItemProps {
  itemKey?: string;
  template: Template;
  showStatus?: boolean;
}

const DashboardTemplateItem: React.FC<DashboardTemplateItemProps> = ({
  itemKey,
  template,
  showStatus,
}) => {
  const isStatusVisible = showStatus == true;
  return (
    <Link
      to={setPathId(Destination.ACCOUNT_TEMPLATES_ID, template.id)}
      key={itemKey + "-" + template.id}
    >
      <>
        <div className="transition-all ease-in-out duration-300 group relative bg-white divide-y divide-solid divide-gray-200/50 shadow-sm rounded-lg border border-1 border-gray-200/50 hover:shadow-md hover:md:scale-[1.02]">
          <div className="bg-indigo-500/[0.025] w-full aspect-[16/9]">
            {(localizedValue(template.coverImages)?.length || 0) > 1 ? (
              <Swiper
                effect={"coverflow"}
                pagination={{ clickable: true }}
                modules={[EffectCoverflow, Autoplay]}
                navigation={true}
                loop={true}
                className="coverImagesSwiper"
                autoplay={{ delay: 8000 }}
              >
                {localizedValue(template.coverImages)?.map((image: string) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt={localizedValue(template.title)!!}
                      className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-lg"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <>
                <img
                  src={
                    localizedValue(template.coverImages)?.[0] ||
                    IMAGE_COVER_PLACEHOLDER
                  }
                  alt={localizedValue(template.title)!!}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-lg"
                />
              </>
            )}
          </div>
          <div>
            <div className="pt-4 pb-4 ml-4 mr-4">
              <p className="text-md font-semibold text-gray-900 line-clamp-1 md:line-clamp-none">
                {localizedValue(template.title)}
              </p>
            </div>
          </div>
        </div>
        <p className="mt-3 mx-1 flex items-center">
          {template.published && (
            <>
              <span className="inline-flex items-center text-xs font-medium text-gray-500">
                Published - {timestampToLocalDate(template.published)}
              </span>
            </>
          )}
          {template.published == null && template.created && (
            <>
              <span className="inline-flex items-center text-xs font-medium text-gray-500">
                Created - {timestampToLocalDate(template.created)}
              </span>
            </>
          )}
          <div className="ml-auto">
            <TemplateIndicatorsItemComponent template={template} />
          </div>
        </p>
      </>
    </Link>
  );
};

export default DashboardTemplateItem;
