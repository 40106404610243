import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";

import { Category, CategoryType } from "../../../models/Category";
import { TemplateFeature } from "../../../models/TemplateFeature";
import EmptyButtonStateComponent from "../state/EmptyButtonState";
import { PlusCircleIcon, SquaresPlusIcon } from "@heroicons/react/20/solid";
import { title } from "process";
import _ from "lodash";
import {
  TEMPLATE_FEATURE_TITLE_MIN_LENGTH,
  TEMPLATE_FEATURE_TITLE_MAX_LENGTH,
  TEMPLATE_FEATURE_DESCRIPTION_MAX_LENGTH,
  TEMPLATE_FEATURE_DESCRIPTION_MIN_LENGTH,
} from "../../../utils/constants";
import ImageItemComponent from "../../template/account/ImageItem";
import { template } from "lodash";
import { uploadFileToTemplate } from "../../../services/storage-service";
import TextFieldComponent from "../input/TextFieldComponent";
import TextAreaComponent from "../input/TextAreaComponent";
import { DialogComponent } from "./DialogComponent";
import { classNames } from "../../../utils/utils-react";
import SettingsGroupComponent from "../settings/SettingsGroupComponent";
import { Logger } from "../../../utils/utils-logging";

const logger = new Logger("FeatureEditDialog");

class FeatureErrorState {
  title: boolean;
  desc: boolean;
  image: boolean;

  constructor() {
    this.title = false;
    this.desc = false;
    this.image = false;
  }
}

export type FeatureData = {
  feature: TemplateFeature | null;
  index: number | null;
};

interface FeatureEditDialogParams {
  show: boolean;
  uid: string;
  templateId: string;
  featureData: FeatureData;
  onSave: (data: FeatureData) => void;
  onClose: () => void;
  onDelete: (data: FeatureData) => void;
}

export const FeatureEditDialog: React.FC<FeatureEditDialogParams> = ({
  show,
  uid,
  templateId,
  featureData,
  onSave,
  onClose,
  onDelete,
}) => {
  const [editFeature, setEditFeature] = React.useState<TemplateFeature>(
    featureData?.feature || new TemplateFeature("", "", "")
  );
  const [errors, setErrors] = React.useState<FeatureErrorState>(
    new FeatureErrorState()
  );
  const [changed, setChanged] = React.useState<boolean>(false);
  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const titleRef = React.useRef<HTMLInputElement>(null);
  const descRef = React.useRef<HTMLTextAreaElement>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (!show) return;
    setErrors(new FeatureErrorState());
    setEditFeature(featureData?.feature || new TemplateFeature("", "", ""));
    setUploadError(null);
    logger.log("show", {
      show,
      featureData,
      editFeature,
    });
  }, [show]);

  /**
   * Saves the selection and closes the dialog
   */
  function saveSelection() {
    if (!editFeature) return;
    logger.log("saveSelection", {
      editFeature,
      featureData,
    });
    onSave({ feature: editFeature, index: featureData.index });
    closeDialog();
  }

  /**
   * Deletes the selection and closes the dialog
   */
  function deleteSelection() {
    onDelete(_.cloneDeep(featureData));
    closeDialog();
  }

  /**
   * Closes the dialog.
   */
  function closeDialog() {
    onClose();
  }

  /**
   * Handle input change events.
   */
  function handleInputChangeEvents(
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const { name, value } = event.target;
    handleLocalizedInputChange(name, value);
  }

  /**
   * Handle input change event.
   */
  function handleInputChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    handleLocalizedInputChange(name, value);
  }

  /**
   * Handle input change.
   * @param name input name.
   * @param value input value.
   */
  const handleLocalizedInputChange = (name: string, value: any) => {
    if (!editFeature) return;
    setChanged(true);

    if (name === "title") {
      editFeature.title = value;
    } else if (name === "desc") {
      editFeature.desc = value;
    } else if (name === "image") {
      editFeature.image = value;
    }
    setEditFeature(_.cloneDeep(editFeature));

    const currentErrors = {
      ...errors,
      [name]: validateInput(name, value),
    };
    setErrors(currentErrors);
  };

  function validateInput(name: any, value: any) {
    switch (name) {
      case "title":
        return (
          value == undefined ||
          value.length < TEMPLATE_FEATURE_TITLE_MIN_LENGTH ||
          value.length > TEMPLATE_FEATURE_TITLE_MAX_LENGTH
        );
      case "desc":
        return (
          value == undefined ||
          value.length < TEMPLATE_FEATURE_DESCRIPTION_MIN_LENGTH ||
          value.length > TEMPLATE_FEATURE_DESCRIPTION_MAX_LENGTH
        );
      case "image":
        const image = value as any;
        return image == undefined || image == null || image.length == 0;
      default:
        return false;
    }
  }

  /**
   * Handle thumbnail image upload.
   */
  const handleFeatureImageUpload = (event: any) => {
    setUploadError(null);
    console.log(event.target.files);
    const file = event.target.files[0];
    if (file && file.type.includes("image")) {
      const featureImage = new Image();
      featureImage.onload = async () => {
        const targetWidth = 1200;
        const targetHeight = 900;
        const targetRatio = targetWidth / targetHeight;
        const targetSize = 3 * 1024 * 1024; // 3 MB
        const ratio = featureImage.width / featureImage.height;
        if (ratio != targetRatio) {
          setUploadError("Image ratio does not meet the minimum requirements.");
        } else if (file.size > targetSize) {
          setUploadError("File size exceeds the maximum limit.");
        } else if (
          featureImage.width < targetWidth ||
          featureImage.height < targetHeight
        ) {
          setUploadError(
            "Image resolution does not meet the minimum requirements."
          );
        } else {
          const result = await uploadFileToTemplate(
            uid,
            file,
            templateId,
            true,
            true
          );
          if (result) {
            const featureImageUrl = result as string;
            console.log(featureImageUrl);
            handleLocalizedInputChange("image", featureImageUrl);
          } else {
            setUploadError("Failed to upload file.");
          }
        }
      };
      featureImage.src = URL.createObjectURL(file);
    }
    fileInputRef.current.value = "";
  };

  const isFormValid = () => {
    return (
      !errors.title &&
      !errors.desc &&
      !errors.image &&
      editFeature.title.trim().length >= TEMPLATE_FEATURE_TITLE_MIN_LENGTH &&
      editFeature.title.trim().length <= TEMPLATE_FEATURE_TITLE_MAX_LENGTH &&
      editFeature.desc.trim().length >=
        TEMPLATE_FEATURE_DESCRIPTION_MIN_LENGTH &&
      editFeature.desc.trim().length <=
        TEMPLATE_FEATURE_DESCRIPTION_MAX_LENGTH &&
      editFeature.image.trim().length > 0 &&
      editFeature != featureData.feature
    );
  };

  return (
    <>
      <DialogComponent
        content={
          <div className="flex gap-x-4">
            <div className="w-6/12">
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFeatureImageUpload}
                style={{ display: "none" }}
              />
              <div>
                {editFeature.image ? (
                  <ImageItemComponent
                    interactive={false}
                    key={editFeature.image}
                    ratio="3/4"
                    onClick={() => fileInputRef.current?.click()}
                    url={editFeature.image}
                  />
                ) : (
                  <EmptyButtonStateComponent
                    icon={PlusCircleIcon}
                    onClick={() => fileInputRef.current?.click()}
                    error={uploadError != null || errors.image}
                    ratio="4/3"
                    text="Add Image"
                    details="Min. 900x1200px, max. 3 MB"
                  />
                )}
                {uploadError && (
                  <p className="mt-2 text-xs text-red-500">{uploadError}</p>
                )}
              </div>
            </div>
            <div className="space-y-4 w-96">
              <SettingsGroupComponent
                title="Feature"
                description="Introduce a core functionality, or special feature of your product."
                content={
                  <div className="space-y-4">
                    <div>
                      <TextFieldComponent
                        type="text"
                        name="title"
                        placeholder="Title of the feature"
                        onChange={handleInputChangeEvent}
                        value={editFeature.title}
                        ref={titleRef}
                      />
                      {errors.title && (
                        <p className="mt-2 text-xs text-red-500 text-left">
                          Title must be between{" "}
                          {TEMPLATE_FEATURE_TITLE_MIN_LENGTH}‎ and ‎
                          {TEMPLATE_FEATURE_TITLE_MAX_LENGTH}‎ characters long.
                        </p>
                      )}
                    </div>
                    <div>
                      <TextAreaComponent
                        onChange={handleInputChangeEvents}
                        rows={4}
                        name="desc"
                        additionalClassName="h-full"
                        placeholder="Description of the feature"
                        ref={descRef}
                        value={editFeature.desc}
                        minLength={TEMPLATE_FEATURE_DESCRIPTION_MIN_LENGTH}
                        maxLength={TEMPLATE_FEATURE_DESCRIPTION_MAX_LENGTH}
                        count={true}
                      />
                      {errors.desc && (
                        <p className="mt-2 text-xs text-red-500 text-left">
                          Description must be between{" "}
                          {TEMPLATE_FEATURE_DESCRIPTION_MIN_LENGTH}‎ and ‎
                          {TEMPLATE_FEATURE_DESCRIPTION_MAX_LENGTH}‎ characters
                          long.
                        </p>
                      )}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        }
        buttons={[
          {
            visible: true,
            text: "Cancel",
            style: "structural-text",
            onClick: () => onClose(),
          },
          {
            visible: isFormValid(),
            text: "Save",
            style: "structural",
            onClick: () => saveSelection(),
          },
          {
            visible: featureData.index != null,
            text: "Delete",
            style: "structural",
            onClick: () => deleteSelection(),
          },
        ]}
        show={show}
        onClose={onClose}
      />
    </>
  );
};
