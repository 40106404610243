import {
  AdjustmentsHorizontalIcon,
  BanknotesIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  GlobeAltIcon,
  GlobeAsiaAustraliaIcon,
  PresentationChartLineIcon,
  Squares2X2Icon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";

const Audience = {
  CREATOR: "Creator",
  VIEWER: "Viewer",
  BOTH: "Both",
};

const features = [
  {
    id: 1,
    name: "More Curations",
    description:
      "Notion templates and creators are showcased based on diverse metrics, ensuring maximum exposure and facilitating seamless discovery.",
    icon: PresentationChartLineIcon,
    audience: Audience.CREATOR,
  },
  {
    id: 2,
    name: "Multilingual Notion Templates",
    description:
      "Discover multilingual Notion Templates and download them in your preferred language.",
    icon: GlobeAltIcon,
    audience: Audience.VIEWER,
  },
  {
    id: 3,
    name: "High Reach with Localization",
    description:
      "Expand your audience worldwide by translating your Notion Templates to different languages.",
    icon: GlobeAsiaAustraliaIcon,
    audience: Audience.CREATOR,
  },
  {
    id: 4,
    name: "Creator-focused Dashboard",
    description:
      "Manage your creator profile and templates with ease. Be in control of your content.",
    icon: UserCircleIcon,
    audience: Audience.CREATOR,
  },
  {
    id: 5,
    name: "Expanded Template Selection",
    description: "Access a wide range of templates in various categories.",
    icon: Squares2X2Icon,
    audience: Audience.VIEWER,
  },
  {
    id: 6,
    name: "Advanced Filtering",
    description:
      "Refine your search with filters based on categories and more.",
    icon: AdjustmentsHorizontalIcon,
    audience: Audience.VIEWER,
  },
  {
    id: 7,
    name: "Modern and Dynamic Design",
    description:
      "Enjoy a visually appealing and dynamic design for an enhanced user experience.",
    icon: ComputerDesktopIcon,
    audience: Audience.BOTH,
  },
  {
    id: 8,
    name: "Mobile App",
    description:
      "Access Elcovia on the go with the Android app for seamless productivity and convenience. iPhone app coming soon.",
    icon: DevicePhoneMobileIcon,
    audience: Audience.VIEWER,
  },
  {
    id: 9,
    name: "Low Affiliate Fee",
    description:
      "The affiliate fee is only 10%, which is the lowest in the market.",
    icon: BanknotesIcon,
    audience: Audience.CREATOR,
  },
];

export default function FeaturesComponent() {
  return (
    <div className="py-16 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Elcovia's Features
            </h2>
            <p className="mt-4 text-md leading-8 text-gray-600 max-w-2xl mx-auto">
              Elcovia, the platform that takes your Notion experience to new
              heights.
            </p>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-4 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div
                key={feature.id}
                className="bg-gray-50 rounded-lg p-4 sm:p-5 text-center py-4"
              >
                <feature.icon className="w-6 h-6 inline mr-2 text-indigo-500" />
                <p className="mt-4 text-md font-bold tracking-tight text-gray-900">
                  {feature.name}
                </p>
                <p className="mt-1 text-sm font-normal leading-6 text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
