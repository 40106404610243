export const PATH_PURCHASE_PAYPAL_TEMPLATE_PROMOTION_SUCCESS = "purchase/paypal/promotion-template/success";
export const PATH_PURCHASE_PAYPAL_TEMPLATE_PROMOTION_CANCEL = "purchase/paypal/promotion-template/cancel";

export const PATH_PURCHASE_PAYPAL_PROFILE_PROMOTION_SUCCESS = "purchase/paypal/promotion-profile/success";
export const PATH_PURCHASE_PAYPAL_PROFILE_PROMOTION_CANCEL = "purchase/paypal/promotion-profile/cancel";

export const PATH_PURCHASE_STRIPE_TEMPLATE_PROMOTION_SUCCESS = "purchase/stripe/promotion-template/success";
export const PATH_PURCHASE_STRIPE_TEMPLATE_PROMOTION_CANCEL = "purchase/stripe/promotion-template/cancel";

export const PATH_PURCHASE_STRIPE_PROFILE_PROMOTION_SUCCESS = "purchase/stripe/promotion-profile/success";
export const PATH_PURCHASE_STRIPE_PROFILE_PROMOTION_CANCEL = "purchase/stripe/promotion-profile/cancel";

export const PATH_PURCHASE_STRIPE_MARKETPLACE_TEMPALTE_SUCCESS = "purchase/stripe/marketplace-template/success";
export const PATH_PURCHASE_STRIPE_MARKETPLACE_TEMPALTE_CANCEL = "purchase/stripe/marketplace-template/cancel";

export const FIREBASE_COLLECTION_PROFILE_IDS = 'userIds';
export const FIREBASE_COLLECTION_USERS = 'users';
export const FIREBASE_COLLECTION_USERS_SECRET = 'usersSecret';
export const FIREBASE_COLLECTION_TEMPLATES = 'templates';
export const FIREBASE_COLLECTION_DISCOVER = 'discover';
export const FIREBASE_COLLECTION_ORDERS = 'orders';
export const FIREBASE_COLLECTION_CUSTOMERS = 'customers';
export const FIREBASE_COLLECTION_CUSTOMER_CONSUMPTIONS = 'customerConsumptions';
export const FIREBASE_COLLECTION_TEMPLATES_OWNED = 'templatesOwned';
export const FIREBASE_COLLECTION_TEMPLATES_LIKED = 'templatesLiked';

export const ARG_UID = "uid"
export const ARG_TEMPLATE_ID = "id"


export const FIREBASE_TEMPLATE_COLLECTION_VIEWS = 'views';

export const FIREBASE_TEMPLATE_FIELD_VIEWS = 'views';
export const FIREBASE_TEMPLATE_FIELD_LIKES = 'likes';
export const FIREBASE_TEMPLATE_FIELD_DOWNLOADS = 'downloads';

export const FIREBASE_PROFILE_COLLECTION_VIEWED = 'viewed';
export const FIREBASE_PROFILE_PROFILE_ID = 'profileId';
export const FIREBASE_PROFILE_LAST_ID_CHANGE = 'lastIdChange';

export const FIREBASE_ADMIN_UID = 'sYghCkcQDYWj7mzyDAkxvcanttd2';

export const ERROR_MESSAGES = {
  LOGIN_REQUIRED: 'You must be logged in to perform this action',
  PERMISSION_DENIED: 'You do not have permission to perform this action',
  SOMETHING_WENT_WRONG: 'Something went wrong. Please try again later'
};

export const DATE_FORMAT = 'MMMM d, yyyy h:mm a';

export const TEMPLATES_AMOUNT = 150;


export const PROFILE_NAME_MIN_LENGTH = 3;
export const PROFILE_NAME_MAX_LENGTH = 30;

export const PROFILE_BIO_MIN_LENGTH = 80;
export const PROFILE_BIO_MAX_LENGTH = 240;

export const TEMPLATE_TITLE_MIN_LENGTH = 3;
export const TEMPLATE_TITLE_MAX_LENGTH = 30;

export const TEMPLATE_DESC_MIN_LENGTH = 350;
export const TEMPLATE_DESC_MAX_LENGTH = 10000;


export const TEMPLATE_FEATURE_TITLE_MIN_LENGTH = 3
export const TEMPLATE_FEATURE_TITLE_MAX_LENGTH = 30

export const TEMPLATE_FEATURE_DESCRIPTION_MIN_LENGTH = 50
export const TEMPLATE_FEATURE_DESCRIPTION_MAX_LENGTH = 250

export const TEMPLATE_FEATURES_MAX = 6

export const IMAGE_COVER_PLACEHOLDER = "https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg";

export const PROFILE_URL_PREFIX = "https://firebasestorage.googleapis.com/v0/b/elcovia-a8c37.appspot.com";


/**
 * A list of all the affiliate links.
 */
export const AffiliateLinks = {
  NOTION: "https://affiliate.notion.so/g7zlxo9jfwgu",
  NOTION_AI: "https://affiliate.notion.so/d90rjpzazvuu-4y5a7",
  NOTION_PROJECTS: "https://affiliate.notion.so/er40invb3xe7-o815lp",
};

export const ExternalLinks = {
  ELCOVIA: "https://elcovia.com",
  THREADS: "https://www.threads.net/@elcovia",
  ELCOVIA_X: "https://twitter.com/elcovia",
  ELCOVIA_INSTAGRAM: "https://instagram.com/elcovia",
  ELCOVIA_LINKEDIN: "https://linkedin.com/company/elcovia",
  ELCOVIA_PRODUCT_HUNT: "https://www.producthunt.com/@elcovia",
  ELCOVIA_GTP: "https://chat.openai.com/g/g-nMoDfkjfo-elcovia-notion-template-finder",
  ELCOVIA_PINTEREST: "https://www.pinterest.com/elcovia",
};

export const AFFILIATE_EMAIL = "elcovia@gmail.com"

export type FooterLink = {
  label: string;
  url: string;
  icon: string;
};

export const FooterSocialLinks: FooterLink[] = [
  {
    label: "Pinterest",
    url: ExternalLinks.ELCOVIA_PINTEREST,
    icon: `${process.env.PUBLIC_URL}/icons/pinterest.svg`,
  },
  {
    label: "Instagram",
    url: ExternalLinks.ELCOVIA_INSTAGRAM,
    icon: `${process.env.PUBLIC_URL}/icons/instagram.svg`,
  },
  {
    label: "Threads",
    url: ExternalLinks.THREADS,
    icon: `${process.env.PUBLIC_URL}/icons/threads.svg`,
  },
  {
    label: "Twitter",
    url: ExternalLinks.ELCOVIA_X,
    icon: `${process.env.PUBLIC_URL}/icons/x.svg`,
  },
  {
    label: "LinkedIn",
    url: ExternalLinks.ELCOVIA_LINKEDIN,
    icon: `${process.env.PUBLIC_URL}/icons/linkedin.svg`,
  },

  {
    label: "Product Hunt",
    url: ExternalLinks.ELCOVIA_PRODUCT_HUNT,
    icon: `${process.env.PUBLIC_URL}/icons/producthunt.svg`,
  },
];

export type AspectRatio = "16/9" | "4/3" | "3/4" | "1/1";